import { Box, Button, VStack } from "@chakra-ui/react";
import { HistoryItem, Loader, SassyAlert } from "components";
import useApiEffect from "hooks/use-api-effect";
import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { useProfile } from "stores";
import { useTranslation } from 'react-i18next';

const ProfileSearchHistory = () => {
  const { searchHistory, getSearchHistory, searchHistory:{ last} } = useProfile();
  const {t} = useTranslation()
  useApiEffect(() => {
    if (searchHistory.state === "loading") getSearchHistory({
      onSuccess:()=>{}, 
      onFailure:()=>{}, 
    });
  }, []);

  //console.log({searchHistory})

  return (
    <Box>
      {searchHistory.state === "erred" ? (
        <SassyAlert status="error" title={searchHistory.message} />
      ) 
      // : searchHistory.state === "loading" ? (
      //   <Loader />
      // ) 
      : (searchHistory.data.length === 0 && searchHistory.state !== "loading") ? (
        <SassyAlert status="info" title="No items found" />
      ) :
      (
        <>
          <VStack spacing="6" align="stretch">
            {searchHistory.data.map((s, i) => (
              <Link aria-label="Catalytic Converters Detailes" to={`/catalyst/${s?.catId}`}>
               <HistoryItem
                image={s.fullImageURL}
                key={i}
                variant="no-border start"
                title={s.catSerial || s.catNo }
                date={moment(s.updatedAt).format("DD MMM YYYY")}
                time={moment(s.updatedAt).format("hh:mm a")}
                price={s?.catPrice ? `${s?.catPrice}  ${localStorage.getItem("defCurrency")} ` :" Show Price"}
              /> 
              </Link>

))}
          </VStack>

          {!last && searchHistory.state === "loaded"&& <Box display="flex" justifyContent="flex-end">
            <Button aria-label= {t('Load  More Data')} onClick={getSearchHistory} mt="16" alignSelf="flex-end" colorScheme='teal' variant='outline'>
              {t('Load  More')}
            </Button>
          </Box>}
        </>
      )}
      { searchHistory.state === "loading" &&  <Loader />}
    </Box>
  );
};

export default ProfileSearchHistory;
