import {
  // Alert,
  // AlertDescription,
  // AlertIcon,
  // AlertTitle,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/react";
import { Catalyst, Loader, SassyAlert } from "components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCatalysts } from "stores";
import { deSluggify } from "utils";
import { useTranslation } from 'react-i18next';

const CarBrandCatalysts = () => {
  const { brandSlug } = useParams();
  const { getBrandCats } = useCatalysts();
  const { t } = useTranslation()
  const brandName = deSluggify(brandSlug);


  const [loadingData, setLoadingData] = useState(false)

  const [brandCats, setBrandCats] = useState({
    state: "loading",
    data: [],
    errorMessage: "",
    offSet: 0,
    last: false,
    limit: 6
  });
const {state,data,offSet,last,limit,}=brandCats


  useEffect(() => {
    getBrandCats({
      brandName,
      limit,
      offSet,
      onSuccess: (res) => {
       // console?.log(res?.body)
        setBrandCats({
          state: "loaded",
          data: res.body,
          errorMessage: "",
          last: res.body?.last,
          offSet: +res.body?.number + 1,
          limit: limit+6
        });
      },
      onFailure: (errorMessage) =>
        setBrandCats({ state: "erred", data:data, errorMessage }),
    });
  }, []);


  //======================
  //Load more Data
  //======================
  const loadMoreData = async()=>{
    setLoadingData(true)
    await getBrandCats({
      brandName,
      limit,
      offSet,
      onSuccess: (res) => { 
      //  console?.log("res0" , res) 
        if(res.body?.length>0){
          setBrandCats({
            state: "loaded",
            data: res.body ? [ ...res.body] : [],
            errorMessage: "",
            last: res.body?.last,
            offSet: +res.body?.number + 1,
            limit: limit+6
          });
        }
        setLoadingData(false)
      },
      onFailure: (errorMessage) =>{
        setLoadingData(false)
        setBrandCats({ state: "erred", data:data, errorMessage })
      }
      
       
    });
   
  }


  return (
    <Box>
      <Heading variant="section" as="h3">
        {t("Showing all Catalytic Converters From")}{" "}
        <Box as="span" color="primary" textTransform="uppercase">
          {/* {
            allBrands.data.find((b) => b.makerName.toUpperCase() === brandName)
              ?.makerName
          } */}
          {brandName}
        </Box>
      </Heading>
      
      <Box mt="16">
        {brandCats.state === "erred" ? (
          <SassyAlert
            title={brandCats.errorMessage || "Error Encountered."}
            description=" We have got an error while fetching catalysts for this car brand."
            status="error"
          />
        ) : brandCats.state === "loading" ? (
          !loadingData  ?<Loader />: <></>
        ) : (
          brandCats.state === "loaded" && (
            <>
              {brandCats.data.length === 0 ? (
                <SassyAlert
                  title="Nothing Found."
                  description="No catalysts found for this brand."
                  status="info"
                />
              ) : (
                <>
                  <Grid
                    templateColumns={{
                      base: "1fr",
                      sm: "repeat(2,1fr)",
                      lg: "repeat(3,1fr)",
                    }}
                    gap="12"
                  >
                    {brandCats.data.map((cat) => (
                      <GridItem
                        key={cat.id}
                        height="100%"
                        width="100%"
                        maxWidth={{ base: "300px", md: "100%" }}
                        mx="auto"
                      >
                        <Catalyst cat={cat} />
                      </GridItem>
                    ))}
                  </Grid>
                  {loadingData && <Loader />}
                  {!last && (state !== "loading") && <Box display="flex" justifyContent={{base:"center" , md:"flex-end"}}>
                    <Button aria-label={t('Load  More')} onClick={loadMoreData} mt="16" alignSelf="flex-end" colorScheme='teal' variant='outline'>
                      {t('Load  More')}
                    </Button>
                  </Box>}
                </>
              )}
            </>

          )
        )}
      </Box>
    </Box>
  );
};

export default CarBrandCatalysts;
