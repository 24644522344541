import { Box, Icon, useColorMode } from "@chakra-ui/react";
import { IconPhone } from "@tabler/icons";
import React from "react";
import PhoneInput from "react-phone-input-2";

const SassyPhoneInput = ({ onChange, value, disabled }) => {
  const dir = localStorage?.getItem("dir") || "ltr"
  const dirRTL = dir === "rtl";
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"

  return (
    <Box style={{
      // color: isDarkMode ? "white" : "dark",
      // backgroundColor: isDarkMode ? "darkModeBg" : "white",
    }} position="relative">
      <PhoneInput
        placeholder="Phone Number"
        country={
          localStorage?.getItem("defaultCountry")
            ? localStorage?.getItem("defaultCountry").toLowerCase()
            : "za"
        }
        onChange={onChange}
        containerClass={!isDarkMode ?"phone-input-container":"phone-input-container-dark"}
        inputClass={!isDarkMode?"phone-input-input":"phone-input-input-dark"}
        buttonClass={!isDarkMode?"phone-input-button":"phone-input-button-dark"}
        value={value}
        disabled={disabled}

        inputStyle={dirRTL ? { direction: "ltr" , backgroundColor:isDarkMode ? "#2d3748" : "white" } : {backgroundColor:isDarkMode ? "#2d3748" : "white"}}
        // style={dirRTL  ? { direction:   "ltr" } :{} }
        style={{
          direction: dirRTL && "ltr",
          // color: isDarkMode ? "white" : "dark",
          // backgroundColor:"red",
          // backgroundColor: isDarkMode ? "darkModeBg" : "white",
        }}

      />
      <Box
        position="absolute"
        right={dirRTL ? "unset" : "2"}
        left={dirRTL ? "2" : "unset"}
        top="0"
        bottom="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon as={IconPhone} color="gray" fontSize="18" />
      </Box>
    </Box>
  );
};

export default SassyPhoneInput;
