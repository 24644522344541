import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IconArrowRight } from "@tabler/icons";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import useSystemSettings from "stores/systemSettings";
import useApiEffect from "hooks/use-api-effect";
import { CalculatePfees, getToastData } from "utils";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import responsiveStyle from "constants/responsiveStyle";

const validationSchema = yup.object().shape({
  branch: yup.string(),
  rhodium: yup.string().required("Required"),
  palladium: yup.string().required("Required"),
  platinum: yup.string().required("Required"),
  fees: yup.number(),
  weightInKg: yup.string().required("Required"),
  fdPtPrice: yup.string().required("Required"),
  fdPdPrice: yup.string().required("Required"),
  fdRhPrice: yup.string().required("Required"),
});

const RefiningComparator = () => {
  const { colorMode } = useColorMode()
  const isDarkMode= colorMode === "dark"
  const { t } = useTranslation();
  const {
    systemSettings: {  data },
    getSystemSettings,
  } = useSystemSettings();
  const [result, setResult] = useState(null);
  const toast = useToast();
  // const formikRef = useRef()
  const INITIAL_VALUES = {
    rhodium: "",
    palladium: "",
    platinum: "",
    fees: "",
    weightInKg: "",
    fdPtPrice: data?.fdPtPrice,
    fdPdPrice: data?.fdPdPrice,
    fdRhPrice: data?.fdRhPrice,
  };

  useApiEffect(() => {
    getSystemSettings({
      onSuccess: (res) => {
      },
      onFailure: (message) => {
        toast(
          getToastData({
            status: "error",
            title: message,
          })
        );
      },
    });
    //formikRef?.current.resetForm()
    setResult('')
  }, [localStorage.getItem("defCurrency")]);


  const onSubmit = (calcData) => {
    // console?.log(data , calcData)
    const ptPrice = (+calcData.platinum / 1000) * +calcData?.fdPtPrice;
    const pdPrice = (+calcData.palladium / 1000) * +calcData?.fdPdPrice;
    const rhPrice = (+calcData.rhodium / 1000) * +calcData?.fdRhPrice;
    const price = (ptPrice + pdPrice + rhPrice) * +calcData?.weightInKg;
    const fees = calcData?.fees
    const finalPrice = fees ? CalculatePfees({ price: price, fees }) : Intl.NumberFormat().format(price);
    setResult(finalPrice);
  };


  return (
    <main className="page">
      <Box style={{backgroundImage: isDarkMode && "none" , backgroundColor:isDarkMode && "transparent"}} display={{base:"none" , md:"block"}} as="section" className={`hero ${"comparator-hero"}`} >
        <Container height="100%">
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              color="white"
              // className="-mt-24"
              fontWeight="extrabold"
              fontSize={{ base: "40px", md: "50px", xl: "60px" }}
            >
              {t("PGM Refinery Calculator")}
            </Heading>
          </Flex>
        </Container>
      </Box>

      <Box as="section" py={responsiveStyle?.section_py}>
        <Container>
          <Formik
            initialValues={INITIAL_VALUES}
            enableReinitialize
            {...{ validationSchema, onSubmit }}
          // innerRef={formikRef}
          >
            <Form>
              <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap="6">
                <GridItem>
                  <Box
                    p="4"
                    color="primary"
                    borderRadius="lg"
                   bgColor={isDarkMode?"darkModeBg": "secondary"}
                  >
                    <Heading fontSize="20">{t("Price Settings")}</Heading>
                  </Box>
                  <Box mt="8">
                    <VStack align="stretch" spacing="6">
                      <Field name="rhodium">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.rhodium && form.touched.rhodium
                            }
                          >
                            {
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                {t('Rhodium In g')}
                              </FormLabel>
                            }
                            <Input color={isDarkMode ? "white":"dark"}  bg={isDarkMode &&"darkModeBg"} type="number" {...field} placeholder="RH" />
                            <FormErrorMessage>
                              {form.errors.rhodium}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="palladium">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.palladium && form.touched.palladium
                            }
                          >
                            {
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                {t('Palladium In g')}
                              </FormLabel>
                            }
                            <Input color={isDarkMode ? "white":"dark"}   bg={isDarkMode &&"darkModeBg"} type="number" {...field} placeholder="PD" />
                            <FormErrorMessage>
                              {form.errors.palladium}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="platinum">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.platinum && form.touched.platinum
                            }
                          >
                            {
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                {t('Platinum In g')}
                              </FormLabel>
                            }
                            <Input  color={isDarkMode ? "white":"dark"} bg={isDarkMode &&"darkModeBg"} type="number" {...field} placeholder="PT" />
                            <FormErrorMessage>
                              {form.errors.platinum}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="fees">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.fees && form.touched.fees}
                          >
                            <Slider
                              aria-label="slider-ex-6"
                              defaultValue={field.value || 0}
                              onChange={(val) => {
                                form.setFieldValue("fees", val);
                              }}
                              mt="10"
                            >
                              <SliderMark
                                value={field.value || 0}
                                textAlign="center"
                                bg="primary"
                                color="white"
                                mt="-10"
                                ml="-5"
                                w="12"
                              >
                                {field.value || 0}%
                              </SliderMark>

                              <SliderTrack>
                                <SliderFilledTrack bg="#198754" />
                              </SliderTrack>
                              <SliderThumb
                                style={{ background: "#198754" }}
                                color="green"
                              />
                            </Slider>
                            <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                              Fees
                            </FormLabel>
                            {/* <Input   bg={isDarkMode &&"darkModeBg"} disabled {...field} placeholder="Fees" />
                            <FormErrorMessage>
                              {form.errors.fees}
                            </FormErrorMessage> */}
                          </FormControl>
                        )}
                      </Field>
                    </VStack>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box
                    p="4"
                    color="primary"
                    borderRadius="lg"
                   bgColor={isDarkMode?"darkModeBg": "secondary"}
                  >
                    <Heading fontSize="20">{t("Result")}</Heading>
                  </Box>
                  <Box mt="8">
                    <VStack align="stretch" spacing="6">
                      <Field name="weightInKg">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.weightInKg && form.touched.weightInKg
                            }
                          >
                            {
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                {t("Weight In KGs")}
                              </FormLabel>
                            }
                            <Input
                            color={isDarkMode ? "white":"dark"}
                             bg={isDarkMode &&"darkModeBg"}
                              {...field}
                              placeholder={t("Weight In KGs")}
                              type="number"
                            />
                            <FormErrorMessage>
                              {form.errors.weightInKg}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <FormControl>

                        <Field name="fdRhPrice">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.fdRhPrice && form.touched.fdRhPrice
                              }
                            >
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                RH ({t("Current Price")}) {`(${localStorage.getItem("defCurrency")})`}
                              </FormLabel>
                              <Input
                                // disabled
                               
                                {...field}
                                bgColor={isDarkMode?"darkModeBg": "secondary"}
                                color={isDarkMode ? "white":"dark"}
                                placeholder={`RH (${t("Current Price")})`}
                                value={field?.value}
                                type="number"
                              />
                              <FormErrorMessage>
                                {form.errors.fdRhPrice}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>


                      </FormControl>

                      <FormControl>

                        <Field name="fdPdPrice">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.fdPdPrice && form.touched.fdPdPrice
                              }
                            >
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                PD ({t("Current Price")}) {`(${localStorage.getItem("defCurrency")})`}
                              </FormLabel>

                              <Input
                                // disabled
                                {...field}
                                bgColor={isDarkMode?"darkModeBg": "secondary"}
                                color={isDarkMode ? "white":"dark"}
                                placeholder={`PD (${t("Current Price")})`}
                                value={field?.value}
                                type="number"
                              />
                              <FormErrorMessage>
                                {form.errors.fdPdPrice}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                      </FormControl>
                      <FormControl>
                        <Field name="fdPtPrice">
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.fdPtPrice && form.touched.fdPtPrice
                              }
                            >
                              <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                                PT ({t("Current Price")}) {`(${localStorage.getItem("defCurrency")})`}
                              </FormLabel>
                              <Input
                                // disabled
                                {...field}
                               bgColor={isDarkMode?"darkModeBg": "secondary"}
                                color={isDarkMode ? "white":"dark"}
                                placeholder={`PT (${t("Current Price")})`}
                                value={field?.value}
                                type="number"
                              />
                              <FormErrorMessage>
                                {form.errors.fdPtPrice}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                      </FormControl>
                      <FormControl>
                        {
                          <FormLabel fontSize="sm" color={isDarkMode ? "white":"dark"}>
                            {t("Resultant")}
                          </FormLabel>
                        }
                        <Input
                          disabled
                         bgColor={isDarkMode?"darkModeBg": "secondary"}
                          color={isDarkMode ? "white":"dark"}
                          value={`${result ? result : "Resultant"}  ${localStorage.getItem("defCurrency")}`}
                          placeholder={`${"Resultant"}  `}
                        />
                      </FormControl>
                    </VStack>
                  </Box>
                  <Box textAlign="right" mt="6">
                    <Button color={"white"} type="submit" rightIcon={<IconArrowRight />}>
                      {t("Proceed")}{" "}
                    </Button>
                  </Box>
                  {/* <Box textAlign="right" mt="6">
                    <Button style={{backgroundColor:"#362c2c" }}  >Reset </Button>
                  </Box> */}
                </GridItem>
              </Grid>
            </Form>
          </Formik>
        </Container>
      </Box>
    </main>
  );
};

export default RefiningComparator;
