import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from 'react-i18next';

const MetalDetailsModal = ({isOpen, onClose, pd = 1, rh = 1, pt = 1}) => {
  const {t} = useTranslation()
  const chartOptions = {
    chart: {
      width: "100%",
      type: "pie",
    },
    colors: ["#DD6420", "#3B92C2", "#198754"],
    labels: ["Palladium", "Rhodium", "Platinum"],
    legend: {
      position: "bottom",
    },
  };

  const chartSeries = [pd, rh, pt];




  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent  mx={5  }>
        <ModalHeader>{t('Metal Details')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5} >
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="pie"
            // width={380}
          />
        </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MetalDetailsModal;
