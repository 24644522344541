import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useFavorites} from "stores";
import {getToastData} from "utils";

const DeleteFavoritesCollectionAlert = ({collectionId, isOpen, onClose}) => {
  const {deleteFavoritesCollection} = useFavorites();
  const cancelRef = React.useRef();
  const toast = useToast();

  const [isBeingDeleted, setIsBeingDeleted] = useState();

  const handleDelete = () => {
    setIsBeingDeleted(true);
    deleteFavoritesCollection({
      collectionId,
      onSuccess: (res) => {
        setIsBeingDeleted(false);
        toast(
          getToastData({
            status: "success",
            title: "Favorites collection deleted successfully.",
          })
        );
        setTimeout(() => {
          onClose();
        }, 500);
      },
      onFailure: (message) => {
        setIsBeingDeleted(false);
        toast(
          getToastData({
            status: "error",
            title: message,
          })
        );
        onClose();
      },
    });
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Favorites Collection
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button aria-label=" Cancel" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
            aria-label="Delete"
              colorScheme="red"
              onClick={handleDelete}
              ml={3}
              isLoading={isBeingDeleted}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteFavoritesCollectionAlert;
