import { Box, Flex, Text } from '@chakra-ui/react';
import  React from 'react';

const LineTitle = ({title}) => {
    return <Flex alignItems={"center"}>
        <Box height={0} border="0.1px solid" borderColor={"#ccc"} flex={1}></Box>
     <Text color={"primary"} fontWeight={"bold"} px={2}>{title}</Text>
    <Box height={0} border="0.1px solid" borderColor={"#ccc"}  flex={1}></Box>
    </Flex>
}

export default LineTitle;