import {extendTheme} from "@chakra-ui/react";
import {defineStyleConfig} from "@chakra-ui/react";
import strings from "./strings";

const primaryColor = "#198754";
const secondaryColor = "#F1FFF5";
const secondaryDarkColor = "#DCFFE6";

const components = {
  Heading: defineStyleConfig({
    variants: {
      section: () => ({
        fontWeight: "bold",
        fontSize: ["2xl", "2xl", "2xl", "3xl", "3xl", "4xl"],
      }),
      tertiary: () => ({
        fontSize: "xl",
        fontWeight: "semibold",
      }),
    },
  }),
  Container: defineStyleConfig({
    baseStyle: {
      maxWidth: {
        base: "90%",
        sm: "540px",
        md: "740px",
        lg: "960px",
        xl: "1140px",
        xxl: "1320px",
      },
    },
  }),
  Image: defineStyleConfig({
    baseStyle: {
      fallbackSrc: strings.FALLBACK_IMAGE_URL,
    },
  }),
  Button: defineStyleConfig({
    baseStyle: {
      color: "white",
      fontWeight: "normal",
      bgColor: "primary",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      _hover: {
        bgColor: "#127346",
      },
      _disabled:{
        opacity:0.5,
      }
     
    },
    variants: {
      outline: {
        borderWidth: "1.2px",
        borderColor: "primary",
        color: "primary",
        _hover: {
          color: "white",
          bgColor: "primary",
        },
      },
      white: {
        borderWidth: "1.2px",
        borderColor: "transparent",
        bgColor: "white",
        color: "primary",
        _hover: {
          color: "white",
          bgColor: "primary",
        },
      },
    },
    defaultProps: {
      colorScheme: "primary",
    },
  }),
  Input: defineStyleConfig({
    baseStyle: {
      field: {
        background: "#2d3748",
        fontFamily: "body",
        fontWeight: "normal",
        color: "dark",
        padding: "4",
        // boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)",
        border: "1px solid rgba(0, 0, 0, 0.15) ",
        borderRadius: "lg",
        fontSize: "16",
        _placeholder: {
          color: "gray",
          fontSize: "15",
        },
        _disabled: {
          opacity: ".95",
        },
      },
    },
    defaultProps: {
      variant: null, // null here
    },

  }),
};

const breakpoints = {
  xs: "320px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const fonts = {
  body: "Poppins, sans-serif",
  heading: "Poppins, sans-serif",
  mono: "Menlo, monospace",
};

const semanticTokens = {
  colors: {
    primary: primaryColor,
    primarydark: "#127346",
    secondary: secondaryColor,
    secondarydark: secondaryDarkColor,
    dark: "#565656",
    gray: "#949494",
    
  },
};

const ChakraDarkTheme = extendTheme({
  // styles,
  fonts,
  semanticTokens,
  breakpoints,
  components,
});

export default ChakraDarkTheme;
