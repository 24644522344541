import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
} from "@chakra-ui/react";
import React from "react";

const SassyAlert = ({
  title = "Error Encountered",
  status = "error",
  variant = "subtle",
  description,
}) => {
  return (
    <Alert
      status={status}
      variant={variant}
      borderRadius="md"
      display="grid"
      gridTemplateColumns={{base: "1fr", lg: "auto 1fr"}}
      gap="2"
    >
      <Flex justify="start" align="center" gap="1">
        <AlertIcon />
        <AlertTitle>{title}</AlertTitle>
      </Flex>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
};

export default SassyAlert;
