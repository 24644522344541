import React, { useState } from 'react';
import useApiEffect from 'hooks/use-api-effect';
import { useProfile } from 'stores';
import { Box, Center, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { Heading  } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { Card } from 'components';
const notFoundImage = require('../../assets/images/notFound.webp')

const ProfileOrderOstoryDetails = ({ orderNo }) => {
    const {t} = useTranslation()
    const { getOrderHistoryDetais } = useProfile()
    const [orederDetails, setOrederDetails] = useState({
        state: "loading",
        data: {},
        errorMessage: "",
    });
    const currency = orederDetails?.data?.order?.currency

    useApiEffect(() => {
        getOrderHistoryDetais({
            orderNo,
            onSuccess: (res) => {
                //console.log({ res })
                setOrederDetails((prev) => ({
                    state: "loaded",
                    data: res.body,
                }))
            }

            ,
            onFailure: (errorMessage) => {
                setOrederDetails((prev) => ({
                    state: "erred",
                    data: {},
                    errorMessage,
                }))

            },
        })
    }, [])
    //console.log(orederDetails?.data)
    return (
        <div style={{}}>
            {orederDetails?.data?.details?.map((o,i)=>(
                <Link aria-label={t('Catalytic Converters detailes')} to={`/catalyst/${o?.catId}`}>
                <Box key={i} pt="2" borderTopWidth={1} pb={1}>
                <Flex align="center">
                    <Box
                        bgColor="gray.50"
                        px="2"
                        py="1"
                        borderRadius="lg"
                        alignSelf="stretch"
                    >
                        <Center w="100%" height="100%">
                            <Image
                                alt={o?.catSerial || o?.catNo}
                                src={o?.catImage}
                                maxWidth="80px"
                                width="100%"
                                objectFit="cover"
                                borderRadius="lg"
                                fallbackSrc={notFoundImage}
                            />
                        </Center>
                    </Box>
                    <Box flexGrow="1" py="4" pl={{ base: "5", lg: "10" }}>
                        <VStack justify="start" align="start" spacing="2">
                            <Heading as="h4" fontSize="16" color="dark" fontWeight="semibold">
                                {o?.catSerial || o?.catNo}
                            </Heading>
                            <Text color="primary">{`${o?.qty} x ${o?.catPrice} ${currency}`}</Text>
                        </VStack>
                    </Box>
                    <Box>
                    </Box>
                </Flex>
            </Box>
            </Link>
            ))}
            
        </div>
    );
}

export default ProfileOrderOstoryDetails;