
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { randomCode } from './utils-functions';

export const firebaseInit = async()=>{
    const firebaseConfig = {
        apiKey: "AIzaSyDN_cHKZuW3bcDfFUwwJIV3KVhfL5gZR0Y",
        authDomain: "catprices-57164.firebaseapp.com",
        projectId: "catprices-57164",
        storageBucket: "catprices-57164.appspot.com",
        messagingSenderId: "699982794294",
        appId: "1:699982794294:web:69a5fa30f84d43aba95297",
        measurementId: "G-194VWNWLQH"
      };
  
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      const fcmToken = localStorage?.getItem("fcmToken")
  
      if (!fcmToken) {
        try {
          const messaging = getMessaging(app);
          const premission = await Notification.requestPermission()
          if (premission === "granted") {
            const fcmToken = await getToken(messaging, { vapidKey: 'BDLWNOIWsntoB0PiALcabY9yNC1VdjBIg6El4JRY4CXZjKyomgqcJMhsvGtK9fg6VLBUUCbx5NrBfrq3iVyK5Ug' })
            localStorage?.setItem("fcmToken", fcmToken)
          }else{
            localStorage?.setItem("fcmToken",randomCode(Math.random() * 352))
          }
  
        } catch (error) {
          localStorage?.setItem("fcmToken", randomCode(Math.random() * 352))
         // console.log(error)
        }
      }
}