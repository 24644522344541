import {
  Box,
  HStack,
  Flex,
  Heading,
  Link,
  Text,
  Grid,
  GridItem,
  VStack,
  ListItem,
  List,
  ListIcon,
  Circle,
  Input,
  useColorMode,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

import {
  IconMapPin,
  IconMail,
  IconPhone,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandYoutube,
  IconSend,
  IconBrandFacebook,
} from "@tabler/icons";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
   <>
   <Divider/>
    <Box display={{base:"none" , md:"block"}} as="footer" bg={!isDarkMode &&  "#136240"} color="white" width="100%" py="16">
   
   <Box
     as="div"
     mx="auto"
     maxWidth={{
       base: "90vw",
       xxl: "1320px",
     }}
   >
     <Grid templateColumns={{ base: "1fr", md: "repeat(3,1fr)" }} gap="12">
       <GridItem>
         <Heading variant="tertiary" as="h3" mb="8">
           {t("Quick Links")}
         </Heading>
         <VStack spacing={4} alignItems="start">
           <NavLink
             to="/home"
             className="text-white hover:scale-110 ease-in-out duration-100 font-light"
           >
             {t("Home")}
           </NavLink>

           <NavLink
             to="/faqs"
             className="text-white hover:scale-110 ease-in-out duration-100 font-light"
           >
             {t("FAQ's")}
           </NavLink>
           <NavLink
             to="/privacy-policy"
             className="text-white hover:scale-110 ease-in-out duration-100 font-light"
           >
             {t("Privacy Policy")}
           </NavLink>
           <NavLink
              to="/terms-and-conditions"
              className="text-white hover:scale-110 ease-in-out duration-100 font-light"
            >
             {t("T & C")}
           </NavLink>
         </VStack>
       </GridItem>
       <GridItem>
         <Heading variant="tertiary" as="h3" mb="8">
           {t("Contacts")}
         </Heading>
         <List spacing={3} fontWeight="light">
           <ListItem>
             <HStack spacing={1} alignItems="start">
               <ListIcon as={IconMapPin} />
               <Text>{t("Business Address Text")}</Text>
             </HStack>
           </ListItem>
           <ListItem>
             <HStack spacing={1} alignItems="start">
               <ListIcon as={IconPhone} />
               <a aria-label={t("phone contact")} href={`tel:${t("+971 58 515 7119")}`}>
                 {t("+971 58 515 7119")}
               </a>
             </HStack>
           </ListItem>
           <ListItem>
             <HStack spacing={1} alignItems="start">
               <ListIcon as={IconMail} />
               <a aria-label="email" href={`mailto:${t("support@catalyticprice.com")}`}>
                 {t("support@catalyticprice.com")}
               </a>
               <Text></Text>
             </HStack>
           </ListItem>
         </List>
       </GridItem>
       <GridItem>
         <Box>
           <Heading mb="8" variant="tertiary" as="h3">
             {t(" Follow us")}
           </Heading>
           <HStack spacing="4">
             <Link aria-label="instagram" href="https://www.instagram.com/catalyticprice/">
               <Circle
                 size="38px"
                 bg="primary"
                 color="white"
                 cursor="pointer"
                 className="hover:scale-110 ease-in-out duration-100"
                 boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25);"
               >
                 <IconBrandInstagram />
               </Circle>
             </Link>

             <Link aria-label="linkedin" href="https://www.linkedin.com/company/catalytic-price">
               <Circle
                 size="38px"
                 bg="primary"
                 color="white"
                 cursor="pointer"
                 className="hover:scale-110 ease-in-out duration-100"
                 boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25);"
               >
                 <IconBrandLinkedin />
               </Circle>
             </Link>
             {/* <Link href="">
               <Circle
                 size="38px"
                 bg="primary"
                 color="white"
                 cursor="pointer"
                 className="hover:scale-110 ease-in-out duration-100"
                 boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25);"
               >
                 <IconBrandTwitter />
               </Circle>
             </Link> */}

             <Link aria-label="facebook" href="https://www.facebook.com/catalyticprice">
               <Circle
                 size="38px"
                 bg="primary"
                 color="white"
                 cursor="pointer"
                 className="hover:scale-110 ease-in-out duration-100"
                 boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25);"
               >
                 <IconBrandFacebook />
               </Circle>
             </Link>

             <Link  aria-label="youtube" href="https://www.youtube.com/channel/UCs7eYQ7JySFX2Ihx5BpMz-g">
               <Circle
                 size="38px"
                 bg="primary"
                 color="white"
                 cursor="pointer"
                 className="hover:scale-110 ease-in-out duration-100"
                 boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(0, 0, 0, 0.25);"
               >
                 <IconBrandYoutube />
               </Circle>
             </Link>
           </HStack>
         </Box>
         <Box mt="8">
           <Heading variant="tertiary" as="h3" mb="8">
             {t("Subscribe Newsletter")}
           </Heading>
           <Flex
             position="relative"
             borderWidth="2px"
             borderRadius="md"
             borderColor="white"
           >
             <Input
               type="email"
               placeholder={t("Enter You Email")}
               p="4"
               bgColor="transparent"
               fontWeight="light"
               border="0"
               outline="0"
               _placeholder={{ color: "white" }}
               className="focus:outline-0 focus:border-0 focus:ring-0"
             />
             {/* <input
               id="basic"
               type="text"
               placeholder="Enter your name"
               class="block w-full rounded-l border-gray-200 text-sm transition focus:z-10 focus:border-blue-600 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
             /> */}
             <button
              aria-label="Subscribe Newsletter"
               type="submit"
               className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-r bg-white px-3 py-2 text-primary"
             >
               <IconSend />
             </button>
           </Flex>
         </Box>
       </GridItem>
     </Grid>
   </Box>
 </Box>
   </>
  );
};

export default Footer;
