import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  HStack,
  List,
  ListItem,
  Tag,
  TagLabel,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { IconCheck } from "@tabler/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PricingPlan = ({ plan, isSpecial, speciality = "Most Popular", hideButton, }) => {
  const {
    planName = "Starter",
    planDescription,
    planCredit,
    validDays,
    // isSpecial,
    // speciality = "MOST POPULAR",
    // planId,
    planCode,
    // planStatus,
    usdPrice

  } = plan;

  const {t} = useTranslation()
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  // const features = [`${planCredit} credits`, `${validDays} days validity`];
  const discriptions = planDescription?.replace("{", '')?.replace("}", '')?.replace(/(")/gm, "")?.replace(/(\r\n|\n|\r)/gm, "")?.split(',')
  // console.log({plan})
  return (
    <Box
      position="relative"
      mt={{ base: isSpecial && "60px", lg: "0" }}
      height="100%"
    >
      {isSpecial && (
        <Box
          bgColor="primary"
          position="absolute"
          top="-60px"
          bottom="0"
          left="0"
          right="0"
          zIndex="1"
          borderRadius="2xl"
          boxShadow={!isDarkMode &&"0px 45px 66px rgba(227, 255, 242, 0.5)"}
        >
          <Box textAlign="right" p="6">
            <Tag
              size="md"
              borderRadius="full"
              variant="solid"
              bgColor="#0E663D"
              color="white"
              py="1"
              px="3"
            >
              <TagLabel fontWeight="extrabold" fontSize="xs">
                {speciality}
              </TagLabel>
            </Tag>
          </Box>
        </Box>
      )}
      <Flex
        p="6"
        gap="6"
        zIndex="5"
        height="100%"
        direction="column"
        position="relative"
        justify="space-between"
        align="flex-start"
        // bgColor={isSpecial ? "primary" : "white"}
        // borderWidth={!isSpecial &&.1}
        color={isSpecial ? "white" : "dark"}
        borderRadius="2xl"
        scaleY={isSpecial && "3"}
      >


        <Heading
          size="md"
          letterSpacing="wider"
          fontWeight="semibold"
          color={isSpecial ? "white" : "black"}
        >
          <Text   class="line-through "   style={{fontSize:27 , fontWeight:"normal"  , color:isDarkMode && "#fff" }} > {usdPrice === 4 && 9.99} {usdPrice === 9 && 19.99} {usdPrice === 99 && 199.99}</Text>
          <span style={{color:isDarkMode && "#fff"}}>{usdPrice} USD/</span>
          <Text
            color={(isSpecial )? "white" : "dark"}
            fontSize="sm"
            fontWeight="normal"
            display="inline"
          >
            {validDays} days
          </Text>
        </Heading>

        <Heading
          size="md"
          letterSpacing="wider"
          fontWeight="semibold"
          color={(isSpecial || isDarkMode) ? "white" : "black"}
        >
          {planName}
        </Heading>

        {/* <Text>{planDescription}</Text> */}

        <List spacing={3}>
          {discriptions?.map((f, i) => (
            <ListItem key={i}>
              <HStack spacing="2" align="flex-start">
                <Circle
                  size="5"
                  bg={isSpecial ? "white" : "rgba(25, 135, 84, 0.15)"}
                  color="primary"
                >
                  <IconCheck size="14" />
                </Circle>
                <Text color={isDarkMode?"white":"dark"}  fontSize="sm">{f}</Text>
              </HStack>
            </ListItem>
          ))}
        </List>
        {!hideButton && <Link aria-label={t('checkout')} to={`/pricing/checkout/${planCode}`} className="w-full">
          <Button
            width="100%"
            borderRadius="30.18px"
            bgColor={isSpecial ? "white" : "primary"}
            color={isSpecial ? "primary" : "white"}
            {...(isSpecial && {
              _hover: { bgColor: "secondarydark", color: "black" },
            })}
            aria-label="Choose Plan"
          >
            Choose Plan
          </Button>
        </Link>}
      </Flex>
    </Box>
  );
};

export default PricingPlan;
