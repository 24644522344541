import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useColorMode,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Card, Loader, OrderCompleteModal, SassyAlert } from "components";

import PurchaseItem from "components/cart-item";
import useApiEffect from "hooks/use-api-effect";
import React, { useState } from "react";
import { useAuth, useCart } from "stores";
import { getToastData, parsePrice } from "utils";

const CartView = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  const {
    cart,
    newOrder,
    addItemToCart,
    removeItemFromCart,
    getCatsPrices,
    saveNewOrder,
    emptyCart,
  } = useCart();
  const { user } = useAuth();

  const [itemsPricesState, setItemsPricesState] = useState("loading");

  const calculateSubTotal = () => {
    const subTotal = cart.reduce(
      (acc, curr) => (acc += curr.catPrice * curr.cartQty),
      0
    );
    return parsePrice(subTotal);
  };

  const placeOrder = () => {
    toast(getToastData({ status: "loading", title: "Placing your order..." }));
    saveNewOrder({
      orderData: cart,
      onSuccess: () => {
        toast.closeAll();
        toast(
          getToastData({
            status: "success",
            title: "Your order has been placed successfully",
          })
        );
        onOpen();
        emptyCart()
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "error",
            title:
              errorMessage || "There was an error while placing your order",
          })
        );
      },
    });
  };

  useApiEffect(() => {
    getCatsPrices({
      catsIds: cart.map((i) => ({ catId: i.catId })),
      onSuccess: () => {
        setItemsPricesState("loaded");
      },
      onFailure: (errorMessage) => {
        setItemsPricesState(errorMessage);
      },
    });
  }, []);

  // console.log(cart);

  return (
    <main className="page">
      <Box as="section" className="hero cart-hero" />
      <Box as="section" py="24">
        <Container>
          {cart.length === 0 ? (
            <SassyAlert
              status="info"
              title="Empty Cart"
              description="Your cart is empty. Add some items to cart first 😄"
            />
          ) : !["loading", "loaded"].includes(itemsPricesState) ? (
            <SassyAlert
              status="error"
              title={
                itemsPricesState ||
                "There was an error while loading prices for the items added to cart."
              }
            />
          ) : itemsPricesState === "loading" ? (
            <Loader />
          ) : (
            <Grid
              gap={{ base: "8", lg: "12" }}
              templateColumns={{ base: "1fr", lg: "1fr 1fr" }}
            >
              <GridItem>
                <VStack justify="start" align="stretch" spacing="7">
                  {cart.map((i) => (
                    <PurchaseItem
                      key={i.id}
                      image={i.images?.[0]?.fullImageURL}
                      name={i.catNo}
                      description=""
                      price={parsePrice(i.catPrice)}
                      onPlus={() => {
                        addItemToCart(i.catId, i);
                      }}
                      onMinus={() => {
                        removeItemFromCart(i.catId);
                      }}
                      quantity={i.cartQty}
                    />
                  ))}
                </VStack>
              </GridItem>
              <GridItem>
                <Card p="8">
                  <Heading textAlign="center" fontSize="22">
                    Checkout
                  </Heading>
                  <Flex justify="space-between" flexDirection="column" mt="10">
                    {/* Checkout Items */}
                    <Box>
                      <UnorderedList listStyleType="none" ml="0">
                        {cart.map((i) => (
                          <ListItem
                            pt="5"
                            pb="2"
                            borderBottom="1px solid #E1E1E1"
                          >
                            <Flex justify="space-between">
                              <Text fontWeight="light" color={isDarkMode ? "white": "black"}>
                                {i.catNo}
                              </Text>
                              <Text color={isDarkMode?"white" :"dark"}>
                                {user.data.defCurrency}{" "}
                                {parsePrice(i.catPrice * i.cartQty)}
                              </Text>
                            </Flex>
                          </ListItem>
                        ))}
                      </UnorderedList>
                    </Box>
                    <Box mt="20">
                      <Box pt="5" pb="2" borderBottom="1px solid #E1E1E1">
                        <Flex justify="space-between">
                          <Text color={isDarkMode ? "white": "black"} fontSize="17">
                            Subtotal
                          </Text>
                          <Text color="primary">
                            {user.data.defCurrency} {calculateSubTotal()}
                          </Text>
                        </Flex>
                      </Box>
                      <Box pt="5" pb="2" borderBottom="1px solid #E1E1E1">
                        <Flex justify="space-between">
                          <Heading as="h5" color="primary" fontSize="18">
                            Grand Total
                          </Heading>
                          <Text color="primary" fontWeight="semibold">
                            {user.data.defCurrency} {calculateSubTotal()}
                          </Text>
                        </Flex>
                      </Box>
                      <Button color={"white"} aria-label="Complete Order" width="100%" mt="6" onClick={placeOrder}>
                        Complete Order
                      </Button>
                    </Box>
                  </Flex>

                </Card>
              </GridItem>
            </Grid>
          )}
        </Container>
      </Box>
      <OrderCompleteModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          emptyCart();
        }}
        orderNo={newOrder.data.orderNo}
      />
    </main>
  );
};

export default CartView;
