import {Box,  Grid, GridItem, Heading} from "@chakra-ui/react";
import {CarBrand, Loader, SassyAlert} from "components";
import useApiEffect from "hooks/use-api-effect";
import React from "react";
import {useCatalysts} from "stores";
import { useTranslation } from 'react-i18next';

const CarBrandsGrid = () => {
  const {allBrands, getAllBrands} = useCatalysts();
  const {t} = useTranslation()
  useApiEffect(() => {
    if (allBrands.state === "loading") getAllBrands({});
  }, []);

  return (
    <>
      <Heading variant="section" as="h3">
        {t("All")}{" "}
        <Box as="span" color="primary">
          {t("Brands")}
        </Box>
      </Heading>

      <Box mt="12" style={{display:"flex" , justifyContent:"center"}}>
        {allBrands.state === "erred" ? (
          <SassyAlert
            status="error"
            title={allBrands.errorMessage || "Error Encountered."}
            description="We have got an error while fetching all car brands."
          />
        ) : allBrands.state === "loading" ? (
          <Loader />
        ) : (
          allBrands.state === "loaded" && (
            <Grid
              style={{display:"flex" , justifyContent:"space-around" , flexWrap:"wrap"}}
              templateColumns={{
                base: "1fr",
                sm: "repeat(2,1fr)",
                lg: "repeat(3,1fr)",
                xl: "repeat(4,1fr)",
              }}
              columnGap={{base:"3", md:"12"}}
              rowGap="16"
            >
              {allBrands.data.map((b) => (
                
                  <GridItem key={b.makerId}>
                    
                  <CarBrand brand={b} key={b.makerId} />
                </GridItem>
                
              ))}
            </Grid>
          )
        )}
      </Box>
    </>
  );
};

export default CarBrandsGrid;
