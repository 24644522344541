import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import { ChakraTheme } from "constants";
import Router from "router";
import useApiEffect from 'hooks/use-api-effect';
import { useAuth, useCatalysts } from 'stores';
import { firebaseInit } from './utils/initializeAppFirebase';
import { getCountryDefaultData } from "utils";
import { RtlProvider } from "lang/rtl-provider";
import useLangSettings from "stores/langStor";
import ChakraDarkTheme from "constants/chakra-dark-theme";


function App() {
  const { handleAuth } = useAuth()
  const {colorMode} = useColorMode()
  const isDarkMode =  colorMode=== "dark"
  // console.log({isDarkMode , colorMode})
  const { languages, getActiveLanguages, getLanguagesTranslate } = useLangSettings();
  const { getAllFavCats } = useCatalysts()
  useApiEffect(async () => {
    const lang = localStorage.getItem('lang')
    const currency  = localStorage.getItem('currency')
    const defCurrency = localStorage.getItem('defCurrency')
    if(!(currency === "USD" || currency === "ZAR" || currency === "EUR") ){
      localStorage.setItem('currency' , "USD")
    }

    if( defCurrency &&  !(defCurrency === "USD" || defCurrency === "ZAR" || defCurrency === "EUR") ){
      localStorage.setItem('defCurrency' , "USD")
    }

    if (lang?.lang_id) {
      localStorage.getItem('lang')
      localStorage.getItem('dir')
    } else {
      document.body.setAttribute("dir", JSON?.parse(lang)?.langDir);
      if (lang !== null) {
        getLanguagesTranslate({ lang: JSON?.parse(lang) })
      }
    }
    firebaseInit()
    handleAuth()
    getCountryDefaultData()
    getActiveLanguages({
      onSuccess: () => { },
      onFailure: () => { },
    });
    getAllFavCats({
      onSuccess: () => { },
      onFailure: () => { },
    })


    return () => { }
  }, [])




  return (
    <ChakraProvider theme={ChakraTheme }>
      <RtlProvider>
        <Router />
      </RtlProvider>
    </ChakraProvider>
  );
}

export default App;
