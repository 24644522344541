import React from "react";
import { Box, Button, Circle, Grid, GridItem, HStack, Heading, Highlight, Image, List, ListItem, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorMode } from "@chakra-ui/react";
import PricingPlan from "./single-pricing-plan";
import { useFeatures } from "stores";
import useApiEffect from "hooks/use-api-effect";
import { Loader, SassyAlert } from "components";
import { useTranslation } from 'react-i18next';
import { IconCheck } from "@tabler/icons";
import { Link } from "react-router-dom";
import LineTitle from "components/line-title";

const tabsProps = {
  _hover: { bgColor: "secondarydark", color: "black" },
  _selected: { borderColor: "primary", },
  border: "1.5px solid ",
  borderColor: "#ccc",  
  borderRadius: "lg",
  margin: 2
};

const discriptions = (planDescription) => planDescription?.replace("{", '')?.replace("}", '')?.replace(/(")/gm, "")?.replace(/(\r\n|\n|\r)/gm, "")?.split(',')

const PricingPlans = () => {
  const { activePlans, getActivePlans } = useFeatures();
  const { t } = useTranslation()
  useApiEffect(() => {
    getActivePlans({});
  }, []);
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <>
      <Heading display={{ base: "none", md: "block" }} variant="section" textAlign="center" maxWidth="500px" mx="auto">
        <Highlight
          query={["Best Services", "Best Prices"]}
          styles={{ color: "primary", whiteSpace: "wrap" }}
        >
          {t("We Offer Best Services in Best Prices Ever!")}
        </Highlight>
      </Heading>
      <Box display={{ base: "none", md: "block" }} maxWidth={{ md: "90%", xl: "80%" }} mx="auto">
        <Text color="dark" my="12" textAlign="center" lineHeight="taller">
          {/* Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Curabitur lacus velit, ullamcorper sed vulputate at. */}
        </Text>

        <Box
          bgColor={!isDarkMode && "white"}
          borderRadius="2xl"
          p="6"
          boxShadow={isDarkMode ? "0px 0px 57.8431px rgba(0, 0, 0, 0.41)": "0px 0px 57.8431px rgba(0, 0, 0, 0.11)"}
        >
          {activePlans.state === "erred" ? (
            <SassyAlert
              title={activePlans.message}
              description="An error has occurred while loading available plans info."
              status="error"
            />
          ) : activePlans.state === "loading" ? (
            <Loader />
          ) : (
            <Grid templateColumns={{ base: "1fr", lg: "repeat(3,1fr)" }} gap="6">
              {activePlans.data
                .sort((a, b) => (a.planCredit < b.planCredit ? -1 : 1))
                .map((plan, index, arr) => (
                  <GridItem key={plan.planId} height="100%">
                    <PricingPlan
                      plan={plan}
                      isSpecial={index === arr.length - 1}
                    />
                  </GridItem>
                ))}
            </Grid>
          )}
        </Box>
      </Box>



      {/* mobile view */}
      <Box display={{ base: "block", md: "none" }}>

        <Tabs isFitted variant="unstyled">
          <TabList>
            {activePlans.data.map((plan, index, arr) => <>
              <Tab   {...tabsProps}>
                <Box>
                  <Box width={"100%"} px={3} >
                    <Image margin={"auto"} display={"block"} height={12} src={plan?.planImage} />
                  </Box>

                  <Text textAlign={"center"} fontSize={10}>{plan?.planName}</Text>
                </Box>

              </Tab></>)}
          </TabList>

          <TabPanels>
            {activePlans.data.map((plan) => {
              const planDescription = discriptions(plan?.planDescription)
              const usdPrice = plan?.usdPrice
              return <TabPanel>
                <LineTitle title={plan?.planName} />
                <p class="line-through " style={{ fontSize: 20, fontWeight: "normal", textAlign: "center" }} > {usdPrice === 4 && 9.99} {usdPrice === 9 && 19.99} {usdPrice === 99 && 199.99} </p>
                <Text color={"primary"} fontWeight={"bold"} textAlign={"center"} fontSize={"18px"}>{plan?.usdPrice} USD</Text>
                {planDescription.map((f, i) => (
                  <List spacing={3} mt={"10px"}>
                    <ListItem key={i}>
                      <HStack spacing="2" align="flex-start">
                        <Circle
                          size="5"
                          color="primary"
                        >
                          <IconCheck size="14" />
                        </Circle>
                        <Text fontSize="sm">{f}</Text>
                      </HStack>
                    </ListItem>
                  </List>
                ))}
                {<Link style={{ display: "flex", flex: 1, marginTop: "25px" }} aria-label={t('checkout')} to={`/pricing/checkout/${plan?.planCode}`} >
                  <Button
                    mt={10}
                    disabled="block"
                    width="50%"
                    margin={"auto"}
                    borderRadius="10px"
                    bgColor={'primary'}
                    color={"white"}
                    {...({
                      _hover: { bgColor: "secondarydark", color: "black" },
                    })}
                    aria-label="Choose Plan"
                  >
                    {t("Subscribe")}
                  </Button>
                </Link>}
              </TabPanel>
            })}

          </TabPanels>
        </Tabs>

      </Box>
    </>
  );
};

export default PricingPlans;
