import {
  Box,
  Button,
  Card,
  Circle,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Highlight,
  HStack,
  Image,
  Link,
  Square,
  Switch,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  IconArrowNarrowRight,
  IconArrowUpRight,
  IconCoin,
  IconHeadset,
  IconShieldCheck,
} from "@tabler/icons";
import React, { useState } from "react";
import { CatSearchBox, FooterCTA } from "components";

import HandShakeImage from "assets/images/hand-shake.webp";
import DetailsScreenImage from "assets/images/details-screen.webp";
import MobileScreensImage from "assets/images/mobile-screens.webp";
import GooglePlayButton from "assets/images/googleplaycta.svg";

import { PopularBrandsCarousel } from "modules";
import useSystemSettings from "stores/systemSettings";
import useApiEffect from 'hooks/use-api-effect';
// import { timestampTotime } from "utils";
import { useTimer } from 'react-timer-hook';
import { useAuth } from "stores";
import { useTranslation } from 'react-i18next';
import LogoDark from "assets/images/logo-dark.webp";

const LandingPage = () => {
  
  const [pricingPerGram, setPricingPerGram] = useState(true)
  const { getSystemSettings, getHomeSystemSettings, homeSystemSettings } = useSystemSettings()
  const { data: metalPriceData, state: metalPriceState } = homeSystemSettings
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  //console?.log({metalPriceData})
  const { user } = useAuth()
  const { t } = useTranslation()
  const currency = user?.currency
  // const [timer ,  setTimer ] = useState('')
  // const time = new Date();
  const {
    seconds,
    minutes,
    hours,
    days,
    // isRunning,
    // start,
    // pause,
    // resume,
    restart,
  } = useTimer({ expiryTimestamp: 1, onExpire: () => { getData() } });


  const getData = () => {
    getSystemSettings({
      onSuccess: (res) => {
     
        // let time = timestampTotime(Math.abs((createdAt.getTime() - updatedAt.getTime()) / 1000));
        // setTimer(time)
        // console.log(time)
      },
      onFailure: (message) => {
        //console?.log(message)
      }
    })
    getHomeSystemSettings({
      onSuccess: (res) => { 
        const data = res?.body
        const createdAt = new Date()
        const updatedAt = new Date(data.updatedAt)
        let timestamp = Math.abs((createdAt.getTime() - updatedAt.getTime()) / 1000);
        // console.log(timestamp)

        const currentTime = new Date();
        currentTime.setSeconds(currentTime.getSeconds() + timestamp  );
        // console.log({currentTime})
        restart(currentTime)

      },
      onFailure: (message) => { }
    })
  }

  useApiEffect(() => {
    getData()
  }, [currency])






  return (
    <>
      {/*--DESKTOP home page--*/}
      <Box display={{ base: "none", md: "block" }}>
        <>
          <main className="page">
            {/* Hero start */}
            <Box as="section" style={{backgroundImage: isDarkMode && "none" , backgroundColor:isDarkMode && "transparent"}} className={ "hero landing-hero"}>
              <Container py={{
                base: 5,
                md: 36
              }} color="white" height="100%">
                <Flex
                  h="100%"
                  justify="center"
                  align="center"
                  className="text-center"
                >
                  <Box mx="auto" maxWidth="918px">
                    <Heading
                      as="h1"
                      fontWeight="extrabold"
                      fontSize={{ base: "35px", md: "50px", xl: "60px" }}
                    >
                      <Highlight
                        query="Catalytic Converters"
                        styles={{ color: "primary", whiteSpace: "wrap" }}
                      >
                        {t("Search Catalytic Converters for Authentic prices.")}
                      </Highlight>
                    </Heading>
                    <Text
                      // fontSize={{ base: "12px", sm: "15px", xl: "20px" }}

                      mt={{ sm: "10", base: "4" }}>
                      <Highlight
                        query={["40,000", "PT, PD, RH"]}
                        styles={{ color: "primary", whiteSpace: "wrap" }}
                      >
                        {t("Browse among more than 40,000 catalytic converters prices in our records. Our prices are based on a real ICP results according to PT, PD, RH.")}
                      </Highlight>
                    </Text>
                    <Box mt={{ base: "6", md: '12' }}>
                      <CatSearchBox />
                    </Box>

                    <Text mt="8">
                      {t("We update the prices every 2 hours acording to the market price so you will always be up to date and get the acurate price for your catalytic converters.")}
                    </Text>

                    <FormControl mt="8" display='flex' justifyContent="center" alignItems='center'>
                      <FormLabel htmlFor='pricing Per Gram' mb='0'>
                        {t('Ounce ')}
                      </FormLabel>
                      <Switch onChange={() => { setPricingPerGram(!pricingPerGram) }} isChecked={pricingPerGram} colorScheme="green" id='pricing Per Gram' />
                      <FormLabel ml="3" htmlFor='pricing Per Gram' mb='0'>
                        {t('Gram')}
                      </FormLabel>
                    </FormControl>



                    <Flex
                      mt="14"
                      justify="center"
                      align="center"
                      wrap="wrap"
                      gap={14}
                      flexDirection={{
                        base: "column",
                        md: "row"
                      }}
                    >
                      <HStack spacing={4}>
                        <Circle size="38px" bg="primary" color="white">
                          Pd
                        </Circle>
                        <Box as="span">
                          <Text fontSize="md">

                            {metalPriceState === "loading" ? t("Loading...")
                              : `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}  ${pricingPerGram ? metalPriceData?.pdPriceGram : metalPriceData?.pdPriceOunce}`
                            }
                          </Text>
                          <Text color="gray" textAlign="left" fontSize="sm" mt="1">
                            {`${hours}h:${minutes}m:${seconds}s`}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack spacing={4}>
                        <Circle size="38px" bg="primary" color="white">
                          Pt
                        </Circle>
                        <Box as="span">
                          <Text fontSize="md">
                            {metalPriceState === "loading" ? t("Loading...")
                              : `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")} ${pricingPerGram ? metalPriceData?.ptPriceGram : metalPriceData?.ptPriceOunce} `
                            }
                          </Text>
                          <Text color="gray" textAlign="left" fontSize="sm" mt="1">
                            {`${hours}h:${minutes}m:${seconds}s`}
                          </Text>
                        </Box>
                      </HStack>

                      <HStack spacing={4}>
                        <Circle size="38px" bg="primary" color="white">
                          Rh
                        </Circle>
                        <Box as="span">
                          <Text fontSize="md">
                            {metalPriceState === "loading" ? t("Loading...")
                              : `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")} ${pricingPerGram ? metalPriceData?.rhPriceGram : metalPriceData?.rhPriceOunce} `
                            }
                          </Text>
                          <Text color="gray" textAlign="left" fontSize="sm" mt="1">
                            {`${hours}h:${minutes}m:${seconds}s`}
                          </Text>
                        </Box>
                      </HStack>

                    </Flex>
                  </Box>
                </Flex>
              </Container>
            </Box>
            {/* Hero end */}
                         
            {/* Popular brands carousel */}
            <PopularBrandsCarousel
              header={
                <Flex justify="space-between" align="center" wrap="wrap">
                  <Heading variant="section">
                    <Highlight
                      query="Brands & Models"
                      styles={{ color: "primary", whiteSpace: "wrap" }}
                    >
                      {t("Browse Cats by Car Brands & Models")}
                    </Highlight>
                  </Heading>
                  <Link
                    as={RouterLink}
                    to="/car-brands"
                    color="primary"
                    fontWeight="bold"
                    cursor="pointer"
                  >
                    <HStack>
                      <Text>{t("Explore More")}</Text>
                      <IconArrowNarrowRight />
                    </HStack>
                  </Link>
                </Flex>
              }
            />

            {/*  --------------  */}
            <Box as="section" py="24">
              <Container>
                <Box textAlign="center">
                  <Heading variant="section" mb="8">
                    <Highlight
                      query="Catalytic Prices"
                      styles={{ color: "primary", whiteSpace: "wrap" }}
                    >
                      {t("Why Choose Catalytic Prices?")}
                    </Highlight>
                  </Heading>
                  <Text maxWidth="500px" mx="auto" color={isDarkMode?"white":"dark"}>
                    {t("We provide more details about your catalytic converters & the amount of metals inside of each catalytic with full description")}.{" "}
                  </Text>
                </Box>
                <Grid
                  templateColumns={{ md: "repeat(2,1fr)", lg: "repeat(3,1fr)" }}
                  gap="14"
                  mt="16"
                  alignItems="stretch"
                >
                  <Card>
                  <GridItem>
                    <Box
                      py="12"
                      px="10"
                      height="100%"
                      bgColor={colorMode === "light" &&  "white"}
                      borderRadius="md"
                      boxShadow="19px 19px 80px rgba(0, 0, 0, 0.05)"
                    >
                      <Square
                        size="40px"
                        bg="primary"
                        color="white"
                        mb="7"
                        fontWeight="bold"
                        borderRadius="md"
                        boxShadow="8px 8px 30px rgba(25, 135, 84, 0.2)"
                      >
                        <IconCoin />
                      </Square>
                      <VStack spacing="4" alignItems="flex-start">
                        <Heading as="h3" variant="tertiary">
                          {t('Latest Prices')}
                        </Heading>
                        <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }}>
                          {t("we update the prices every 2 hours acoording to the market price so you will always be up to date and get the acurate price for your catalytic converters")}
                        </Text>
                        {/* <Link fontWeight="semibold" color="primary">
                    Learn More
                  </Link> */}
                      </VStack>
                    </Box>
                  </GridItem>
                  </Card>

                  <Card>
                  <GridItem>
                    <Box
                      py="12"
                      px="10"
                      height="100%"
                      bgColor={colorMode === "light" &&  "white"}
                      borderRadius="md"
                      boxShadow="19px 19px 80px rgba(0, 0, 0, 0.05)"
                    >
                      <Square
                        size="40px"
                        bg="primary"
                        color="white"
                        mb="7"
                        fontWeight="bold"
                        borderRadius="md"
                        boxShadow="8px 8px 30px rgba(25, 135, 84, 0.2)"
                      >
                        <IconShieldCheck />
                      </Square>
                      <VStack spacing="4" alignItems="flex-start">
                        <Heading as="h3" variant="tertiary">
                          {t('40,000 Items')}{" "}
                        </Heading>
                        <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }}>
                          {t('Browse among more than 40,000 catalytic converters prices in our records.Our prices are based on a real ICP results according to PT, PD, RH.')}
                        </Text>
                        {/* <Link fontWeight="semibold" color="primary">
                    Learn More
                  </Link> */}
                      </VStack>
                    </Box>
                  </GridItem>
                  </Card>

                  <Card>
                  <GridItem>
                    <Box
                      py="12"
                      px="10"
                      height="100%"
                      bgColor={colorMode === "light" &&  "white"}
                      borderRadius="md"
                      boxShadow="19px 19px 80px rgba(0, 0, 0, 0.05)"
                    >
                      <Square
                        size="40px"
                        bg="primary"
                        color="white"
                        mb="7"
                        fontWeight="bold"
                        borderRadius="md"
                        boxShadow="8px 8px 30px rgba(25, 135, 84, 0.2)"
                      >
                        <IconHeadset />
                      </Square>
                      <VStack spacing="4" alignItems="flex-start">
                        <Heading as="h3" variant="tertiary">
                          {t('In-Depth Specifications')}{" "}
                        </Heading>
                        <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }}>
                          {t(' Get more details about your catalytic converters & the amount of metals inside of each catalytic with full description.')}
                        </Text>
                        {/* <Link fontWeight="semibold" color="primary">
                    Learn More
                  </Link> */}
                      </VStack>
                    </Box>
                  </GridItem>
                  </Card>
                </Grid>
              </Container>
            </Box>
            {/*  --------------  */}

            {/*  ------------ */}
            <Box as="section" py="20">
              <Container>
                <Heading variant="section" textAlign="center" mx="auto">
                  <Highlight
                    query="Partner"
                    styles={{ color: "primary", whiteSpace: "wrap" }}
                  >
                    {t("Become Our Partner & Enjoy Benefits")}
                  </Highlight>
                </Heading>
                <Box mt="16">
                  <Grid
                    templateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
                    gap="14"
                    justifyItems="center"
                  >
                    <GridItem alignSelf="center">
                      <Image alt="HandShake" src={HandShakeImage} width="100%" height="100%" />
                    </GridItem>
                    <GridItem alignSelf="stretch">
                      <VStack spacing="12" alignItems="flex-start">
                        <Box width="100%">
                          <Heading mb="3" variant="tertiary" as="h3">
                            {t("Become_Our_Partner_title_1")}
                          </Heading>
                          <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }}>
                            {t("Become_Our_Partner_description_1")}
                          </Text>
                        </Box>
                        <Box width="100%">
                          <Heading mb="3" variant="tertiary" as="h3">
                            {t("Become_Our_Partner_title_2")}
                          </Heading>
                          <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }}>
                            {t("Become_Our_Partner_description_2")}
                          </Text>
                        </Box>
                        <Box width="100%">
                          <Heading mb="3" variant="tertiary" as="h3">
                            {t("Become_Our_Partner_title_3")}
                          </Heading>
                          <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }}>
                            {t("Become_Our_Partner_description_3")}
                          </Text>
                        </Box>
                        <Box>
                          <Text color={isDarkMode?"white":"dark"} maxWidth={{ md: "90%" }} mb="4">
                            {t('What are you waiting for? Become our partner today to enjoy benefits.')}
                          </Text>

                          <Button color="#fff" aria-label={t("Become a Partner")} as={RouterLink} to="https://catalyticprice.com/blog/partner-request-form/" rightIcon={<IconArrowUpRight />}>
                            {t("Become a Partner")}
                          </Button>

                        </Box>
                      </VStack>
                    </GridItem>
                  </Grid>
                </Box>
              </Container>
            </Box>

            {/*  */}

            <Box  as="section" py="24" className="relative" overflow="hidden">
              <Container>
                <Heading
                  variant="section"
                  textAlign="center"
                  maxWidth="500px"
                  mx="auto"
                >
                  <Highlight
                    query="The Best"
                    styles={{ color: "primary", whiteSpace: "wrap" }}
                  >
                    {t('We Are The Best for Some Reasons')}
                  </Highlight>
                </Heading>
                <Box mt="28"   >
                  <Grid
                    templateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
                    gap="14"
                    justifyItems="center"
                    alignItems="center"
                  >
                    <GridItem>
                      <VStack spacing="12">
                        <Flex align="center" gap="8">
                          <Box>
                            <Square
                              size="40px"
                              bg="primary"
                              color="white"
                              fontWeight="bold"
                              borderRadius="md"
                              boxShadow="8px 8px 30px rgba(25, 135, 84, 0.2)"
                            >
                              1
                            </Square>
                          </Box>
                          <Box>
                            <Heading variant="tertiary" as="h3" mb="3">
                              {t("In-Depth Cat Details")}
                            </Heading>
                            <Text color={isDarkMode?"white":"dark"}>
                              {t("Get more details about your catalytic converters & the amount of metals inside of each cat.")}
                            </Text>
                          </Box>
                        </Flex>
                        <Flex align="center" gap="8">
                          <Box>
                            <Square
                              size="40px"
                              bg="primary"
                              color="white"
                              fontWeight="bold"
                              borderRadius="md"
                              boxShadow="8px 8px 30px rgba(25, 135, 84, 0.2)"
                            >
                              2
                            </Square>
                          </Box>
                          <Box>
                            <Heading mb="3" variant="tertiary" as="h3">
                              {t("Quick, Easy and Reliable")}
                            </Heading>
                            <Text color={isDarkMode?"white":"dark"}>
                              {t("Cat Price ensures the usability to be easy and secure to provide you the best Cat market experience.")}
                            </Text>
                          </Box>
                        </Flex>
                        <Flex align="center" gap="8">
                          <Box>
                            <Square
                              size="40px"
                              bg="primary"
                              color="white"
                              fontWeight="bold"
                              borderRadius="md"
                              boxShadow="8px 8px 30px rgba(25, 135, 84, 0.2)"
                            >
                              3
                            </Square>
                          </Box>
                          <Box>
                            <Heading mb="3" variant="tertiary" as="h3">
                              {t("Never Ending Catalogue")}
                            </Heading>
                            <Text color={isDarkMode?"white":"dark"}>
                              {t("We have more that 40,000 cat records in our database and we are still growing with unmatchable pace.")}
                            </Text>
                          </Box>
                        </Flex>
                      </VStack>
                    </GridItem>
                    <GridItem gridRow={{ base: "1/2", md: "auto" }}>
                      <Image alt="Catalytic Converter detailes" src={DetailsScreenImage} width="100%" height="100%" />
                    </GridItem>
                  </Grid>
                </Box>
              </Container>
              {colorMode==="light" && <Box className="bg-radial-gradient" ></Box>}
              
            </Box>

            <Box as="section" className="pattern-bg" py="24">
              <Container>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
                  gap="14"
                  justifyItems="center"
                  alignItems="center"
                >
                  <GridItem>
                    <Image
                      alt="Mobile"
                      src={MobileScreensImage}
                      width="100%"
                      maxWidth={{ base: "360px", md: "100%" }}
                      height="100%"
                    />
                  </GridItem>
                  <GridItem>
                    <Heading variant="section">
                      <Highlight
                        query="Seamless Experience"
                        styles={{ color: "primary", whiteSpace: "wrap" }}
                      >
                        {t("Download our App to Enjoy a Seamless Experience")}
                      </Highlight>
                    </Heading>
                    <Text color={isDarkMode?"white":"dark"} my="10">
                      {t("Download our App from Google Play and Apple App Store to enjoy using an uninterrupted experience in the palm of your hands.")}
                    </Text>
                    <Flex columnGap="4%" rowGap="4%" flexWrap="wrap">
                      <Link aria-label="Android App" target="_blank" href="https://play.google.com/store/apps/details?id=com.catprices">
                        <Image
                          alt="GooglePlayButton"
                          src={GooglePlayButton}
                          width="100%"
                          maxWidth={{ base: "140px", md: "180px" }}
                          height="100%"
                        />
                      </Link>
                      {/* <Link>
                  <Image
                    width="100%"
                    src={AppStoreButton}
                    maxWidth={{ base: "140px", md: "180px" }}
                  />
                </Link> */}
                    </Flex>
                  </GridItem>
                </Grid>
              </Container>
            </Box>
            <FooterCTA />
          </main>
        </>
      </Box>

      {/*--mobile home page--*/}
      <Box display={{ base: "block", md: "none" }}  >
        <main className="page">
          {/* Hero start */}
          <Box as="section" className=" ">
            <Container py={{
              base: 5,
              md: 5
            }} 
            px={0}
            height="100%">

               <Image
              alt="Catalytic Price Logo"
              src={LogoDark}
              width='180px'
              height='100%'
              margin='auto'
              // marginTop={10}
            />
              <Flex
                h="100%"
                justify="center"
                align="center"
                className="text-center"
              >
                <Box mx="auto" maxWidth="918px">

                  <Text
                    mt={{ sm: "10", base: "4" }}>
                    <Highlight
                      query={["40,000", "PT, PD, RH"]}
                      styles={{ color: "primary", whiteSpace: "wrap" }}
                    >
                      {t("Browse among more than 40,000 catalytic converters prices in our records. Our prices are based on a real ICP results according to PT, PD, RH.")}
                    </Highlight>
                  </Text>
                  <Box mt={8}>
                    <CatSearchBox />
                  </Box>



                  <FormControl mt="10" display='flex' justifyContent="center" alignItems='center'>
                    <FormLabel htmlFor='pricing Per Gram' mb='0'>
                      {t('Ounce ')}
                    </FormLabel>
                    <Switch onChange={() => { setPricingPerGram(!pricingPerGram) }} isChecked={pricingPerGram} colorScheme="green" id='pricing Per Gram' />
                    <FormLabel ml="3" htmlFor='pricing Per Gram' mb='0'>
                      {t('Gram')}
                    </FormLabel>
                  </FormControl>



                  <Flex
                    mt="10"
                    justify="center"
                    align="center"
                    wrap="wrap"
                    gap={8}
                    flexDirection={{
                      base: "column",
                      md: "row"
                    }}
                  >
                    <Flex width={"100%"} >
                      <HStack width={"100%"} >
                        <Circle size="30px" bg="primary" color="white">
                          Pd
                        </Circle>
                        <Box as="span">
                          <Text fontSize="md">
                            {metalPriceState === "loading" ? t("Loading...")
                              : `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}  ${pricingPerGram ? metalPriceData?.pdPriceGram : metalPriceData?.pdPriceOunce}`
                            }
                          </Text>

                        </Box>
                      </HStack>
                      <Text color="gray" textAlign="left" fontSize="sm" mt="1">
                        {`${hours}h:${minutes}m:${seconds}s`}
                      </Text>
                    </Flex>



                    <Flex width={'100%'}>
                      <HStack width={"100%"} spacing={4}>
                        <Circle size="30px" bg="primary" color="white">
                          Pt
                        </Circle>
                        <Box as="span">
                          <Text fontSize="md">
                            {metalPriceState === "loading" ? t("Loading...")
                              : `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")} ${pricingPerGram ? metalPriceData?.ptPriceGram : metalPriceData?.ptPriceOunce} `
                            }
                          </Text>
                        </Box>
                      </HStack>
                      <Text color="gray" textAlign="left" fontSize="sm" mt="1">
                        {`${hours}h:${minutes}m:${seconds}s`}
                      </Text>
                    </Flex>


                    <Flex width={'100%'}>
                      <HStack width={"100%"} spacing={4}>
                        <Circle size="30px" bg="primary" color="white">
                          Rh
                        </Circle>
                        <Box as="span">
                          <Text fontSize="md">
                            {metalPriceState === "loading" ? t("Loading...")
                              : `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")} ${pricingPerGram ? metalPriceData?.rhPriceGram : metalPriceData?.rhPriceOunce} `
                            }
                          </Text>

                        </Box>
                      </HStack>
                      <Text color="gray" textAlign="left" fontSize="sm" mt="1">
                        {`${hours}h:${minutes}m:${seconds}s`}
                      </Text>
                    </Flex>


                  </Flex>
                </Box>
              </Flex>
            </Container>
          </Box>
          {/* Hero end */}
        </main>
      </Box>
    </>

  );
};

export default LandingPage;
