import * as yup from "yup";

export const signupValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required").max(50, "Too Long!"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum"),
  confirmPassword: yup
    .string()
    .required("Password confirmation is required for your security")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  currency: yup.string().required("Kindly select a currency"),
  phoneNumber: yup.string().required("Phone number is required"),
  policyAgreement: yup.boolean().required(`Oh, so you don't agree `),
});

export const INITIAL_STATE = {
  name: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  currency:localStorage?.getItem("currency")|| ' ',
  dialCode: "",
  countryName:localStorage?.getItem("countryName") || ' ',
  countryCode: "",
  policyAgreement: false,
};


export const changePasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum"),
  confirmPassword: yup
    .string()
    .required("Password confirmation is required for your security")
    .oneOf([yup.ref("password"), null], "Passwords must match"),

});