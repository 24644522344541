import { API_BASE_URL } from "constants";
import { apiService } from "services";
import { convertToBase64, getAuthHeaders } from "utils";
import { create } from "zustand";

const useProfile = create((set, get) => {
  return {
    userBalance: {
      state: "loading",
      data: {},
      message: "",
    },
    activePlan: {
      state: "loading",
      data: {},
    },
    ordersHistory: {
      state: "loading",
      data: [],
      offSet: 0,
      pageSize: 5,
      last: false
    },
    creditsHistory: {
      state: "loading",
      data: [],
      offSet: 0,
      pageSize: 5,
      last: false
    },
    searchHistory: {
      state: "loading",
      data: [],
      offSet: "0",
      pageSize: 5,
      last: false
    },
    emailActivation: {
      state: "loading",
      data: {},
    },


    getUserBalance: async ({ }) => {
      apiService.get({
        url: `${API_BASE_URL}/api/v1/balance/user-balance`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          set({
            userBalance: { state: "loaded", data: res.body },
          });
        },
        onFailure: ({ message }) =>
          set({ userBalance: { state: "erred", data: {}, message } }),
      });
    },
    getOrdersHistory: async ({ status = "all", onSuccess, onFailure, }) => {
      apiService.get({
        url: `${API_BASE_URL}/api/v1/order/get-orders`,
        headers: getAuthHeaders(),
        data: [{ key: "status", value: status }],
        onSuccess: (res) => {
          // console.log(res);

          set({ ordersHistory: { 
            state: "loaded",
             data: res.body ,

            } });
          onSuccess?.();
        },
        onFailure: ({ message }) => {
          // console.log(message);
          set({ ordersHistory: { state: "erred", message, data: [] } });

          onFailure?.(message);
        },
      });
    },
    getCreditsHistory: async ({ onSuccess, onFailure }) => {
      const { last, offSet, pageSize } = get().creditsHistory

      apiService.get({
        url: `${API_BASE_URL}/api/v2/history/user-transactions?offSet=${offSet}&pageSize=${pageSize}`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          // console.log(res);
          onSuccess(res)
          set({
            creditsHistory: {
              state: "loaded",
              data: res.body?.content,
              last: res.body?.last,
              offSet: +res.body?.number + 1,
              pageSize: 5
            }
          });
        },
        onFailure: ({ message }) => {
          // console.log(message);

          set({
            creditsHistory: {
              state: "erred",
              message,
              data: [],

            },
          });

          onFailure?.(message);
        },
      });
    },
    getOrderHistoryDetais: async ({ onSuccess, onFailure , orderNo}) => {
      // console.log({orderNo})
      apiService.get({
        url: `${API_BASE_URL}/api/v1/order/order-details?orderNo=${orderNo}`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          // console.log(res);
          onSuccess(res)
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },
    getSearchHistory: async ({ onSuccess, onFailure }) => {
      const { last, offSet, pageSize ,data} = get().searchHistory
      // console.log({ last, offSet, pageSize , } , getAuthHeaders())
      //((prev) => ({ ...prev, state: "loading" }));
      //set({ searchHistory: {  state: "loadeding", } });

      apiService.get({
        url: `${API_BASE_URL}/api/v2/history/user-show-price`,
        headers: getAuthHeaders(),
        data: [
          // {key: "search", value: search},
          {key: "offSet", value: offSet.toString()},
          {key: "pageSize", value: pageSize},
     
        ],
        onSuccess: (res) => {
          // console.log(res);
          //console?.log(res.body?.last)
          set({
            searchHistory: {
              state: "loaded",
              data: [...data ,...res.body?.content],
              last: res.body?.last,
              offSet: offSet+1,
              pageSize: pageSize
            }
          });

          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          // console.log(message);

          set({
            creditsHistory: {
              state: "erred",
              data: [],
              message,
            },
          });

          onFailure?.(message);
        },
      });
    },
    updateUserProfile: async ({ data, onSuccess, onFailure }) => {
      // console.log({data});
      if (typeof data.image !== "string")
        data.image = await convertToBase64(data.image);

      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/update-profile`,
        headers: getAuthHeaders(),
        data: JSON.stringify(data),
        onSuccess: (res) => {
          //console.log(res);
          
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          //console.log(message);

          onFailure?.(message);
        },
      });

      
    },

    getUserActivePlan: () => {
      apiService.get({
        url: `${API_BASE_URL}/apis/v1/user/user-active-plan`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
         // console.log(res);
          set({ activePlan: { state: "loaded", data: res.body ,  } });
        },
        onFailure: ({ message }) => {
          set({ activePlan: { state: "erred", data: {} , message } });
        },
      });
    },

    changePassword: ({ oldPassword, newPassword, onSuccess, onFailure }) => {
      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/change-password`,
        headers: getAuthHeaders(),
        data: JSON.stringify({
          oldPassword,
          newPassword,
          confirmPassword: newPassword,
        }),
        onSuccess: (res) => {
          //console.log(res);
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          //console.log(message);
          onFailure?.(message);
        },
      });
    },

    sendEmailActivation: ({ email, onSuccess, onFailure }) => {
      apiService.post({
        url: `${API_BASE_URL}/api/v1/activations/send-email-activation-code`,
        headers: getAuthHeaders(),
        data: JSON.stringify({ "recipient": email, "codeReason": "EMAIL_CONFIMRATION" }),
        onSuccess: (res) => {
          //console.log(res);
          set({ emailActivation: { state: "loaded", data: res.body } });

          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          //console.log(message);
          onFailure?.(message);
        },
      });
    },
    verifyEmailOtp: ({ otp, onSuccess, onFailure, emailActivation }) => {
    //  console.log(otp, emailActivation,)
      apiService.post({
        url: `${API_BASE_URL}/api/v1/activations/active-user-email`,
        headers: getAuthHeaders(),
        data: JSON.stringify({
          "token": emailActivation,
          "code": otp
        }),
        onSuccess: (res) => {
         // console.log(res);
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
         // console.log(message);
          onFailure?.(message);
        },
      });
    },

  };
});

export default useProfile;
