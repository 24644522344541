import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  IconCashBanknote,
  IconEye,
  IconEyeOff,
  IconUser,
} from "@tabler/icons";
import {SassyPhoneInput} from "components";
import CountryDropDown from "components/country-dropDown";
import {Field, Form, Formik} from "formik";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "stores";
import {getToastData} from "utils";
import {INITIAL_STATE, signupValidationSchema} from "./utils";
import currencies from "../../../../constants/commonCurrency.json";
import { useTranslation } from 'react-i18next';

const AuthSignUpForm = () => {
  const {t} = useTranslation()
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  const [{showPassword, showConfirmPassword}, setPasswordsState] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  const {registerUser} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const submitHandler = (values) => {
    toast(getToastData({status: "loading", title: "Signing up"}));

    registerUser({
      userData: values,
      onSuccess: (res) => {
        toast.closeAll();
        toast(
          getToastData({
            title: t("You have successfully signed up."),
            status: "success",
          })
        );
        setTimeout(() => {
          navigate("/auth/verify-otp");
        }, 1500);
      },
      onFailure: (errorMessage) => {
        toast.closeAll();

        if (errorMessage === "USER ALREADY EXIST BUT NOT ACTIVE") {
          toast(
            getToastData({
              title:
               t( "Your account already exists but not active. Verify OTP to activate it."),
            })
          );
          setTimeout(() => {
            navigate("/auth/verify-otp");
          }, 1500);
          return;
        }
        toast(getToastData({title: errorMessage, status: "error"}));
      },
    });
  };

  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={INITIAL_STATE}
      enableReinitialize
      validationSchema={signupValidationSchema}
    >
      {/* {({values, errors, touched}) => ( */}
      <Form>
        <VStack align="stretch" spacing="6">
          <Field name="name">
            {({field, form}) => (
              <FormControl isInvalid={form.errors.name && form.touched.name}>
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Full Name")}
                  </FormLabel>
                )}
                <InputGroup>
                  <Input
                  color={isDarkMode ? "white":"dark"}
                  bgColor={isDarkMode?"darkModeBg": "white"}
                    {...field}
                    placeholder={t("Full Name")}
                    size="lg"
                    fontSize="16"
                  />
                  <InputRightElement
                    color="gray"
                    children={<IconUser size="18" />}
                  />
                </InputGroup>
                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="phoneNumber">
            {({field, form}) => (
              <FormControl
                isInvalid={
                  (form.errors.phoneNumber && form.touched.phoneNumber) ||
                  !isPhoneNumberValid
                }
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Phone Number")}
                  </FormLabel>
                )}
                <SassyPhoneInput
                  onChange={(phone, country ) => {
                    const phoneNumber = phone.replace(country.dialCode , '')
                    // if (
                    //   country.format?.split(".").length - 1 !==
                    //   phone.length
                    // ) {
                    //   console.log(
                    //     country.format?.split(".").length - 1,
                    //     phone.length
                    //   );
                    //   setIsPhoneNumberValid(false);
                    // } else {
                    //   setIsPhoneNumberValid(true);
                    // }
                    // form.setFieldValue("countryName", country.name);
                    form.setFieldValue(
                      "countryCode",
                      country.countryCode?.toUpperCase()
                    );
                    form.setFieldValue("dialCode", country.dialCode);
                    form.setFieldValue(
                      "phoneNumber",
                      phoneNumber
                    );
                  }}
                />
                <FormErrorMessage>
                  {form.errors.phoneNumber ||
                    (!isPhoneNumberValid
                      ?t("Phone number does not seem to be valid.")
                      : "")}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="password">
            {({field, form}) => (
              <FormControl
                isInvalid={form.errors.password && form.touched.password}
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Password")}
                  </FormLabel>
                )}
                <InputGroup>
                  <Input
                  color={isDarkMode ? "white":"dark"}
                  bgColor={isDarkMode?"darkModeBg": "white"}
                    {...field}
                    placeholder={t("Password")}
                    size="lg"
                    type={showPassword ? "text" : "password"}
                    fontSize="16"
                  />
                  <InputRightElement
                    color="gray"
                    onClick={() =>
                      setPasswordsState((prev) => ({
                        ...prev,
                        showPassword: !prev.showPassword,
                      }))
                    }
                    children={
                      showPassword ? (
                        <IconEyeOff size="18" />
                      ) : (
                        <IconEye size="18" />
                      )
                    }
                  />
                </InputGroup>
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="confirmPassword">
            {({field, form}) => (
              <FormControl
                isInvalid={
                  form.errors.confirmPassword && form.touched.confirmPassword
                }
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Confirm Password")}
                  </FormLabel>
                )}
                <InputGroup>
                  <Input
                  color={isDarkMode ? "white":"dark"}
                  bgColor={isDarkMode?"darkModeBg": "white"}
                    {...field}
                    placeholder={t("Confirm Password")}
                    size="lg"
                    type={showConfirmPassword ? "text" : "password"}
                    fontSize="16"
                  />
                  <InputRightElement
                    color="gray"
                    onClick={() =>
                      setPasswordsState((prev) => ({
                        ...prev,
                        showConfirmPassword: !prev.showConfirmPassword,
                      }))
                    }
                    children={
                      showConfirmPassword ? (
                        <IconEyeOff size="18" />
                      ) : (
                        <IconEye size="18" />
                      )
                    }
                  />
                </InputGroup>
                <FormErrorMessage>
                  {form.errors.confirmPassword}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="countryName">
            {({field, form}) => (
              <FormControl
                isInvalid={
                  (form.errors.countryName && form.touched.countryName)
                }
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Country")}
                  </FormLabel>
                )}
                <CountryDropDown value={field.value} onChange={(value)=>{
                  form.setFieldValue("countryName", value);
                }} />
                <FormErrorMessage>
                  {form.errors.countryName }
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="currency">
            {({field, form}) => (
              <FormControl
                isInvalid={form.errors.currency && form.touched.currency}
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Currency")}
                  </FormLabel>
                )}
                <InputGroup>
                  <Select
                    placeholder={t("Choose your currency")}
                    icon={<IconCashBanknote color="gray" />}
                    size="lg"
                    onChange={(e) => {
                      form.setFieldValue("currency", e.target.value);
                    }}
                    fontSize="16"
                    color="dark"
                    _placeholder={{color: "gray"}}
                  >
                    {Object.keys(currencies).map((currency,i)=>(
                      <option 
                      selected={currency === localStorage?.getItem("currency")}
                       key={i} value={currency}>{currency} - {currencies[currency].name}</option>
                    ))}
                    {/* <option value="SAR">SAR</option>
                    <option value="PKR">PKR</option>
                    <option value="USD">USD</option> */}
                  </Select>
                </InputGroup>
                <FormErrorMessage>{form.errors.currency}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Checkbox colorScheme="green">
            <Text fontSize="sm">
              {t("I agree to the")}{" "}
              <Link aria-label={t("Terms of Services")} className="text-primary">{t("Terms of Services")}</Link> &{" "}
              <Link aria-label= {t("Terms of Services of the Catalytic Price website.")} className="text-primary"></Link> {t("of the Catalytic Price website.")}
            </Text>
          </Checkbox>
        </VStack>
        <Button color={"white"} width="100%" mt="6" size="lg" type="submit">
          {t("Register")}
        </Button>
      </Form>
      {/* )} */}
    </Formik>
  );
};

export default AuthSignUpForm;
