import {Box, Card, Center, Image, Link, Text, useColorMode} from "@chakra-ui/react";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {sluggify} from "utils";

const CarBrand = ({
  brand,
  size = "lg",
  boxShadow = "0px 0px 5px rgba(0, 0, 0, 0.15)",
  border = "0",
  showTitle = true,
  boxSize,
  ...props
}) => {

  
  const {makerName, makerImage} = brand;
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  const slug = sluggify(makerName);

  return (
   <>
     <Card
      boxSize={{base:boxSize ||"120px" ,  md:boxSize || "200px"}}
      cursor="pointer"
      p="5"
      // bgColor={colorMode === "light" &&  "white"}
      boxShadow={ (colorMode === "light") ? boxShadow:"0px 0px 5px rgba(0, 0, 0, 0.65)"}
      border={border}
      borderRadius="lg"
      {...props}

    >
      <Link
        aria-label={slug}
        as={RouterLink}
        to={`/car-brands/${slug}`}
        display="inline-block"
        height="100%"
      >
        <Box height="100%">
          <Center width="100%" height="100%">
            <Image
              alt={makerName}
              src={makerImage}
              size={size}
              width="150px"
              height="100%"
              fallbackSrc="https://via.placeholder.com/150"
            />
          </Center>
        </Box>
        {showTitle && makerName && (
          <Text
            textAlign="center"
            textTransform="uppercase"
            mt="6"
            fontWeight="semibold"
            fontSize="18"
            color={isDarkMode?"white":"dark"}
          >
            {makerName}
          </Text>
        )}
      </Link>
    </Card>
   </>
  );
};

export default CarBrand;
