import * as React from 'react';
import { Text } from '@chakra-ui/react';

const ListItem = ({itemKey , value}) => {
    return (
        <>
            <Text color="dark" fontSize="14px" mt="1" display="flex">
            <Text fontWeight={"500"} mr="2">{itemKey} </Text>  {value} </Text>
        </>
    );
}

export default ListItem