import { API_BASE_URL } from "constants";
import { apiService } from "services";
import { getAuthHeaders, getCookies, setCoockies } from "utils";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { randomCode, removeCookies } from "./../utils/utils-functions";

const useAuth = create(
  devtools((set, get) => ({
    user: {
      state: "loaded",
      isLoggedIn: false,
      data: {},
      currency: localStorage?.getItem("defCurrency"),
    },
    otpData: {
      state: "loading",
      data: {},
    },
    forgetPassData: {
      mobileCode: "",
      mobileNo: "",
    },
    loginUser: async ({
      dialCode,
      phoneNumber,
      password,
      onSuccess,
      onFailure,
    }) => {
      let devId = localStorage.getItem( "_devId") // getCookies("_devId");
      const getDevId = async () => {
        if (!devId) {
          devId = randomCode(50);
        localStorage.setItem( "_devId" , devId)
          // setCoockies({ name: "_devId", value: devId });
        }
      };
      await getDevId();
      // macaddress.one(function (err, mac) {
      //   console.log("Mac address for this host: %s", mac);
      // });
      // console.log({
      //   username: phoneNumber,
      //   mobileCode: dialCode,
      //   password,
      //   "deviceId": devId,
      //   "fcmToken": localStorage.getItem("fcmToken") || ' ',
      //   "deviceDetails": '',
      //   "secretClientDetails": ""

      // })
      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/login`,
        headers: getAuthHeaders(),
        data: {
          username: phoneNumber,
          mobileCode: dialCode,
          password,
          deviceId: devId,
          fcmToken: localStorage.getItem("fcmToken") || devId,
          deviceDetails: "",
          secretClientDetails: "",
        },
        onSuccess: (res) => {
          setCoockies({ name: `_Ud`, value: JSON.stringify(res.body) });
          setCoockies({ name: "_Ut", value: res.body.token });
          const defCurrency = res.body.defCurrency
          if( defCurrency &&  !(defCurrency === "USD" || defCurrency === "ZAR" || defCurrency === "EUR") ){
            localStorage.setItem('defCurrency' , "USD")
          }else{
            localStorage.setItem("defCurrency", res.body.defCurrency);
          }
          // localStorage.setItem("defCurrency", res.body.defCurrency);
          
          set(() => ({
            user: {
              state: "loaded",
              data: res.body,
              isLoggedIn: true,
              currency: res.body.defCurrency,
            },
          }));
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          // console.log({
          //   dialCode,
          //   phoneNumber,
          //   password,
          // })
          // set(() => ({
          //   user: {
          //     state: "erred",
          //     data: { mobileNo: phoneNumber, mobileCode: dialCode },
          //     isLoggedIn: false,
          //   },
          // }));
          onFailure?.(message);
        },
      });
      
    },
    updataUser: ({ user }) => {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("defCurrency", user.defCurrency);
     
      //document.cookie = `user=${res.body.token}`
      set(() => ({
        user: {
          state: "loaded",
          data: user,
          isLoggedIn: true,
          currency: user.defCurrency,
        },
      }));
    },
    registerUser: async ({ userData, onSuccess, onFailure }) => {
      const {
        name: fullNameEn,
        dialCode: mobileCode,
        countryName: countryEn,
        phoneNumber: mobileNo,
        currency: defCurrency,
        password,
        countryCode,
      } = userData;

      const data = JSON.stringify({
        fullNameEn,
        mobileNo,
        mobileCode,
        countryEn,
        defCurrency,
        countryCode,
        password,
      });

      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/register`,
        headers: getAuthHeaders(),
        data,
        onSuccess: (res) => {
          set(() => ({ user: { state: "loaded", data: res.body } }));
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          set(() => ({
            user: {
              state: "erred",
              data: { mobileNo, mobileCode },
            },
          }));
          onFailure?.(message);
        },
      });
    },
    logoutUser: () => {
      removeCookies("_Ud");
      removeCookies("_Ut");
      localStorage.removeItem("defCurrency");
      set({ user: { isLoggedIn: false, state: "loading", data: {} } });
      window?.location.reload()
    },
    sendOtp: ({ onSuccess, onFailure }) => {
      apiService.post({
        url: `${API_BASE_URL}/api/v1/sms/register-otp`,
        // headers: getAuthHeaders(),
        data: {
          mobileCode: get().user.data.mobileCode,
          mobileNo: get().user.data.mobileNo,
          smsHashCode: " ",
        },
        onSuccess: (res) => {
          //console.log({ res });
          set({ otpData: { state: "loaded", data: res.body } });
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          set({ otpData: { state: "erred", data: {} } });
          onFailure?.(message);
        },
      });
    },
    verifyOtp: ({ otpCode, activationToken, onSuccess, onFailure }) => {
      const data = JSON.stringify({
        activationCode: otpCode,
        activationToken,
      });

      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/user-activation`,
        headers: getAuthHeaders(),
        data,
        onSuccess: (res) => {
          set((state) => ({ user: { isLoggedIn: false, ...state.user } }));

          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },
    handleAuth: async () => {
      let user = getCookies("_Ud");
      if (user) {
        const userData = await JSON?.parse(user);
        set({
          user: {
            state: "loaded",
            isLoggedIn: true,
            data: userData,
            currency: userData?.defCurrency,
          },
        });
      }
    },
    changeCurrancy: (currancy) => {
      const user = get()?.user;
      // console?.log(user)
      set({
        user: {
          ...user,
          currency: currancy,
        },
      });
    },

    //==============
    //forget Pass
    //=============
    sendForgetPassOtp: ({ onSuccess, onFailure }) => {
      const data = {
        mobileCode: get()?.forgetPassData?.mobileCode,
        mobileNo: get()?.forgetPassData?.mobileNo,
        smsHashCode: " ",
      }
      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/forgot-password`,
        headers: getAuthHeaders(),
        data,
        onSuccess: (res) => {
          //console.log({ res });
          set({ otpData: { state: "loaded", data: res.body ,  } });
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          set({ otpData: { state: "erred", data: {} } });
          onFailure?.(message);
        },
      });
    },
    setForgetPassPhone: ({ mobileCode, mobileNo }) => {
      set({ forgetPassData: { mobileCode, mobileNo , } });
    },
    verifyForgetPasswordOtp: ({ otp, onSuccess, onFailure }) => {
      const data = {
        otp: otp,
        otpToken: get()?.otpData?.data?.otpToken,
      };

      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/validation-forgot-otp`,
        headers: getAuthHeaders(),
        data,
        onSuccess: (res) => {
          set({ otpData: { state: "loaded", data:get()?.otpData?.data ,  otp} });
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },


    createNewPassword: ({ newPassword, confirmPassword, onSuccess, onFailure }) => {
      //console.log(get()?.otpData?.data)
      const data = {
        otp: get()?.otpData?.otp,
        otpToken: get()?.otpData?.data?.otpToken,
        newPassword,
        confirmPassword
      };
    
      apiService.post({
        url: `${API_BASE_URL}/apis/v1/user/validation-forgot-password`,
        headers: getAuthHeaders(),
        data,
        onSuccess: (res) => {
          set((state) => ({ user: { isLoggedIn: false, ...state.user } }));

          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },
  }))
);

export default useAuth;
