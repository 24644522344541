import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React, {useState} from "react";
import {useFavorites} from "stores";
import {getToastData} from "utils";
import { useTranslation } from 'react-i18next';

const NewFavoritesCollectionModal = ({isOpen, onClose}) => {
  const {t} = useTranslation()
  const [{state, collectionName}, setViewState] = useState({
    state: "",
    collectionName: "",
  });
  const {createFavoritesCollection} = useFavorites();

  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    setViewState((prev) => ({...prev, state: "loading"}));
    createFavoritesCollection({
      collectionName,
      onSuccess: (res) => {
        setViewState({state: "loaded", collectionName: ""});
        toast(
          getToastData({
            status: "success",
            title: t("Favorites collection created successfully."),
          })
        );
        setTimeout(() => {
          onClose();
        }, 500);
      },
      onFailure: (message) => {
        setViewState({state: "erred", collectionName: ""});
        toast(
          getToastData({
            status: "error",
            title: message,
          })
        );
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      mx="10"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent py="10">
        <ModalHeader textAlign="center">
          <Heading as="h2" fontSize="26px" fontWeight="semibold">
            {t("Create a New Favorites Collection")}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>{t("Collection Name")}:</FormLabel>
              <Input
                type="text"
                required
                value={collectionName}
                onChange={(e) =>
                  setViewState({state: "", collectionName: e.target.value})
                }
                placeholder={t("New Collection name goes here")}
              />
            </FormControl>
            <Button aria-label={t("Create new favourite collection")} type="submit" mt="4" isLoading={state === "loading"}>
              {t("Create")}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewFavoritesCollectionModal;
