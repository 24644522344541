import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useToast, VStack} from "@chakra-ui/react";
import {Loader, PurchaseItem, SassyAlert} from "components";
import useApiEffect from "hooks/use-api-effect";
import {useFavorites} from "stores";
import {getToastData} from "utils";

const FavoritesCollectionItems = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const {collectionId} = useParams();
  const {getFavoritesCollectionItems, removeItemFromFavorites} = useFavorites();

  const [collectionItems, setCollectionItems] = useState({
    state: "loading",
    data: [],
  });

  const handleRemoveFavorite = (catId) => {
    toast(getToastData({status: "loading", title: "Removing from favorites"}));
    removeItemFromFavorites({
      catId,
      onSuccess: (res) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "success",
            title: "Item removed successfully from favorites",
          })
        );
        navigate("/profile/favorites");
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "error",
            title: "There was an error while removing this item from favorites",
          })
        );
      },
    });
  };

  useApiEffect(() => {
    getFavoritesCollectionItems({
      collectionId: parseInt(collectionId),
      onSuccess: (res) => setCollectionItems({state: "loaded", data: res.body}),
      onFailure: (errorMessage) =>
        setCollectionItems({state: "erred", data: [], errorMessage}),
    });
  }, []);

  return collectionItems.state === "erred" ? (
    <SassyAlert
      status="error"
      title={collectionItems.errorMessage || "Error Encountered."}
      description="We have got an error while fetching all car brands."
    />
  ) : collectionItems.state === "loading" ? (
    <Loader />
  ) : collectionItems.data.cats.length > 0 ? (
    <VStack align="stretch" spacing="8">
      {collectionItems.data.cats.map((c) => (
        <PurchaseItem
          name={c.catSn || c?.catNo}
          onFavorite={() => handleRemoveFavorite(c.catId)}
          image={c.image}
        />
      ))}
    </VStack>
  ) : (
    <SassyAlert
      status="info"
      title="You haven't added any favorites in this collection."
    />
  );
};

export default FavoritesCollectionItems;
