import { Box, Heading, useColorMode,  } from "@chakra-ui/react";
import React, { useState } from "react";
// import { useParams } from "react-router-dom";

import ChoosePaymentMehtodForm from "./choose-payment-method-form";

import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useFeatures } from "stores";
import useApiEffect from 'hooks/use-api-effect';
import { Loader, SassyAlert } from "components";

const PlanPurchasingForm = () => {
  const { paymentData } = useFeatures()
  // const { planCode } = useParams();
  const [preferredMethod, setPreferredMethod] = useState("");
  const { publishableKey, clientSecret } = paymentData?.data
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  //console.log({ paymentData })
  useApiEffect(() => {
    // addCustomer({
    //   onSuccess: (res) => { console.log(res) },
    //   onFailure: (errorMessage) => { console.log(errorMessage) },
    // })
    // initPayment({
    //   planCode,
    //   subscribe: false,
    //   onSuccess: (res) => { console.log(res) },
    //   onFailure: (errorMessage) => { console.log(errorMessage) },
    // })
  }, []);

  return (
    <>
      {/* <main className="page"  > */}
        {/* <Box as="section" className="hero cart-hero" /> */}
        {/* <Box as="section" py="24" maxWidth="1100px" alignSelf="center"> */}

          <Heading variant="section" textAlign="center" maxWidth="500px" mx="auto">
            {/* <Highlight
            query={["Payment Method", "Credit/Debit Card", "PayPal"]}
            styles={{ color: "primary", whiteSpace: "wrap" }}
          >
            {formTitle}
          </Highlight> */}
          </Heading>

          <Box mt="12" >
            {paymentData.state === "erred" ? (
              <SassyAlert status="error" title={paymentData.message} />
            ) : paymentData.state === "loading" ? (
              <Loader />
            ) : (
              <Elements stripe={loadStripe(publishableKey)} options={{ clientSecret }}>
                <ChoosePaymentMehtodForm setPreferredMethod={setPreferredMethod} />
              </Elements>
            )}
          </Box>
        {/* </Box>
      </main> */}
    </>
  );
};

export default PlanPurchasingForm;

// Pay Via Credit/Debit Card
// Pay Via PayPal
