import {Box, Heading, Highlight, Text, useColorMode} from "@chakra-ui/react";
import {AuthLoginForm} from "modules";
import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AuthLogin = ({setModalScreen , onClose}) => {
  const {t} = useTranslation()
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <Box as="section">
      <Heading fontSize="30" mb="2" fontWeight="semibold">
        {!setModalScreen && <Highlight
          query="Catalytic Price!"
          styles={{color: "primary", whiteSpace: "wrap"}}
        >
          {t("Welcome to Catalytic Price!")}
        </Highlight>}
      </Heading>
      <Text color={isDarkMode ? "white":"black" }textAlign={setModalScreen?'center':"left"} fontSize={setModalScreen?"30":"22"} mb={setModalScreen?"0":"10"}>
        <Highlight
          query={t("Login")}
          styles={{
            color: "primary",
            whiteSpace: "wrap",
            fontWeight: "semibold",
          }}
        >
          {t("Login to Your account")}
        </Highlight>
      </Text>
      {setModalScreen && <Text mb="5" textAlign={'center'}>{t('Login into your account to see all benefits and catalytic converters price')}</Text>}

      <AuthLoginForm onClose={onClose}  setModalScreen={setModalScreen} />

      <Box textAlign="center" mt="6">
        <Text>{t("Don't have an account?")}</Text>
       <Link onClick={onClose} aria-label= {t("Register here")}  to="/auth/signup">
          <Text color="primary" fontWeight="medium">
            {t("Register here")}
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default AuthLogin;
