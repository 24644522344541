import {
  Heading,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { BrandLogo } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

const ListCatItem = ({ title, value }) => {
  const { t } = useTranslation();
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <ListItem
      pt="4"
      pb="3"
      borderBottomWidth="1.7px"
      borderBottomColor={isDarkMode ? "#2f2f2f":"gray.200"}
      borderBottomStyle="solid"
    >
      <HStack justify="space-between">
        <Heading as="h4"  fontWeight="semibold" fontSize="18">
          {title}
        </Heading>
        <Text color={isDarkMode?"":"dark"} fontSize="15">
          {value || t("Unknown")}
        </Text>
      </HStack>
    </ListItem>
  );
};

const CatSpecifications = ({ catDetails }) => {
  const { t } = useTranslation();
  //console.log({ catDetails });
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <UnorderedList listStyleType="none" ml="0">
      <Heading 
        pb="3"
        borderBottomWidth="1.7px"
        borderBottomColor={isDarkMode ? "#2f2f2f":"gray.200"}
        borderBottomStyle="solid"
         as="h1"  fontWeight="semibold" fontSize="25">
        {catDetails.catNo  || catDetails.catSn}
      </Heading>


      <ListItem
        pt="4"
        pb="3"
        borderBottomWidth="1.7px"
        borderBottomColor={isDarkMode ? "#2f2f2f":"gray.200"}
        borderBottomStyle="solid"
      >
        <HStack justify="space-between" align="start">
          <Heading as="h4" fontWeight="semibold" fontSize="18">
            {t("Brands")}
          </Heading>

          <VStack gap="3" align="flex-end" justify="flex-start">
            {catDetails.brands.map((b) => (
              <HStack key={b.brandId} gap="4" justify="flex-end">
                <Heading
                  as="h6"
                  fontWeight="semibold"
                  color="primary"
                  fontSize="16"
                >
                  {b.makerName}
                </Heading>
                <BrandLogo logo={b.makerImage} size="45px" p="2" />
              </HStack>
            ))}
          </VStack>
        </HStack>
      </ListItem>

      {
        <ListCatItem
          title={t("Catalytic No")}
          value={catDetails.catNo || t("Unknown")}
        />
      }
      {
        <ListCatItem
          title={t("Catalytic Serial")}
          value={catDetails.catSn || t("Unknown")}
        />
      }
     
       <ListCatItem
          title={t(" Car Models") }
          value={catDetails?.carModel?.replace("[",'')?.replace("]",'')?.replace(`"`,'')?.replace('"','') || t("Unknown")}
        />
      {
        <ListCatItem
          title={t("Ref 1")}
          value={catDetails.ref1 || t("Unknown")}
        />
      }
      {
        <ListCatItem
          title={t("Ref 2")}
          value={catDetails.ref2 || t("Unknown")}
        />
      }

      {/* {
        <ListItem
          pt="4"
          pb="3"
          borderBottomWidth="1.7px"
          borderBottomColor={isDarkMode ? "#2f2f2f":"gray.200"}
          borderBottomStyle="solid"
        >
          <HStack justify="space-between" align="start">
            <Heading as="h4" color="black" fontWeight="semibold" fontSize="18">
              Car Models
            </Heading>

            <VStack gap="2" align="flex-end" justify="flex-start" color="dark">
              <Text>BMW-B5</Text>
              <Text>Nissan-N6</Text>
            </VStack>
          </HStack>
        </ListItem>
      } */}

      {/* {
        <ListItem
          pt="4"
          pb="3"
          borderBottomWidth="1.7px"
          borderBottomColor={isDarkMode ? "#2f2f2f":"gray.200"}
          borderBottomStyle="solid"
        >
          <HStack justify="space-between" align="start">
            <Heading as="h4" color="black" fontWeight="semibold" fontSize="18">
              Car Description
            </Heading>

            <VStack
              gap="2"
              align="flex-end"
              justify="flex-start"
              color="dark"
              textAlign="right"
            >
              <Box>
                <Text>BMW-B5 A34</Text>
              </Box>
            </VStack>
          </HStack>
        </ListItem>
      } */}

      {/* {
        <ListItem
          pt="4"
          pb="3"
          borderBottomWidth="1.7px"
          borderBottomColor="gray.200"
          borderBottomStyle="solid"
        >
          <HStack justify="space-between" align="start">
            <Heading as="h4" color="black" fontWeight="semibold" fontSize="18">
              Manufacturer
            </Heading>

            <Heading
              as="h6"
              color="primary"
              fontWeight="semibold"
              fontSize="17px"
            >
              {catDetails.maker}
            </Heading>
          </HStack>
        </ListItem>
      } */}
      {
        <ListItem
          pt="4"
          pb="3"
          borderBottomWidth="1.7px"
          borderBottomColor={isDarkMode ? "#2f2f2f":"gray.200"}
          borderBottomStyle="solid"
        >
          <HStack justify="space-between" align="start">
            <Heading as="h4"  fontWeight="semibold" fontSize="18">
              {t("Type")}
            </Heading>

            <Heading
              as="h6"
              color="primary"
              fontWeight="semibold"
              fontSize="17px"
            >
              {catDetails.type}
            </Heading>
          </HStack>
        </ListItem>
      }
    </UnorderedList>
  );
};

export default CatSpecifications;
