import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import {
  IconArrowNarrowRight,
  IconBuilding,
  IconChartPie2,
  IconUser,
  IconUsers,
} from "@tabler/icons";
import {Field, Form, Formik} from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
const INITIAL_VALUES = {
  companyName: "",
  companyRegNo: "",
  directorName: "",
  status: "",
  companyNumber: "",
  companyScale: "",
};

const validationSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required."),
  companyRegNo: yup
    .string()
    .required("Company registration number is required."),
  directorName: yup.string().required("Director name is required."),
  status: yup.string().required("Status name is required."),
  companyNumber: yup.string().required("Company number is required."),
  companyScale: yup.string().required("Company scale is required."),
});
const PartnerAboutForm = ({initialValues, onSubmit, onBack}) => {
  const{t}= useTranslation()

  return (
    <Formik
      initialValues={initialValues || INITIAL_VALUES}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
       // console.log(values);
        onSubmit(values);
      }}
    >
      <Form>
        <Grid
          gap="12"
          justifyContent="center"
          alignItems="end"
          templateColumns={{sm: "repeat(2,1fr)"}}
        >
          <GridItem>
            <Field name="companyName">
              {({field, form}) => (
                <FormControl
                  isInvalid={
                    form.errors.companyName && form.touched.companyName
                  }
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t('Company Name')}
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input {...field} placeholder={t("Company Name")} />
                    <InputRightElement
                      color="dark"
                      children={<IconBuilding size="18" />}
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.companyName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
          <GridItem>
            <Field name="companyRegNo">
              {({field, form}) => (
                <FormControl
                  isInvalid={
                    form.errors.companyRegNo && form.touched.companyRegNo
                  }
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      Company Registration#
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input {...field} placeholder={t("Company Registration")} />
                    <InputRightElement
                      color="dark"
                      children={<IconBuilding size="18" />}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.companyRegNo}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
          <GridItem>
            <Field name="directorName">
              {({field, form}) => (
                <FormControl
                  isInvalid={
                    form.errors.directorName && form.touched.directorName
                  }
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t('Director Name')}
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input {...field} placeholder={t("Director Name")} />
                    <InputRightElement
                      color="dark"
                      children={<IconUser size="18" />}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.directorName}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
          <GridItem>
            <Field name="status">
              {({field, form}) => (
                <FormControl
                  isInvalid={form.errors.status && form.touched.status}
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t('Status')}
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input {...field} placeholder="Status" />
                    <InputRightElement
                      color="dark"
                      children={<IconChartPie2 size="18" />}
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.status}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
          <GridItem>
            <Field name="companyNumber">
              {({field, form}) => (
                <FormControl
                  isInvalid={
                    form.errors.companyNumber && form.touched.companyNumber
                  }
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t('Company Number')}
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input
                      // as={
                      //   <IntlTelInput
                      //     containerClassName="intl-tel-input"
                      //     inputClassName="chakra-input"
                      //   />
                      // }
                      {...field}
                      placeholder={t("Telephone No")}
                    />
                    {/* <IntlTelInput
                        containerClassName="intl-tel-input"
                        inputClassName="chakra-input"
                      /> */}
                    <InputRightElement
                      color="dark"
                      children={<IconBuilding size="18" />}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.companyNumber}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
          <GridItem>
            <Field name="companyScale">
              {({field, form}) => (
                <FormControl
                  isInvalid={
                    form.errors.companyScale && form.touched.companyScale
                  }
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t('Company Scale')}
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input {...field} placeholder={t("Company Scale")} />
                    <InputRightElement
                      color="dark"
                      children={<IconUsers size="18" />}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.companyScale}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </GridItem>
        </Grid>
        <Flex justify="flex-end" mt="10">
          <HStack spacing="4">
            {onBack && (
              <Button aria-label={t('Back')} onClick={onBack} variant="outline" color="primary">
                {t('Back')}
              </Button>
            )}
            {onSubmit && (
              <Button aria-label={t('Next')} style={{cursor:'no-drop' , opacity:0.5}} type="submit" rightIcon={<IconArrowNarrowRight />}>
                {t('Next')}
              </Button>
            )}
          </HStack>
        </Flex>
      </Form>
    </Formik>
  );
};

export default PartnerAboutForm;
