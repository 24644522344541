import {API_BASE_URL} from "constants";
import {apiService} from "services";
import {getAuthHeaders} from "utils";
import {create} from "zustand";

const useCart = create((set) => ({
  cart: [],
  newOrder: {
    state: "initial",
    data: {},
  },
  emptyCart: () => set({cart: []}),
  addItemToCart: (itemId, item) => {
    set((state) => {
      const found = state.cart.find((i) => i.catId === itemId);
      const others = state.cart.filter((i) => i.catId !== itemId);
      if (found) {
        found.cartQty = found.cartQty + 1;
        return {cart: [found, ...others]};
      } else {
        return {cart: [{...item, cartQty: 1}, ...state.cart]};
      }
    });
  },
  removeItemFromCart: (itemId) => {
    set((state) => {
      const updatedCart = [];

      state.cart.forEach((i) => {
        if (i.catId !== itemId) {
          updatedCart.push(i);
          return;
        }
        if (i.cartQty === 1) return;
        updatedCart.push({...i, cartQty: i.cartQty - 1});
      });

      return {cart: updatedCart};
    });
  },
  saveNewOrder: ({orderData, onSuccess, onFailure}) => {
    set({newOrder: {state: "loading", data: {}}});
    const reqBody = orderData.map((i) => ({
      catId: i.catId,
      catPrice: i.catPrice,
      catSerial: i.catSn,
      catNo: i.catNo,
      catImage: i.images?.[0]?.fullImageURL,
      catBrands: JSON.stringify(i.brand),
      qty: i.cartQty,
    }));

    apiService.post({
      url: `${API_BASE_URL}/api/v1/order/save-order`,
      headers: getAuthHeaders(),
      data: JSON.stringify(reqBody),
      onSuccess: (res) => {
        //console.log({res});
        set({newOrder: {state: "placed", data: res.body}});
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        //console.log(message);
        set({
          newOrder: {state: "erred", data: {}, errorMessage: message},
        });
        onFailure?.(message);
      },
    });
  },
  getCatsPrices: ({catsIds, onSuccess, onFailure}) => {
    apiService.post({
      url: `${API_BASE_URL}/api/v1/cart/check-cats-prices`,
      headers: getAuthHeaders(),
      data: JSON.stringify(catsIds),
      onSuccess: (res) => {
        const prices = res.body;
        set((prev) => {
          const pricedCartItems = prev.cart.map((i) => ({
            ...i,
            catPrice: prices.find((c) => c.catId === i.catId).price,
          }));

          //console.log({pricedCartItems});

          return {cart: pricedCartItems};
        });
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        //console.log(message);
        onFailure?.(message);
      },
    });
  },
}));

export default useCart;
