import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RouterWrapper from "./router-wrapper";
import { Layout, RequireAuth } from "components";
import { CustomHelmet } from './Helmet';
import useLangSettings from "stores/langStor";
import { BuyersView, } from "views";
import { AuthVerifyOtpForm, ForgotPasswordForm, } from "modules";
import { IconButton, useColorMode } from "@chakra-ui/react";
import { IconMoon, IconMoon2, IconMoonOff } from "@tabler/icons";
const EmailVerifyOtpForm = React.lazy(() => import('../modules/forms/forms-profile/email-verify-otp-form'));
const CurrentPlan = React.lazy(() => import("views/profile/current-plan"));
const PlanPurCheckoutForm = React.lazy(() => import("modules/forms/forms-plan-purchasing/plan-checkout"));
const CreteNewPassword = React.lazy(() => import("modules/forms/forms-auth/create-new-password"));
const PrivacyPolicy = React.lazy(() => import("views/privacy-policy"));
const TermsAndConditions = React.lazy(() => import("views/terms-conditions"));
const AuthLayout = React.lazy(() => import("views/auth/auth-layout"));
const LoadingScreen = React.lazy(() => import("views/loading-screen"));
const ProfileEmailConfairmationForm = React.lazy(() => import('../modules/forms/forms-profile/form-profile-email-activation'));
const ProfileSupportForm = React.lazy(() => import('../modules/forms/forms-profile/form-profile-support'));
const ProfileSettingsForm = React.lazy(() => import('../modules/forms/forms-profile/form-profile-settings'));
const ProfileSecurityForm = React.lazy(() => import('../modules/forms/forms-profile/form-profile-security'));
const PricingPlans = React.lazy(() => import('../modules/pricing-plans/pricing-plans'));
const PlanPurchasingForm = React.lazy(() => import('../modules/forms/forms-plan-purchasing/plan-purchasing-form'));
const LandingPage = React.lazy(() => import('../views/landing-page'));
const AboutUs = React.lazy(() => import('../views/about-us'));
const FAQsPage = React.lazy(() => import('../views/faqs'));
const BeOurPartner = React.lazy(() => import('../views/be-our-partner'));
const Pricing = React.lazy(() => import('../views/pricing/pricing'));
const AuthView = React.lazy(() => import('../views/auth/auth'));
const CarBrands = React.lazy(() => import('../views/car-brands/car-brands'));
const CarBrandCatalysts = React.lazy(() => import('../views/car-brands/car-brand-catalysts'));
const CarBrandsGrid = React.lazy(() => import('../views/car-brands/car-brands-grid'));
const CatSearchResults = React.lazy(() => import('../views/cat-search-results'));
const CatDetailsView = React.lazy(() => import('../views/cat-details/cat-details'));
const CartView = React.lazy(() => import('../views/cart/cart'));
const ProfileLayout = React.lazy(() => import('../views/profile/profile-layout'));
const ProfileHistory = React.lazy(() => import('../views/profile/profile-history'));
const RefiningComparator = React.lazy(() => import('../views/refining-comparator'));
const AuthLogin = React.lazy(() => import('../views/auth/auth-login'));
const AuthSignup = React.lazy(() => import('../views/auth/auth-signup'));
const FavoritesCollections = React.lazy(() => import('../views/profile/profile-favorites/favorites-collections'));
const FavoritesCollectionItems = React.lazy(() => import('../views/profile/profile-favorites/favorites-collection-items'));

const Router = () => {
  const { languages, } = useLangSettings();
  const { colorMode, toggleColorMode } = useColorMode()

  if (languages?.state === "loading") {
    return <LoadingScreen />
  }

  return (
    <>
      <RouterWrapper>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/home" element={<CustomHelmet name={"Home"} component={<LandingPage />} />} />
              <Route path="/about-us" element={<CustomHelmet name={"AboutUs"} component={<AboutUs />} />} />
              <Route path="/faqs" element={<CustomHelmet name={"FAQsPage"} component={<FAQsPage />} />} />
              <Route path="/privacy-policy" element={<CustomHelmet name={"PrivacyPolicy"} component={<PrivacyPolicy />} />} />
              <Route path="/terms-and-conditions/" element={<CustomHelmet name={"TermsAndConditions"} component={<TermsAndConditions />} />} />
              <Route path="/privacy-policy/:type" element={<CustomHelmet name={"PrivacyPolicy"} component={<PrivacyPolicy />} />} />
              <Route path="/terms-and-conditions/:type" element={<CustomHelmet name={"TermsAndConditions"} component={<TermsAndConditions />} />} />

              <Route
                path="/toll-refining-comparator"
                element={<RequireAuth>
                  <CustomHelmet name={"RefiningComparator"} component={<RefiningComparator />} />
                </RequireAuth>}
              />
              <Route path="/search/:catSlug" element={<CustomHelmet name={"CatSearchResults"} component={<CatSearchResults />} />} />
              <Route path="/catalyst/:catId" element={<CustomHelmet name={"CatDetails"} component={<CatDetailsView />} />} />

              <Route path="/car-brands" element={<CustomHelmet name={"CarBrands"} component={<CarBrands />} />}>
                <Route index element={<CustomHelmet name={"CarBrands"} component={<CarBrandsGrid />} />} />
                <Route path=":brandSlug" element={<CustomHelmet name={"CarBrandCatalysts"} component={<CarBrandCatalysts />} />} />
              </Route>
              <Route path="/be-our-partner" element={<CustomHelmet name={"BeOurPartner"} component={<BeOurPartner />} />} />
              <Route path="/pricing" element={<CustomHelmet name={"Pricing"} component={<Pricing />} />}>
                <Route index element={<CustomHelmet name={"PricingPlans"} component={<PricingPlans />} />} />

              </Route>
              <Route path="/pricing/checkout/:planCode/payment" element={
                <RequireAuth>
                  <CustomHelmet name={"PlanPurchasing"} component={<PlanPurchasingForm />} />
                </RequireAuth>
              } />

              <Route path="/pricing/checkout/:planCode" element={
                <RequireAuth>
                  <CustomHelmet name={"PlanCheckout"} component={<PlanPurCheckoutForm />} />
                </RequireAuth>
              } />

              <Route
                path="/cart"
                element={
                  <RequireAuth>
                    <CustomHelmet name={"Cart"} component={<CartView />} />
                  </RequireAuth>
                }
              />
              <Route path="/buyers" element={<CustomHelmet name={"Buyers"} component={<BuyersView />} />} />

              <Route
                path="/profile"
                element={
                  <RequireAuth>
                    <CustomHelmet name={"Profile"} component={<ProfileLayout />} />
                  </RequireAuth>
                }
              >

                <Route
                  index
                  element={<Navigate to="/profile/profile-settings" />}
                />
                <Route path="profile-settings" element={<CustomHelmet name={"ProfileSettings"} component={<ProfileSettingsForm />} />} />
                <Route path="history" element={<CustomHelmet name={"ProfileHistory"} component={<ProfileHistory />} />} />

                <Route
                  path="email-activation"
                  element={<CustomHelmet name={"ProfileEmailConfairmation"} component={<ProfileEmailConfairmationForm />} />}
                />
                <Route
                  path="email-activation/otp"
                  element={<CustomHelmet name={"EmailVerifyOtp"} component={<EmailVerifyOtpForm />} />}
                />

                <Route path="favorites">
                  <Route index element={<CustomHelmet name={"FavoritesCollections"} component={<FavoritesCollections />} />} />
                  <Route
                    path="collection/:collectionId"
                    element={<CustomHelmet name={"FavoritesCollectionItems"} component={<FavoritesCollectionItems />} />}
                  />
                </Route>
                <Route path="current-plan" element={<CustomHelmet name={"CurrentPlan"} component={<CurrentPlan />} />} />

                <Route path="security-settings" element={<CustomHelmet name={"ProfileSecurity"} component={<ProfileSecurityForm />} />} />
                <Route path="support" element={<CustomHelmet name={"ProfileSupport"} component={<ProfileSupportForm />} />} />
              </Route>


              <Route element={<AuthLayout />}>
                <Route path="/auth" element={<AuthView />}>
                  <Route path="login" element={<CustomHelmet name={"login"} component={<AuthLogin />} />} />
                  <Route path="signup" element={<CustomHelmet name={"signup"} component={<AuthSignup />} />} />
                  <Route path="forgot-password" element={<CustomHelmet name={"ForgotPassword"} component={<ForgotPasswordForm />} />} />
                  <Route path="verify-otp/:forgetPass" element={<CustomHelmet name={"AuthVerifyOtp"} component={<AuthVerifyOtpForm />} />} />
                  <Route path="changePassword" element={<CustomHelmet name={"changePassword"} component={<CreteNewPassword />} />} />
                  <Route path="verify-otp" element={<CustomHelmet name={"AuthVerifyOtp"} component={<AuthVerifyOtpForm />} />} />
                </Route>
              </Route>


            </Route>
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </Suspense>
      </RouterWrapper>

      <IconButton
        bottom={{base:'65px' , md:5}}
        right={4}
        isRound={true}
        variant='solid'
        aria-label='dark mode'
        fontSize='20px'
        onClick={toggleColorMode}
        style={{backgroundColor:"#fff" , borderWidth:1 , position:"fixed" , zIndex:1000 , }}
        icon={colorMode === 'light' ? <IconMoon  color="#aaa" />:<IconMoonOff  color="#aaa" />}
      />
    </>
  );
};

export default Router;
