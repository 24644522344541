import {API_BASE_URL} from "constants";
import {apiService} from "services";
import {getAuthHeaders} from "utils";
import {create} from "zustand";

const useFavorites = create((set) => ({
  favoritesCollections: {
    state: "loading",
    data: [],
  },

  getAllFavoriteCollections: ({onSuccess, onFailure}) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/favorites/collections/get-collections`,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        //console.log(res);
        set({favoritesCollections: {state: "loaded", data: res.body}});
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
       // console.log(message);
        set({favoritesCollections: {state: "erred", data: [], message}});
        onFailure?.(message);
      },
    });
  },

  getFavoritesCollectionItems: ({collectionId = 36, onSuccess, onFailure}) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/favorites/collections/get-favorites-details`,
      headers: getAuthHeaders(),
      data: [{key: "collectionId", value: collectionId}],
      onSuccess: (res) => {
        //console.log(res);
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        //console.log(message);
        onFailure?.(message);
      },
    });
  },

  createFavoritesCollection: ({collectionName, onSuccess, onFailure}) => {
    const data = JSON.stringify({collectionName});

    apiService.post({
      url: `${API_BASE_URL}/api/v1/favorites/collections/save`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        set((state) => ({
          favoritesCollections: {
            ...state,
            data: [...state.favoritesCollections.data, res.body],
          },
        }));
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        // console.log(message);

        onFailure?.(message);
      },
    });
  },

  addItemToFavorites: ({
    collectionId = 36,
    catId = 16314,
    onSuccess,
    onFailure,
  }) => {
    const data = JSON.stringify({collectionId, catId});

    apiService.post({
      url: `${API_BASE_URL}/api/v1/favorites/save`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        // console.log(res);

        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        // console.log(message);

        onFailure?.(message);
      },
    });
  },

  removeItemFromFavorites: ({catId, onSuccess, onFailure}) => {
    const data = JSON.stringify({catId});

    apiService.post({
      url: `${API_BASE_URL}/api/v1/favorites/delete`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        // console.log(res);

        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        // console.log(message);

        onFailure?.(message);
      },
    });
  },

  deleteFavoritesCollection: ({collectionId, onSuccess, onFailure}) => {
    const data = JSON.stringify({collectionId});

    apiService.post({
      url: `${API_BASE_URL}/api/v1/favorites/delete-collection`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        set((state) => ({
          favoritesCollections: {
            state: "loaded",
            data: state.favoritesCollections.data.filter(
              (c) => c.collectionId !== collectionId
            ),
          },
        }));

        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        // console.log(message);

        onFailure?.(message);
      },
    });
  },
}));

export default useFavorites;
