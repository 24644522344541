import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),

  phoneNumber: yup.string().required("Phone number is required"),
});

export const INITIAL_STATE = {
  phoneNumber: "",
  password: "",
  dialCode: localStorage?.getItem('defaultCountry') ? localStorage?.getItem('defaultCountry').toLowerCase() :"za",
};


export const forgetPasswordValidationSchema = yup.object().shape({
  phoneNumber: yup.string().required("Phone number is required"),
  dialCode: yup.string().required()
});
