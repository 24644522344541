import { Box, Button, Container, Flex, Grid, GridItem, Heading, useColorMode } from "@chakra-ui/react";
import { Catalyst, CatSearchBox, Loader, SassyAlert } from "components";
import responsiveStyle from "constants/responsiveStyle";
// import { DEFAULT_SEARCH_CATALOG_LIMIT } from "constants";
import useApiEffect from "hooks/use-api-effect";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth, useCatalysts } from "stores";
import { deSluggify } from "utils";

const CatSearchResults = () => {
  const { colorMode } = useColorMode()
  const isDarkMode= colorMode === "dark"

  const { catSlug } = useParams();
  const { searchCatalysts } = useCatalysts();
  const { user } = useAuth()
  const currency = user?.currency

  const [loadingData, setLoadingData] = useState(false)
  const [{ state, data, errorMessage, last, offSet, limit }, setSearchResults] = useState({
    state: "loading",
    data: [],
    errorMessage: "",
    offSet: 0,
    last: false,
    limit: 6
  });



  const getSeacrchResult = async ({ data, offSet , state }) => {
    if (state !== "erred") {
      setSearchResults((prev) => ({ ...prev, state: state }));
    }
    searchCatalysts({
      search: catSlug,
      limit: limit.toString(),
      offSet: offSet.toString(),
      onSuccess: (res) => {
        setSearchResults({
          state: "loaded",
          data: res.body?.content ? [...data, ...res.body?.content] : [],
          errorMessage: "",
          last: res.body?.last,
          offSet: +res.body?.number + 1,
          limit: 6
        });
        setLoadingData(false)
      },
      onFailure: ({ errorMessage }) => {
        setSearchResults({
          state: "erred",
          data: [],
          errorMessage,
        });
      },
    });
  }


  useApiEffect(() => {
    setLoadingData(true)
    getSeacrchResult({
      data: [],
      offSet: 0,
      state:"loaded"
    })
    

  }, [currency, catSlug]);




  return (
    <main className="page">
      <Box  as="section" style={{backgroundImage: isDarkMode && "none" , backgroundColor:isDarkMode && "transparent"}}  height={responsiveStyle?.page_header_section_height}  className="hero search-hero">
        <Container height="100%">
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Box width={{ base: "90%", md: "70%" }}>
              <CatSearchBox value={catSlug} />
            </Box>
          </Flex>
        </Container>
      </Box>
      <Box as="section" py={responsiveStyle?.section_py}>
        <Container padding={0} >
          <Box padding={0}>
            <Heading variant="section" as="h3" display={{base:"none" , md:"block"}}>
              Showing all Catalytic Converters For{" "}
              <Box as="span" color="primary" textTransform="uppercase">
                {deSluggify(catSlug)}
              </Box>
            </Heading>
            <Box mt={{base:0 , md:16}}>
              {/* {loadingData && <Loader />} */}
              {state === "erred" ? (
                <SassyAlert title={errorMessage} status="error" />
              )
                 : loadingData ? (
                  <Loader />
                ) 
                : (data.length === 0 && state !== "loading") ? (
                  <SassyAlert status="info" title="No items found" />
                ) : (
                  <>
                    <Grid
                      templateColumns={{
                        base: "1fr",
                        sm: "repeat(2,1fr)",
                        lg: "repeat(3,1fr)",
                      }}
                      gap="12"
                      mt="16"
                    >
                      {data.map((cat, i) => (
                        <GridItem
                          key={i}
                          height="100%"
                          width="100%"
                          maxWidth={{ base: "300px", md: "100%" }}
                          mx="auto"
                        >
                          <Catalyst cat={cat} />
                        </GridItem>
                      ))}

                    </Grid>
                    {state === "loading" && <Loader />}

                    {!last && (state !== "loading") && <Box display="flex" justifyContent={{base:"center" , md:"flex-end"}}>
                      <Button 
                      aria-label=" Load  More Data"
                      onClick={()=>{
                        getSeacrchResult({
                          data: data,
                          offSet:offSet.toString(),
                          state:"loading"
                        })
                      }} mt="16" alignSelf="flex-end" colorScheme='teal' variant='outline'>
                        Load  More
                      </Button>
                    </Box>}
                  </>
                )}




            </Box>
          </Box>
        </Container>
      </Box>
    </main>
  );
};

export default CatSearchResults;
