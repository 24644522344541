import {
  Box,
  Container,
  Heading,
  Highlight,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

const Buyers = () => {
  return (
    <main className="page">
      <Box as="section" className="hero buyers-hero" />

      <Box as="section" py="24">
        <Container textAlign="center">
          <Heading variant="section" as="h2">
            <Highlight
              query="Buyers"
              styles={{color: "primary", whiteSpace: "wrap"}}
            >
              {t('Buyers List')}
            </Highlight>
          </Heading>
          <Text
            maxWidth={{base: "90%", md: "80%"}}
            mx="auto"
            color="dark"
            mt="6"
          >
            {t("No buyers in your area")}
          </Text>
        </Container>
      </Box>
      {/* <Box as="section" py="16">
        <Container>
          <Grid
            gap="8"
            templateColumns={{
              base: "1fr",
              md: "1fr 1fr",
              xl: "repeat(auto-fit, minmax(400px,6fr))",
            }}
          >
            {buyers.map((b) => (
              <GridItem key={b.id}>
                <Buyer {...b} />
              </GridItem>
            ))}
          </Grid>
        </Container>
      </Box> */}
    </main>
  );
};

export default Buyers;
