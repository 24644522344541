import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import RightArrow from "assets/images/arrow-right.webp";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import responsiveStyle from "constants/responsiveStyle.js";

const FooterCTA = () => {
  const {t} = useTranslation()
  const { colorMode } = useColorMode()
    const isDarkMode = colorMode === "dark"
  return (
    <Box bgColor={!isDarkMode && "secondary"} py={responsiveStyle?.section_py}>
      <Container>
        <Grid
          gap={4}
          textAlign={{base: "center", md: "left"}}
          justifyContent={{base: "center", md: "space-between"}}
          alignItems="center"
          templateColumns={{
            xs: "1fr",
            md: "repeat(2,1fr)",
            lg: "2fr 1fr auto",
          }}
        >
          <GridItem >
            <Heading color="primary" variant="section">
              {t("Need Any Help?")}
            </Heading>
            <Text color={isDarkMode?"white":"dark"} mt="6" mx={{base: "auto"}}>
              {t("Feel free to Ping us anytime in case you need any help regarding any topic or have query about us.")}
            </Text>
          </GridItem>
          <GridItem
            display={{base: "none", lg: "inherit"}}
            justifySelf="center"
          >
            <Image alt='Right Arrow' src={RightArrow} width="40" />
          </GridItem>
          <GridItem
            alignSelf="center"
            justifySelf={{md: "end"}}
            mt={{base: "8", md: "0"}}
          >
            <Link aria-label={t("Contact Us")} to="/profile/support">
            <Button  color={"#fff"} aria-label={t("Contact Us")}>{t("Contact Us")}</Button>
            </Link>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default FooterCTA;
