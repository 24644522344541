import {
  Avatar,
  AvatarBadge,
  HStack,
  IconButton,
  Input,
  useToast,
} from "@chakra-ui/react";
import {IconCircleCheck, IconCircleX, IconPencil} from "@tabler/icons";
import React, {useRef, useState} from "react";
import {useAuth, useProfile} from "stores";
import {getToastData} from "utils";

const ProfileSidebarImage = ({user, src, name}) => {
  const inputRef = useRef();
  const toast = useToast();
  const [{state, previewImg}, setViewState] = useState({
    state: "displaying",
    previewImg: null,
  });
  const {updataUser } = useAuth();

  const {updateUserProfile} = useProfile();

  const handleInput = (e) => {
    setViewState({
      state: e.target.files?.[0] ? "previewing" : "displaying",
      previewImg: e.target.files?.[0] || "",
    });
  };

  const handleCancel = () =>
    setViewState({
      state: "displaying",
      previewImg: null,
    });

  const handleConfirm = () => {
    setViewState((prev) => ({state: "loading", ...prev}));

   // console.log(state);

    updateUserProfile({
      data: {
        image: previewImg,
        countryCode: user.countryCode,
        fullName: user.fullNameEn,
        country: user.countryEn,
        defCurrency: user.defCurrency,
        email: user.username
      },
      onSuccess: (res) => {
        setViewState((prev) => ({...prev, state: "updated"}));
        updataUser({ user: res?.body })
        toast(getToastData({ status: "success", title: "profile image Updated successfully" }));

      },
      onFailure: (errorMessage) => {
        toast(
          getToastData({
            status: "error",
            title: errorMessage,
          })
        );
        setViewState({previewImg: null, state: "displaying"});
      },
    });
  };

  return (
    <>
      <Avatar
        src={state === "displaying" ? src : URL.createObjectURL(previewImg)}
        name={name}
        bg="primary"
        color="white"
        size="xl"
      >
        <AvatarBadge bgColor="transparent" boxSize="1em">
          <IconButton
            aria-label="Edit Profile Picture"
            borderRadius="full"
            bgColor="gray.50"
            color="dark"
            cursor="pointer"
            icon={<IconPencil fontSize="18px" />}
            _hover={{bgColor: "secondarydark"}}
            onClick={() => inputRef.current.click()}
          />

          <Input
            type="file"
            accept="image/*"
            hidden
            ref={inputRef}
            onChange={handleInput}
          />
        </AvatarBadge>
      </Avatar>
      {state === "previewing" && (
        <HStack mt="4" spacing="4">
          <IconButton
            variant="outline"
            onClick={handleCancel}
            isLoading={state === "loading"}
          >
            <IconCircleX />
          </IconButton>
          <IconButton
            colorScheme="green"
            onClick={handleConfirm}
            isLoading={state === "loading"}
          >
            <IconCircleCheck />
          </IconButton>
        </HStack>
      )}
    </>
  );
};

export default ProfileSidebarImage;
