import {
  Avatar,
  Badge,
  Box,
  Button,
  Circle,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Logo from "assets/images/logo.webp";
import LogoDark from "assets/images/logo-dark.webp";
import {
  NavLink,
  Link as ReactRouterLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { IconCar, IconHeart, IconLogin, IconLogout, IconMenu2, IconShoppingCart, IconSmartHome, IconUser, IconWallet } from "@tabler/icons";
import { useAuth, useCart, } from "stores";
import currencies from "../constants/currencies.ts";
import { useTranslation } from "react-i18next";
import useLangSettings from "./../stores/langStor";
import useApiEffect from "hooks/use-api-effect";
import AuthModal from "./auth-modal";

const AppNavbar = ({ className, variant = "light", ...props }) => {
  const { user, logoutUser, changeCurrancy } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  const { languages, getActiveLanguages, getLanguagesTranslate } = useLangSettings();
  const lang = localStorage.getItem('lang')
  const location = useLocation();
  const { cart } = useCart();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  // const p = useRoutes()
  const titleName = location?.pathname.replaceAll("/", "").replaceAll("-", " ")
  const title = titleName.charAt(0).toUpperCase() + titleName.slice(1);
  // console?.log(languages?.data, "lang");
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const btnRef = React.useRef();

  useApiEffect(() => {
    getActiveLanguages({
      onSuccess: () => { },
      onFailure: () => { },
    });
  }, []);



  React.useLayoutEffect(() => {
    onDrawerClose()
  }, [location]);

  const getNavLinkClassName = ({ isActive }) =>
    `${isActive ? "text-primary" : "text-inherit"
    } hover:text-primary hover:duration-100 hover:ease-in tracking-wide hover:scale-105 text-center`;




  // return <></>
  return (
    <>
      {/* ============DESKTOP============= */}
      <Box as="header" display={{ base: "none", md: "block" }} bgColor="transparent" className={`w-full ${className}`}>
        <Box
          as="div"
          mx="auto"
          maxWidth={{
            base: "98%",
            sm: "90vw",
            xxl: "1320px",
          }}
        >
          {/* :DESKTOP MENU */}
          <Flex
            justify={{ base: "space-between" }}
            align="center"
            py="7"
            px="5"
            gap={4}
            direction={{ base: "row", sm: "row" }}
            display={{
              base: "none",
              md: "flex"
            }}
          >
            {/* ::Site logo and Name */}
            <Link aria-label={"home"} as={ReactRouterLink} to="/home">
              <Image
                alt="Catalytic Price Logo"
                src={variant === "dark" ? LogoDark : Logo}
                width={{ base: "150px", sm: "180px" }}
                height={{ base: "100%", sm: "100%" }}
              />
            </Link>
            {/* ::Navbar */}
            <Flex
              justify="center"
              align="center"
              grow={1}
              gap={"3%"}
              color={variant === "light" ? "white" : "black"}
              display={{
                base: "none",
                xl: "flex",
              }}
            >
              <NavLink to="/home" className={getNavLinkClassName}>
                {t("Home")}
              </NavLink>

              <NavLink to="/pricing" className={getNavLinkClassName}>
                {t("Subscriptions")}
              </NavLink>

              <NavLink to="/car-brands" className={getNavLinkClassName}>
                {t("Car Brands")}
              </NavLink>

              {/* <NavLink to="/be-our-partner" className={getNavLinkClassName}>
              {t('Be Our Partner')}
            </NavLink> */}


              <NavLink
                to="/toll-refining-comparator"
                className={getNavLinkClassName}
              >
                {t("Refining Calculator")}
              </NavLink>
              <NavLink to="/about-us" className={getNavLinkClassName}>
                {t("About Us")}
              </NavLink>
              <NavLink to="/faqs" className={getNavLinkClassName}>
                {t("FAQ's")}
              </NavLink>



              <Select
                width="fit-content"
                size="lg"
                onChange={(e) => {
                  // console.log(e.target.value);
                  changeCurrancy(e.target.value);
                  localStorage?.setItem("defCurrency", e.target.value);
                  window?.location.reload()
                }}
                fontSize="16"
                _placeholder={{ color: "gray" }}
                style={{ border: 0 }}
                outline="0px"
                color={variant === "dark" ? "dark" : "white"}
                defaultValue={`${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}`}
                variant="unstyled"
                icon={<></>}
              >
                {currencies.map((currency, i) => (
                  <>
                    <option
                      style={{ color: "gray" }}
                      selected={currency.currency[0] === `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}`}
                      key={i}
                      value={currency.currency[0]}
                    >
                      {currency.currency[0]}
                    </option>
                  </>
                ))}
                {/* {Object.keys(currencies).map((currency, i) => (
                  <option
                    style={{ color: "gray" }}
                    selected={currency === `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}`}
                    key={i}
                    value={currency}
                  >
                    {currency}
                  </option>
                ))} */}
              </Select>

              <Select
                width="fit-content"
                size="sm"
                onChange={(e) => {
                  const value = e.target?.value;
                  getLanguagesTranslate({ lang: JSON?.parse(value) })
                  localStorage.setItem('lang', value)
                }}
                fontSize="16"
                _placeholder={{ color: "gray" }}
                style={{ border: 0 }}
                outline="0px"
                color={variant === "dark" ? "dark" : "white"}
                variant="unstyled"
                icon={<></>}
              >
                {languages?.data?.map((item) => {
                  // console?.log(item?.["langCode"]);
                  return (
                    <option
                      key={item?.langId}
                      selected={lang !== null ? (item?.langId === JSON?.parse(lang)?.langId || item?.langId === JSON?.parse(lang)?.lang_id) : item?.isDefault || item?.isـdefault}
                      style={{ color: "gray" }}
                      value={JSON?.stringify(item)}
                      data-name={item}
                    >
                      {item?.["langName"]}
                    </option>
                  );
                })}
              </Select>


            </Flex>


            <HStack spacing={{ base: 4, sm: 8 }}>
              {user.state === "loaded" && user.isLoggedIn ? (
                <Flex
                  gap={4}
                  align="center"
                  color={variant === "light" ? "white" : "black"}
                >
                  <Text
                    style={{ maxWidth: "60px" }}
                    className="overflow-hidden text-ellipsis	whitespace-nowrap	"
                  >
                    {user.data.fullNameEn}
                  </Text>
                  <Menu>
                    <MenuButton>
                      <Avatar
                        borderRadius="full"
                        width="30px"
                        height="30px"
                        src={user.data.profilePicture}
                      />
                    </MenuButton>
                    <MenuList color={isDarkMode ? "white" : "dark"}>
                      <MenuItem
                        _hover={{ bgColor: "secondary", color: "primary" }}
                      >
                        <NavLink style={{ flex: 1, }} to="/profile" className={getNavLinkClassName}>
                          {t("Profile")}
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        _hover={{ bgColor: "secondary", color: "primary" }}
                        as="button"
                        onClick={() => {
                          logoutUser();
                          navigate("/home");
                          // resetFavData()
                        }}
                      >
                        {t("Logout")}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <ReactRouterLink to="/cart">
                    <Icon
                      as={() => (
                        <Box position="relative" cursor="pointer">
                          <Circle
                            position="absolute"
                            top="-10px"
                            right="-10px"
                            width="20px"
                            height="20px"
                            bgColor={"secondarydark"}
                          >
                            <Badge
                              fontSize="sm"
                              borderRadius="full"
                              bgColor={"secondarydark"}
                              color="primary"
                            >
                              {cart.length}
                            </Badge>
                          </Circle>
                          <IconShoppingCart />
                        </Box>
                      )}
                      color={variant === "light" ? "white" : "black"}
                      fontSize="24px"
                    />
                  </ReactRouterLink>
                </Flex>
              ) : (
                <ReactRouterLink to="auth/login">
                  <Button
                    color={"white"}
                    aria-label={t("Login/SignUp")}
                    size={{
                      base: 'xs',
                      md: "lg"
                    }} onClick={() => { }}>{t("Login/SignUp")}</Button>
                </ReactRouterLink>
              )}

              <button
                aria-label="menu"
                className={`xl:hidden cursor-pointer ${variant === "light" ? "text-white" : "text-black"
                  } active:outline-none rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary`}
                onClick={onDrawerOpen}
                ref={btnRef}
              >
                <IconMenu2 size={33} />
              </button>
            </HStack>
          </Flex>
        </Box>
      </Box>


      {/* ============mobile============= */}
      <Box as="header" bgColor="transparent" display={{ base: "block", md: "none" }}>
        <Box
          as="div"
          mx="auto"
          maxWidth={{
            base: "98%",
            sm: "90vw",
            xxl: "1320px",
          }}
        >
          {/* ::MOBILE MENU */}
          <Drawer
            isOpen={isDrawerOpen}
            placement="left"
            onClose={onDrawerClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>{t("Navigation")}</DrawerHeader>
              <Divider />
              <DrawerBody>
                <VStack spacing={8} mt={4} color={!isDarkMode ? "black" : "white"} alignItems="start">
                  <NavLink to="/home" className={getNavLinkClassName}>
                    {t("Home")}
                  </NavLink>

                  <NavLink to="/car-brands" className={getNavLinkClassName}>
                    {t("Car Brands")}
                  </NavLink>
                  <NavLink to="/be-our-partner" className={getNavLinkClassName}>
                    {t("Be Our Partner")}
                  </NavLink>
                  <NavLink to="/pricing" className={getNavLinkClassName}>
                    {t("Subscriptions")}
                  </NavLink>
                  <NavLink to="/faqs" className={getNavLinkClassName}>
                    {t("FAQ's")}
                  </NavLink>
                  <NavLink
                    to="/toll-refining-comparator"
                    className={getNavLinkClassName}
                  >
                    {t("Refining Calculator")}
                  </NavLink>
                  <NavLink to="/about-us" className={getNavLinkClassName}>
                    {t("About Us")}
                  </NavLink>

                  <Select
                    //width={'90px'}
                    size="lg"
                    onChange={(e) => {
                      // console.log(e.target.value);
                      changeCurrancy(e.target.value);
                      localStorage?.setItem("defCurrency", e.target.value);
                      window?.location.reload()
                    }}
                    fontSize="16"
                    _placeholder={{ color: "gray" }}
                    style={{ border: 0 }}
                    outline="0px"
                    color={variant === "dark"}
                    defaultValue={`${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}`}
                  >
                    {currencies.map((currency, i) => (
                      <option
                        style={{ color: "gray" }}
                        selected={currency.currency[0] === `${localStorage.getItem("defCurrency") || localStorage.getItem("currency")}`}
                        key={i}
                        value={currency.currency[0]}
                      >
                        {currency.currency[0]} 
                      </option>
                    ))}
                  </Select>
                  <Select
                    width="fit-content"
                    size="sm"
                    onChange={(e) => {
                      const value = e.target?.value;
                      getLanguagesTranslate({ lang: JSON?.parse(value) })
                      localStorage.setItem('lang', value)
                    }}
                    fontSize="16"
                    _placeholder={{ color: "gray" }}
                    style={{ border: 0 }}
                    outline="0px"
                    color={variant === "dark"}
                    variant="unstyled"
                    icon={<></>}
                  >
                    {languages?.data?.map((item) => {
                      // console?.log(item?.["langCode"]);
                      return (
                        <option
                          key={item?.langId}
                          selected={lang !== null ? item?.langId === JSON?.parse(lang)?.langId : item?.is_default}
                          style={{ color: "gray" }}
                          value={JSON?.stringify(item)}
                          data-name={item}
                        >
                          {item?.["langName"]}
                        </option>
                      );
                    })}
                  </Select>

                  {user.state === "loaded" && user.isLoggedIn && (<>
                    <Link
                      style={{ display: "flex", flex: 1 }}
                      _hover={{ bgColor: "secondary", color: "primary" }}
                      as="button"
                      onClick={() => {
                        logoutUser();
                        navigate("/home");
                        // resetFavData()
                      }}
                    >
                      <IconLogout />
                      {t("Logout")}
                    </Link>
                  </>)}

                  {!user.isLoggedIn && (<>
                    <Link
                      style={{ display: "flex", flex: 1, width: '100%', padding: "5px 0px", }}
                      _hover={{ bgColor: "secondary", color: "primary" }}
                      as="button"
                      onClick={() => {
                        // logoutUser();
                        navigate("/auth/login");
                        // resetFavData()
                      }}
                    >
                      <IconLogin />
                      {t("Log in")}
                    </Link>
                  </>)}
                </VStack>
              </DrawerBody>

              {/* <DrawerFooter>
      <Button variant="outline" mr={3} onClick={onDrawerClose}>
        Cancel
      </Button>
      <Button colorScheme="blue">Save</Button>
    </DrawerFooter> */}
            </DrawerContent>
          </Drawer>
          {/* */}

          {/* mobile header */}
          <Flex
            className="nav-header"
            justify={{ base: "space-between" }}
            align="center"
            gap={4}
            direction={{ base: "row", sm: "row" }}
            display={{ base: "flex", md: 'none' }}
            bgColor={isDarkMode ? "darkModeBg" : "white"}
          >
            <HStack spacing={{ base: 4, sm: 8 }}>
              <button
                aria-label="menu"
                className={`xl:hidden cursor-pointer  text-primary
         active:outline-none rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary`}
                onClick={onDrawerOpen}
                ref={btnRef}
              >
                <IconMenu2 size={25} />
              </button>
            </HStack>
            <Text className="text-primary">{title}</Text>

            <HStack spacing={{ base: 4, sm: 8 }}>
              {user.state === "loaded" && user.isLoggedIn && (
                <ReactRouterLink to="/cart">
                  <Icon
                    as={() => (
                      <Box marginRight={3} position="relative" cursor="pointer">
                        <Circle
                          position="absolute"
                          top="-10px"
                          right="-10px"
                          width="20px"
                          height="20px"
                          bgColor={"secondarydark"}
                        >
                          <Badge
                            fontSize="sm"
                            borderRadius="full"
                            bgColor={"secondarydark"}
                            color="primary"
                          >
                            {cart.length}
                          </Badge>
                        </Circle>
                        <IconShoppingCart />
                      </Box>
                    )}
                    color={"black"}
                    fontSize="24px"
                  />
                </ReactRouterLink>
              )}
            </HStack>



            {/* <IconShoppingCart className="text-primary" /> */}
          </Flex>

          {/*Mobile Tab bar */}
          <Flex
            justify="space-around"
            align="center"
            grow={1}
            gap={"3%"}
            color={'black'}
            className="bottom-tab"
            display={{ base: "flex", md: 'none' }}
            bgColor={isDarkMode ? "darkModeBg" : "white"}
          >
            <Link onClick={() => {
              navigate("/home")

            }} color={isDarkMode ? "white" : "dark"} to="/home" className={getNavLinkClassName}>
              <IconSmartHome className={getNavLinkClassName} />
              {t("Home")}
            </Link>

            {/* {console.log(user, "user login")} */}
            <Link color={isDarkMode ? "white" : "dark"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }} justifyContent={"center"} as="button" onClick={() => {
              user.isLoggedIn === true ? navigate("/profile/favorites") : setIsLoginModalOpen(true)
            }} className={getNavLinkClassName}>
              <IconHeart className={getNavLinkClassName} />
              {t("Favourites")}
            </Link>

            <Link onClick={() => { navigate("/car-brands") }} color={isDarkMode ? "white" : "dark"} to="/car-brands" className={getNavLinkClassName}>
              <IconCar />
              {t("Brands")}
            </Link>



            <Link onClick={() => { navigate("/pricing") }} color={isDarkMode ? "white" : "dark"} to="/pricing" className={getNavLinkClassName}>
              <IconWallet />
              {t("Cridite")}
            </Link>

            <Link color={isDarkMode ? "white" : "dark"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              as="button" to="/profile" className={getNavLinkClassName}
              onClick={() => {
                user.isLoggedIn === true ? navigate("/profile") : setIsLoginModalOpen(true)

              }}
            >
              <IconUser />
              {t("profile")}
            </Link>
          </Flex>


        </Box>
        <AuthModal isOpen={isLoginModalOpen} onClose={() => { setIsLoginModalOpen(false) }} />
      </Box>


    </>
  );
};

export default AppNavbar;
