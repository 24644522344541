import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IconMail } from "@tabler/icons";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useProfile } from "stores";
import { getToastData } from "utils";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';

const ProfileEmailConfairmationForm = () => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  const {t} = useTranslation()
  const { sendEmailActivation } = useProfile();
  const {user:{data:{username}}} = useAuth()
  const toast = useToast();
  const navigate = useNavigate();

  const [  { state,}, setViewState,] = useState({state: "",});
  const INITIAL_VALUES = {
    email: username,
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email(t("Email not valid")).required(t("Email is required")),
  });

  const onSubmit = (values) => {
    if(username)return;
    toast(getToastData({ status: "loading", title: "Send OTP ..." }));
    sendEmailActivation({
      email: values.email,
      onSuccess: (res) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "success",
            title: t("OTP was sent successfully"),
          })
        );
        setTimeout(() => {
            navigate("/profile/email-activation/otp")
        }, 1500);
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "error",
            title:
              errorMessage || t("An error has occurred while Send OTP"),
          })
        );
      },
    });
  };

  return (
    <Box pt="12">
      <Formik
        initialValues={INITIAL_VALUES}
        enableReinitialize
        {...{ validationSchema, onSubmit }}
      >
        {/* {(props) => ( */}
        <Form>
          <VStack spacing="6" align="stretch">
            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t("Email")}
                    </FormLabel>
                  )}

                  <InputGroup>
                    <Input 
                    bgColor={isDarkMode ? "darkModeBg" : "white"}
                    color={isDarkMode ? "white" : "dark"}
                    disabled={username} {...field} type={"text"} placeholder="email" />

                    <InputRightElement
                      color="dark"
                      onClick={() => {}}
                      children={<IconMail size="18" color={isDarkMode ? "white" : "dark"}/>}
                    />
                  </InputGroup>

                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex justify="end" mt="6">
              <HStack spacing="5">
                <Button color="white" aria-label="Active your Email" opacity={username?0.4:1} cursor={username?"auto":"pointer"}  type="submit" isLoading={state === "loading"}>
                  {username? t("Email Already Activated") : t("Send OTP")}
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </Form>
        {/* )} */}
      </Formik>
    </Box>
  );
};

export default ProfileEmailConfairmationForm;
