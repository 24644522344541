import {Box,  Container, } from "@chakra-ui/react";
import {CarBrand, Loader} from "components";
import CarouselNextButton from "components/carousel-next-button";
import CarouselPrevButton from "components/carousel-prev-button";
import useApiEffect from "hooks/use-api-effect";
import React, {useState} from "react";
import {Carousel} from "react-responsive-carousel";
import {useCatalysts} from "stores";

const PopularBrandsCarousel = ({header, py = "14"}) => {
  const {allBrands, getAllBrands} = useCatalysts();

  // state for managing images slider
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = (index) => setCurrentSlide(index);

  useApiEffect(() => {
    if (allBrands.state === "loading") getAllBrands({});
  }, []);

  return allBrands.state === "loading" ? (
    <Loader my="4" />
  ) : (
    allBrands.state === "loaded" && (
      <Box as="section" py={py}>
        <Container>
          {header && header}
          <Box height="200px" mt="8">
            <Carousel
              swipeable
              showArrows={true}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
              onChange={changeSlide}
              selectedItem={currentSlide}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && <CarouselPrevButton {...{onClickHandler, label}} />
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && <CarouselNextButton {...{onClickHandler, label}} />
              }
            >
              {allBrands.data.map((b) => (
                <CarBrand
                  brand={b}
                  showTitle={false}
                  boxSize="200px"
                  mx="4"
                  border="1px solid"
                  borderColor="primary"
                  key={b.makerId}
                />
              ))}
            </Carousel>
          </Box>
        </Container>
      </Box>
    )
  );
};

export default PopularBrandsCarousel;
