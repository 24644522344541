import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Link,
  IconButton,
  VStack,
  HStack,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {IconTrash} from "@tabler/icons";
import {Card} from "components";
import NewFavoritesCollectionModal from "components/new-favorites-collection-modal";
import useApiEffect from "hooks/use-api-effect";
import {DeleteFavoritesCollectionAlert} from "modules";
import React, {useState} from "react";
import {Link as ReactRouterLink} from "react-router-dom";
import {useFavorites} from "stores";
import { useTranslation } from 'react-i18next';

const FavoritesCollections = () => {
  const {favoritesCollections, getAllFavoriteCollections} = useFavorites();
  const {t} = useTranslation()
  const [toBeDeletedId, setToBeDeletedId] = useState();

  const {
    isOpen: isNewModalOpen,
    onOpen: onNewModalOpen,
    onClose: onNewModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteAlertOpen,
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
  } = useDisclosure();

  useApiEffect(() => {
    getAllFavoriteCollections({});
  }, []);

  return (
    <Box>
      <HStack justify="space-between">
        <Heading as="h2" size="md" color="primary">
          {t("Collections")}
        </Heading>
        <Button aria-label={t("Create New Collection")} onClick={onNewModalOpen}>{t("Create New Collection")}</Button>
      </HStack>
      <Grid
        templateColumns={"repeat(auto-fit, minmax(200px, 1fr))"}
        gap="8"
        mt="8"
      >
        {favoritesCollections.data.map((c) => (
          <GridItem key={c.collectionId}>
            <Card p="4">
              <Center height="full">
                <VStack spacing="8">
                  <Link
                    aria-label={t("Favourits collection")}
                    as={ReactRouterLink}
                    to={`/profile/favorites/collection/${c.collectionId}`}
                  >
                    <Heading as="h4" fontSize="md" color="gray">
                      {c.collectionName}
                    </Heading>
                  </Link>
                  <IconButton
                    bgColor="#FFF5F6"
                    color="red"
                    size="md"
                    borderRadius="full"
                    _hover={{
                      bgColor: "red",
                      color: "white",
                    }}
                    onClick={() => {
                      setToBeDeletedId(c.collectionId);
                      onDeleteAlertOpen();
                    }}
                  >
                    <IconTrash size="20" />
                  </IconButton>
                </VStack>
              </Center>
            </Card>
          </GridItem>
        ))}
      </Grid>
      <NewFavoritesCollectionModal
        {...{isOpen: isNewModalOpen, onClose: onNewModalClose}}
      />
      <DeleteFavoritesCollectionAlert
        {...{
          isOpen: isDeleteAlertOpen,
          onClose: onDeleteAlertClose,
          collectionId: toBeDeletedId,
        }}
      />
    </Box>
  );
};

export default FavoritesCollections;
