import {
  Box,
  Button,
  Heading,
  Highlight,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";

import AuthenticationScreen from "assets/images/authentication-screen.webp";
import { useNavigate,useParams } from "react-router-dom";
import useApiEffect from "hooks/use-api-effect";
import {useAuth} from "stores";
import {getToastData} from "utils";
import { useTranslation } from 'react-i18next';

const AuthVerifyOtpForm = () => {
  const {t} = useTranslation()
  const {otpData, sendOtp, verifyOtp , sendForgetPassOtp , verifyForgetPasswordOtp} = useAuth();
  const toast = useToast();
  const navigate = useNavigate()
  const forgetPass = useParams()?.forgetPass === "forgetPass"  ;

  const [enteredOtp, setEnteredOtp] = useState();
  const otpSentRef = useRef(false);


  const handleOtpVerify = () => {
    verifyOtp({
      otpCode: enteredOtp,
      activationToken: otpData.data.activationToken,
      onSuccess: (res) => {
        toast(
          getToastData({
            title: t("Your phone number has been verified."),
            status: "success",
          })
        );
        setTimeout(() => {
          navigate("/auth/login");
        }, 400);
      },
      onFailure: (errorMessage) => {
        toast(
          getToastData({
            title: errorMessage,
            status: "error",
          })
        );
      },
    });
  };



  const sendActivationCode = async()=>{
    if (otpSentRef.current === false) {
      sendOtp({

        onSuccess: (res) => {
          otpSentRef.current = true;
          toast(
            getToastData({
              title: t("OTP sent successfully"),
              status: "success",
            })
          );
        },
        onFailure: (errorMessage) => {
          otpSentRef.current = true;
          toast(
            getToastData({
              title: errorMessage,
              status: "error",
            })
          );
        },
      });
    }
  }



  const sendForgetPasswordCode = async()=>{
    if (otpSentRef.current === false) {
      sendForgetPassOtp({
        onSuccess: (res) => {
          otpSentRef.current = true;
          toast(
            getToastData({
              title: t("OTP sent successfully"),
              status: "success",
            })
          );
        },
        onFailure: (errorMessage) => {
          otpSentRef.current = true;
          toast(
            getToastData({
              title: errorMessage,
              status: "error",
            })
          );
        },
      });
    }
  }


  useApiEffect(() => {
  if(forgetPass){
    sendForgetPasswordCode()
  }else{
    sendActivationCode()
  }
  }, []);

  const handelForgetPasswodOtp=()=>{
    verifyForgetPasswordOtp({
      otp: enteredOtp,
      activationToken: otpData.data.activationToken,
      onSuccess: (res) => {
       // console.log({res})
        navigate("/auth/changePassword");
        // toast(
        //   getToastData({
        //     title: t("Password changed successfully."),
        //     status: "success",
        //   })
        // );
        // setTimeout(() => {
        //   navigate("/auth/login");
        // }, 400);
      },
      onFailure: (errorMessage) => {
        toast(
          getToastData({
            title: errorMessage,
            status: "error",
          })
        );
      },
    });
  }

  useEffect(()=>{
    if(enteredOtp?.length === 4){
      if(forgetPass){
        handelForgetPasswodOtp()
      }else{
      handleOtpVerify()
      }
    }  
  },[enteredOtp])

  return (
    <VStack spacing="8">
      <Heading fontSize="30" mb="2" fontWeight="semibold">
        <Highlight
          query="Verification"
          styles={{color: "primary", whiteSpace: "wrap"}}
        >
          {t("Account Verification")}
        </Highlight>
      </Heading>
      <Image src={AuthenticationScreen} width="360px" mx="auto" />
      <Text textAlign="center" maxWidth="400px">
       {t("We have sent you an OTP on your")}{" "}
        <Box as="span" color="primary">
          {t("Phone")}
        </Box>
        , {t("Enter in fields to get verified.")}
      </Text>
      <HStack>
        <PinInput otp onComplete={(val) => setEnteredOtp(val)}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>

      <Button color={"white"} aria-label= {t("Verify OTP")} width="100%" onClick={handleOtpVerify}>
        {t("Verify")}
      </Button>
      <Box textAlign="center" mt="6">
        <Text>{t("Didn’t recieve the OTP?")}</Text>
        {/* <Link to="/auth/signup"> */}
        <Button
          aria-label= {t("Send OTP Again")}
          variant="unstyled"
          className="text-primary transition hover:underline"
        >
          {t("Send Again")}
        </Button>
        {/* </Link> */}
      </Box>
    </VStack>
  );
};

export default AuthVerifyOtpForm;
