import { PAYMENT_END_POINT } from "constants";
import { API_BASE_URL } from "constants";
import { apiService } from "services";
import { getAuthHeaders, getCookies } from "utils";
import { create } from "zustand";

const useFeatures = create((set, get) => ({
  activePlans: {
    state: "loading",
    data: [],
    message: "",
  },
  paymentData: {
    state: "loading",
    data: {},
    message: "",
  },
  discountDetails: {
    state: "loaded",
    data: {},
    message: "",
    coupon: {
      "couponCode": null,
      "product": null,
      "forCountry": null,
      "forUser": null,
      "planCode": null
    }
  },

  getActivePlans: async ({ onSuccess, onFailure }) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/plans/get-plans`,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        // console.log(res);
        set({ activePlans: { state: "loaded", data: res.body } });
        onSuccess?.();
      },
      onFailure: ({ message }) => {
        set({
          activePlans: { state: "erred", data: [], message },
        });
        onFailure?.(message);
      },
    });
  },
  addCustomer: async ({ onSuccess, onFailure }) => {
    const user =  getCookies("_Ud")
    const userData = await JSON?.parse(user)
    const data = {
      userId: userData?.userId,
      phone: userData?.mobileNo,
      name: userData?.fullNameEn,
      email: userData?.username,
      source: null,
      description: null,
      paymentMethod: null
    }

    apiService.post({
      url: `${PAYMENT_END_POINT}/customer/add-customer`,
      headers: getAuthHeaders(),
      data: JSON.stringify(data),
      onSuccess: (res) => {
        // console.log(res);
        onSuccess?.();
      },
      onFailure: ({ message }) => {
        onFailure?.(message);
      },
    });

  },

  initPayment: async ({ subscribe, planCode, onSuccess, onFailure }) => {
    const user = getCookies('_Ud')
    const userData = await JSON?.parse(user)
    const coupon = get().discountDetails?.coupon
    
    const data = {
      userId: userData?.userId,
      currency: 'USD',
      planCode: planCode,
      subscribe: subscribe,
      amount: 150.0,
      coupon: coupon,
    }
      //console.log({data , userData})
      
    apiService.post({
      url: `${PAYMENT_END_POINT}/payment/init-payment`,
      headers: getAuthHeaders(),
      data: data,
      onSuccess: (res) => {
        // console.log({ res });
        set({ paymentData: { state: "loaded", data: res.body } });
        onSuccess?.();
      },
      onFailure: ({ message }) => {
        set({
          paymentData: { state: "erred", data: [], message },
        });
        onFailure?.(message);
      },
    });
  },
  resetPaymentData:()=>{
    set({ paymentData: { state: "loading", data: {} } });
  },
  applyCoupon: async ({ planCode, coupon, onSuccess, onFailure, }) => {
    const user =getCookies("_Ud")
    const userData = await JSON?.parse(user)
    const data = {
      couponCode: coupon,
      product: planCode,
      forCountry: userData?.countryCode,
      forUser: userData?.userId,
      planCode:planCode
    }
    set({ discountDetails: { state: "loading", data: {}, message: "" } })

    apiService.post({
      url: `${API_BASE_URL}/api/v1/coupons/get-coupon-discount`,
      headers: getAuthHeaders(),
      data: JSON.stringify(data),
      onSuccess: (res) => {
        // console.log(res);
        const couponData = {
          couponCode: coupon,
          product: planCode,
          forCountry: userData?.countryEn,
          forUser: userData?.userId,
          planCode: planCode,
        }
        set({ discountDetails: { state: "loaded", data: res?.body , coupon:couponData} })
        onSuccess?.(res);
      },
      onFailure: ({ message }) => {
        const coupon ={
          "couponCode": null,
          "product": null,
          "forCountry": null,
          "forUser": null,
          "planCode": null
        }
        set({ discountDetails: { state: "loaded", data: {}, message , coupon} })
        onFailure?.(message);
      },
    });
  },
  resetDiscountDetails:()=>{
    const coupon ={
      "couponCode": null,
      "product": null,
      "forCountry": null,
      "forUser": null,
      "planCode": null
    }
    set({ discountDetails: { state: "loaded", data: {}, message:"" , coupon} })
  }


}));

export default useFeatures;
