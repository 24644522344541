import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Grid,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  IconBrandWhatsapp
} from "@tabler/icons";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const emailIcon = require("../../../assets/images/emailIcon.webp")
const phonIcon = require("../../../assets/images/phonIcon.webp")

const ProfileSupportForm = () => {
  const { t } = useTranslation()
  const INITIAL_VALUES = {
    firstName: "",
    email: "",
    query: "",
  };

  return (
    <Box pt="12">
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        // alignItems="center"
        justifyContent="space-between"
        gap="10"
      >


        <Card maxW="sm" textAlign='center'>
          <CardBody>
            <Image
              margin={"auto"}
              src={phonIcon}
              alt="Phone"
              borderRadius="lg"
            />
            <Stack mt="6" spacing="3">
              {/* <Heading size="md">Living room Sofa</Heading> */}
              <Text fontSize="lg">
                {t('Give us a ring at')}
              </Text>
              <Text color="primary" fontSize="lg">
                <a aria-label="phone" href={`tel:${t("+971 58 515 7119")}`}>
                  {t("+971 58 515 7119")}
                </a>
              </Text>
              <Text>
                {t('We welcome your service within 24 hours via WhatsApp or by calling the phone from 9 am to 5 pm Dubai time, on official working days from Monday to Friday.')}
              </Text>


            </Stack>
          </CardBody>
          <Divider color={"#ccc"} />
          <CardFooter>
            <ButtonGroup spacing="2" flex={1}>

              <a aria-label={t("phone")} style={{display:"flex" , flex:1}} href={`tel:${t("+971 58 515 7119")}`}>
                <Button color="white"  aria-label={t("Call Now")} style={{ display: "block", flex: 1 }} flex={1} variant="solid" colorScheme="primary">
                  {t("Call Now")}
                </Button>
              </a>

              <a aria-label={t("whatsapp")} target='_blank' style={{}} href={`https://web.whatsapp.com/send?phone${t('+971 58 515 7119')}`}>
              <Card>
              <Button color="white"    aria-label={t("whatsapp")} variant="ghost" colorScheme="#198754">
                <IconBrandWhatsapp size="35px" color="#198754" />
              </Button>
              </Card>
              </a>
              
            </ButtonGroup>
          </CardFooter>
        </Card>

        <Card maxW="sm" textAlign='center'>
          <CardBody>
            <Image
              margin={"auto"}
              src={emailIcon}
              alt="Email"
              borderRadius="lg"
            />
            <Stack mt="6" spacing="3">
              {/* <Heading size="md">Living room Sofa</Heading> */}
              <Text>
                {t('Simply drop us an email at')}
              </Text>
              <Text color="primary" fontSize="lg">
                <a aria-label={t("email")} href={`mailto:${t("support@catalyticprice.com")}`}>
                  {t("support@catalyticprice.com")}
                </a>
              </Text>
              <Text fontSize="lg">
                {t('You will receive a reply within 24 Hours')}
              </Text>

            </Stack>
          </CardBody>
          {/* <Divider /> */}
          {/* <CardFooter>
            <Button color="white" Group spacing="2">
              <Button color="white"  variant="solid" colorScheme="blue">
                Buy now
              </Button>
              <Button color="white"  variant="ghost" colorScheme="blue">
                Add to cart
              </Button>
            </Button>
          </CardFooter> */}
        </Card>




      </Grid>
    </Box>
  );
};

export default ProfileSupportForm;
