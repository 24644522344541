import {Box, Container, Flex, Heading, useColorMode} from "@chakra-ui/react";
import {FooterCTA} from "components";
import React from "react";
import {Outlet} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import responsiveStyle from "constants/responsiveStyle";

const Pricing = () => {
  const {t} = useTranslation()
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <main className="page">
      <Box as="section" display={{base:"none" , md:"block"}} height={responsiveStyle?.page_header_section_height} className={`hero ${!isDarkMode && "pricing-hero"}`}>
        <Container height="100%">
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              color="white"
              // className="-mt-24"
              fontWeight="extrabold"
              fontSize={{base: "40px", md: "50px", xl: "60px"}}
            >
              {t("Subscriptions")}
            </Heading>
          </Flex>
        </Container>
      </Box>
      <Box as="section" py={responsiveStyle?.section_py} className={!isDarkMode &&"blobs-bg"}>
        <Container>
          <Outlet />
        </Container>
      </Box>
      <FooterCTA />
    </main>
  );
};

export default Pricing;
