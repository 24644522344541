import { Accordion, AccordionItem, Box, VStack } from "@chakra-ui/react";
import { HistoryItem, Loader, SassyAlert } from "components";
import useApiEffect from "hooks/use-api-effect";
import React from "react";
import { useProfile } from "stores";
import moment from 'moment/moment';
import ProfileOrderOstoryDetails from "./pricing-plans/profile-order-history-details";



const ProfileOrderHistory = () => {
  const { ordersHistory, getOrdersHistory } = useProfile();

  useApiEffect(() => {
    if (ordersHistory.state === "loading") getOrdersHistory({
      onSuccess: () => { },
      onFailure: () => { },
    });
  }, []);
 // console.log({ ordersHistory })
  return (
    <Box>
      {ordersHistory.state === "erred" ? (
        <SassyAlert status="error" title={ordersHistory.message} />
      ) : ordersHistory.state === "loading" ? (
        <Loader />
      ) : (
        <VStack spacing="6" align="stretch">
          <Accordion allowToggle>
            {ordersHistory.data.map((o, i) => (
              <AccordionItem mt="5" borderColor="#fff">
                <HistoryItem
                  accordion = {true}
                  title={o.orderNo}
                  key={i}
                  price={`Total: ${o.total} ${o.currency}`}
                  // variant="bordered start"
                  date={moment(o.createdAt).format("DD MMM YYYY")}
                  time={moment(o.createdAt).format("hh:mm a")}
                  status={o?.orderStatus}
                  variant="bordered center"
                  content={<ProfileOrderOstoryDetails orderNo={o?.orderNo}/>}
                />
              </AccordionItem>
            ))}

          </Accordion>
{/* 
          {ordersHistory.data.map((o, i) => (
            <HistoryItem
              title={o.orderNo}
              key={i}
              price={`${o.total} ${o.currency}`}
              // variant="bordered start"
              date={moment(o.createdAt).format("DD MMM YYYY")}
              time={moment(o.createdAt).format("hh:mm a")}
              status={o?.orderStatus}
              variant="bordered center"
              content="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem I"
            />
          ))} */}
        </VStack>
      )}
    </Box>
  );
};

export default ProfileOrderHistory;
