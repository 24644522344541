import {Center} from "@chakra-ui/react";
import {IconArrowLeft} from "@tabler/icons";
import React from "react";

const CarouselPrevButton = ({onClickHandler, label}) => {
  return (
    <Center
      as="button"
      type="button"
      onClick={onClickHandler}
      title={label}
      position="absolute"
      zIndex="2"
      top="calc(50% - 15px)"
      cursor="pointer"
      left="15"
      height="40px"
      width="40px"
      bgColor="primary"
      color="white"
      borderRadius="full"
    >
      <IconArrowLeft />
    </Center>
  );
};

export default CarouselPrevButton;
