import { Box, useColorMode } from "@chakra-ui/react";
import React from "react";
import { CountryDropdown, } from 'react-country-region-selector';

const CountryDropDown = ({ onChange, value, style }) => {
    const { colorMode } = useColorMode()
    const isDarkMode = colorMode === "dark"
    return (
        <Box position="relative" display="flex">

            <CountryDropdown
                style={{
                    width: '100%',
                    color:isDarkMode ? "white":"#666",
                    fontSize: "16",
                    borderWidth: "1px",
                    padding: "10px",
                    borderRadius: "5px",
                    outline: "0px",
                    flex: 1,
                    backgroundColor:isDarkMode?"#2d3748": "white",
                    ...style
                }}
                value={value}
                onChange={onChange} />
        </Box>
    );
};

export default CountryDropDown;
