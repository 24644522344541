import {Box, Heading, Highlight, Text, useColorMode} from "@chakra-ui/react";
import {AuthSignUpForm} from "modules";
import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const AuthSignup = () => {
  const {t} = useTranslation()
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <Box as="section" >
      <Heading fontSize="30" mb="2" fontWeight="semibold">
        <Highlight
          query="Catalytic Price!"
          styles={{color: "primary", whiteSpace: "wrap"}}
        >
          {t("Welcome to Catalytic Price!")}
        </Highlight>
      </Heading>
      <Text color={isDarkMode ? "white":"black"} fontSize="22" mb="10">
        <Highlight
          query="Register"
          styles={{
            color: "primary",
            whiteSpace: "wrap",
            fontWeight: "semibold",
          }}
        >
          {t("Register Your account")}
        </Highlight>
      </Text>

      <AuthSignUpForm />

      {/* ------------- */}
      <Box textAlign="center" mt="6">
        <Text>{t("Already have an account?")}</Text>
        <Link aria-label={t("Login here")} to="/auth/login">
          <Text color="primary" fontWeight="medium">
            {t("Login here")}
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default AuthSignup;
