import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import "react-phone-input-2/lib/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./tailwind-index.css";
import "assets/styles/main.scss";
import './lang/i18n/index.ts';

// #2d3748
// theme.js

// 1. import `extendTheme` function
import { ColorModeScript, extendTheme } from '@chakra-ui/react'

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({ config })


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
