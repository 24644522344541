import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import OrderCompleteDoodle from "assets/images/online-test.png";
import {Link} from "react-router-dom";

const OrderCompleteModal = ({isOpen, onClose, orderNo}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      mx="10"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent py="10">
        <ModalHeader textAlign="center">
          <Heading as="h2" fontSize="26px" fontWeight="semibold">
            Order Complete
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Image alt='OrderCompleteDoodle' src={OrderCompleteDoodle} mx="auto" my="6" maxWidth="300px" />
          <VStack align="center" justify="flex-start" spacing="4" mt="16">
            <Heading as="h4" color="dark" fontSize="20px" fontWeight="semibold">
              Your Order has been Completed.
            </Heading>
            <Text textAlign="center">
              Give this order no to any buyer to complete your sell.
            </Text>
            <Heading as="h4" color="dark" fontSize="20px" fontWeight="semibold">
              Order No:
            </Heading>
            <Text color="primary" fontWeight="semibold" fontSize="18px">
              {orderNo}
            </Text>
            <Button aria-label="Show Buyers List"  as={Link} to="/buyers" width="80%" mx="auto">
              Show Buyers List
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OrderCompleteModal;
