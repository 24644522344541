import React, { useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Image,
  Radio,
  Text,
  useColorMode,
  useRadio,
  
} from "@chakra-ui/react";
import useApiEffect from "hooks/use-api-effect";

import { useStripe, useElements, PaymentElement,  } from "@stripe/react-stripe-js";

const ChoosePaymentMehtodForm = ({ setPreferredMethod }) => {
  // const { value, getRadioProps, getRootProps } = useRadioGroup({ defaultValue: "card", });
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/profile/current-plan`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    //console.log({error})
    setIsProcessing(false);
  };


  // console.log({paymentData})

  useApiEffect(() => {
    // console.log(planCode)
    // addCustomer({
    //   onSuccess: (res) => { console.log(res) },
    //   onFailure: (errorMessage) => { console.log(errorMessage) },
    // })
    // initPayment({
    //   planCode,
    //   subscribe: false,
    //   onSuccess: (res) => { console.log(res) },
    //   onFailure: (errorMessage) => { console.log(errorMessage) },
    // })
  }, [])

  return (
    <Box maxWidth={{ 
      //md: "60%", xl: "50%" 
      }} mx="auto">
      {/* <form
        onSubmit={(e) => {
          e.preventDefault();
          setPreferredMethod(value);
        }}
        {...getRootProps()}
      > */}
    {
    // paymentData.state === "erred" ? (
    //             <SassyAlert status="error" title={paymentData.message} />
    //         ) : paymentData.state === "loading" ? (
    //             <Loader />
    //         ) :
      (<form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        {/* <button disabled={isProcessing || !stripe || !elements} id="submit"> */}

        {/* <span id="button-text">
              {isProcessing ? "Processing ... " : "Pay now"}
            </span> */}

      {  <Box>
          <Button
            disabled = {true}//{isProcessing || !stripe || !elements}
            type="submit"
            width="100%"
            style={{marginTop:"20px"}}
            aria-label="Pay now"
            color="white"
          >
            {isProcessing ? "Processing ... " : "Pay now"}
          </Button>
        </Box>}
        {message && <div style={{color:"#df1b41"}} id="payment-message">{message}</div>}
      </form>)}


      {/* <VStack gap="6" alignItems="stretch">
          <PaymentOption
            {...getRadioProps({ value: "card" })}
            icon={VisaIcon}
            title="Credit / Debit Cards"
          />
          <PaymentOption
            {...getRadioProps({ value: "paypal" })}
            icon={PayPalIcon}
            title="Pay with PayPal"
          />
          <Button
            type="submit"
            width="100%"
            rightIcon={<IconArrowNarrowRight />}
          >
            Proceed to Pay
          </Button>
        </VStack> */}
      {/* </form> */}
    </Box>
  );
};

export default ChoosePaymentMehtodForm;

function PaymentOption({ title, icon, ...radioProps }) {
  const { state, getInputProps, getCheckboxProps } = useRadio(radioProps);
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <FormLabel cursor="pointer" m="0">
      <input {...input} />
      <Grid
        {...checkbox}
        bg="white"
        px="4"
        py="3"
        boxShadow="0px 0px 2px rgb(0 0 0 / 15%)"
        borderRadius="md"
        position="relative"
        templateColumns=".2fr 1fr .2fr"
        alignItems="center"
        gap="3"
      >
        <Box mx="3" textAlign="center">
          <Image  alt='icon'src={icon} mx="auto" />
        </Box>
        <Text color="dark" fontSize="15">
          {title}
        </Text>

        <Radio
          isChecked={state.isChecked}
          colorScheme="green"
          marginLeft="auto"
        />
      </Grid>
    </FormLabel>
  );
}
