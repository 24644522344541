import React from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "stores";
import { getCookies } from 'utils';

const RequireAuth = ({children}) => {
  const {user} = useAuth();

  const location = useLocation();
  //console.log(user)
  const token = getCookies("_Ut")

  return (user.isLoggedIn || token)? (
    children
  ) : (
    <Navigate to="/auth/login" state={{from: location}} replace />
  );
};

export default RequireAuth;
