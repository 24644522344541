import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";

import {IconHeart} from "@tabler/icons";
import React from "react";
import Card from "./card";

const PurchaseItem = ({
  name = "Unknown",
  description,
  price,
  image,
  quantity,
  onPlus,
  onMinus,
  onFavorite,
}) => {
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <Card p="2">
      <Flex align="center">
        <Box
          bgColor="gray.50"
          px="2"
          py="1"
          borderRadius="lg"
          alignSelf="stretch"
        >
          <Center w="100%" height="100%">
            <Image
              alt={name}
              src={image}
              maxWidth="80px"
              width="100%"
              objectFit="cover"
              borderRadius="lg"
              fallbackSrc="https://via.placeholder.com/100/F1FFF5/000000?text=No+Image+Found"
            />
          </Center>
        </Box>
        <Box flexGrow="1" py="4" pl={{base: "5", lg: "10"}}>
          <VStack justify="start" align="start" spacing="2">
            <Heading as="h4" fontSize="16" color={isDarkMode ? "white":"dark"} fontWeight="semibold">
              {name}
            </Heading>
            {description && <Text color="gray">{description} </Text>}
            {quantity && price && (
              <Text color="primary">{`${quantity} x ${price}`}</Text>
            )}
          </VStack>
        </Box>
        <Box>
          <HStack spacing="3">
            {onMinus && (
              <Button  aria-label="decrease cart quantity" variant="white" onClick={onMinus} size="sm">
                -
              </Button>
            )}
            {quantity && (
              <Heading fontSize="12px" fontWeight="semibold" color={isDarkMode ? "white":"dark"}>
                {quantity}
              </Heading>
            )}
            {onPlus && (
              <Button color={'white'} aria-label="increase cart quantity" onClick={onPlus} size="sm">
                +
              </Button>
            )}

            {onFavorite && (
              <Icon
                mr="4"
                color="red"
                fillColor="red"
                fontSize="28px"
                as={IconHeart}
                onClick={onFavorite}
              />
            )}
          </HStack>
        </Box>
      </Flex>
    </Card>
  );
};

export default PurchaseItem;
