export const API_MAIN_URL = "https://catalytic-price.com";
// export const API_MAIN_URL ='https://sit-catalyticprice.com'

export const API_BASE_URL = `${API_MAIN_URL}/catalytic`;

export const PAYMENT_END_POINT = "https://catalytic-price.com/payment-gateway/api/v1/stripe"

// export const API_BASE_URL = "http://localhost:3001/https://catalytic-price.com/catalytic";
// export const PAYMENT_END_POINT = "http://localhost:3001/https://catalytic-price.com/payment-gateway/api/v1/stripe"

//http://catalyticprice.live:8888
// http://catalyticprice.live/
// export const PAYMENT_END_POINT = "https://catalyticprice.live/payment-gateway/api/v1/stripe"
