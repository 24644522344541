import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Select,
  useColorMode
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import {
  IconCashBanknote,
  // IconBuilding,
  // IconChartPie2,
  // IconMail,
  // IconPhone,
  IconUser,
} from "@tabler/icons";
import { SassyPhoneInput } from "components";
// import PhoneInput from "react-phone-input-2";
import { useAuth, useProfile } from "stores";
import { getToastData } from "utils";
import { useState } from "react";
import currencies from "../../../constants/commonCurrency.json";
import CountryDropDown from './../../../components/country-dropDown';
import { useTranslation } from 'react-i18next';

const ProfileSettingsForm = () => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"

  const { user, updataUser } = useAuth();
  const toast = useToast();
  const { updateUserProfile } = useProfile();
  const { t } = useTranslation()

  const [isEditing, setIsEditing] = useState(false);

  const INITIAL_VALUES = {
    firstName: user.data.fullNameEn?.split(" ")[0],
    lastName: user.data.fullNameEn?.split(" ")?.[1],
    phoneNumber: user.data.fullMobileNo,
    country: user.data.countryEn,
    currency: user.data.defCurrency
  };


  const handleUpdateProfile = (values) => {
    toast(getToastData({ status: "loading", title: "Updating profile info" }));
    updateUserProfile({
      data: {
        countryCode: user.data.countryCode,
        country: values?.country,
        fullName: `${values.firstName} ${values.lastName}`,
        defCurrency: values?.currency,
        image: user.data.profilePicture,
        email: user.data.username
      },
      onSuccess: (res) => {
        toast.closeAll();
        // console?.log({ res })
        updataUser({ user: res?.body })
        setIsEditing(false);
        toast(getToastData({ status: "success", title: "profile Updated successfully" }));
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        toast(getToastData({ status: "error", title: errorMessage }));
      },
    });
  };

  return (
    <Box pt={{ base: 0, md: "12" }}>
      <Formik
        initialValues={INITIAL_VALUES}
        enableReinitialize
        onSubmit={handleUpdateProfile}
        onReset={() => {
          setIsEditing(false);
        }}
      >
        {/* {(props) => ( */}
        <Form>
          <Grid
            gap="12"
            justifyContent="center"
            alignItems="end"
            templateColumns={{ sm: "repeat(2,2fr)", }}
            // templateColumns={"repeat(auto-fit, minmax(200px, 1fr))"}
            // gap="8"
            mt="8"
          >
            <GridItem>
              <Field name="firstName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.firstName && form.touched.firstName}
                  >
                    {field.value && (
                      <FormLabel fontSize="sm" color="dark">
                        {t("First Name")}
                      </FormLabel>
                    )}
                    <InputGroup>
                      <Input
                        bgColor={isDarkMode ? "darkModeBg" : "#fff"}
                        color={isDarkMode ? "white" : "dark"}
                        {...field}
                        placeholder={t("First Name")}
                      //isReadOnly={!isEditing}
                      />
                      <InputRightElement
                        color="dark"
                        children={<IconUser size="18" color={isDarkMode ? "white" : "gray"} />}
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem>
              <Field name="lastName">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.lastName && form.touched.lastName}
                  >
                    {field.value && (
                      <FormLabel fontSize="sm" color="dark">
                        {t('Last Name')}
                      </FormLabel>
                    )}
                    <InputGroup>
                      <Input
                        bgColor={isDarkMode ? "darkModeBg" : "#fff"}
                        color={isDarkMode ? "white" : "dark"}
                        {...field}
                        placeholder={t('Last Name')}
                      //isReadOnly={!isEditing}
                      />
                      <InputRightElement
                        color="dark"
                        children={<IconUser color={isDarkMode ? "white" : "gray"} size="18" />}
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>


            <GridItem>
              <Field name="country">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      (form.errors.country && form.touched.country)}
                  >
                    {field.value && (
                      <FormLabel fontSize="sm" color="dark">
                        {t("Country")}
                      </FormLabel>
                    )}
                    <CountryDropDown style={{ width: "50%", padding: "6.5px" }} value={field.value} onChange={(value) => {
                      form.setFieldValue("country", value);
                    }} />
                    <FormErrorMessage>
                      {form.errors.country}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem>
              <Field name="currency">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.currency && form.touched.currency}
                  >
                    {field.value && (
                      <FormLabel fontSize="sm" color="dark">
                        {t("Currency")}
                      </FormLabel>
                    )}
                    <InputGroup>
                      <Select
                        placeholder={t("Choose your currency")}
                        icon={<IconCashBanknote color={isDarkMode ? "white" : "gray"} />}
                        size="lg"
                        onChange={(e) => {
                          form.setFieldValue("currency", e.target.value);
                        }}
                        fontSize="16"
                        bgColor={isDarkMode ? "darkModeBg" : "white"}
                        color={isDarkMode ? "white" : "dark"}
                        _placeholder={{ color: "gray" }}
                        defaultValue={field.value}
                        // borderColor={isDarkMode ?"":"#eee"}
                        borderWidth={1}
                      >
                        {Object.keys(currencies).map((currency, i) => (
                          <option
                            key={i} value={currency}>{currency} - {currencies[currency].name}</option>
                        ))}
                      </Select>
                    </InputGroup>
                    <FormErrorMessage>{form.errors.currency}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>

            <GridItem>
              <Field name="phoneNumber">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.phoneNumber && form.touched.phoneNumber
                    }
                  >
                    {field.value && (
                      <FormLabel fontSize="sm" color="dark">
                        {t("Phone Number")}
                      </FormLabel>
                    )}
                    <SassyPhoneInput
                      onChange={(phone) => { }}
                      value={field.value}
                      disabled={true}
                    />
                    <FormErrorMessage>
                      {form.errors.phoneNumber}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>


          </Grid>
          <Flex justify="end" mt="6">
            <HStack spacing="5">
              {isEditing ? (
                <>
                  <Button aria-label={t("Cancel")} type="reset" variant="outline">
                    {t("Cancel")}
                  </Button>
                  <Button  color={"white"} aria-label="submit" type="submit">{t("Save")}</Button>
                </>
              ) : (
                <Button color={"white"} aria-label={t("Edit")} onClick={() => setIsEditing(true)}>{t("Edit")}</Button>
              )}
            </HStack>
          </Flex>
        </Form>
        {/* )} */}
      </Formik>
    </Box>
  );
};

export default ProfileSettingsForm;
