import {API_BASE_URL, DEFAULT_BRAND_CATS_PER_PAGE} from "constants";
import {apiService} from "services";
import {create} from "zustand";
import {getAuthHeaders} from "../utils";

const useCatalysts = create((set, get) => ({
  allBrands: {
    state: "loading",
    data: [],
    errorMessage: "",
  },
  faveCats:{
    data:[]
  },
  getAllBrands: ({onSuccess, onFailure}) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/brands/get-all-brands`,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        set(() => ({allBrands: {state: "loaded", data: res.body}}));
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        set(() => ({
          allBrands: {state: "erred", data: [], errorMessage: message},
        }));
        onFailure?.(message);
      },
    });
  },
  getBrandCats: ({
    brandName,
    limit = DEFAULT_BRAND_CATS_PER_PAGE || 6,
    offSet,
    onSuccess,
    onFailure,
  }) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/cat/get-all-cats-by-brand`,
      data: [
        {key: "brand", value: brandName},
        {key: "limit", value: limit},
        {key: "offSet", value: offSet.toString()},
      ],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        onFailure?.(message);
      },
    });
  },
  getCatDetails: ({catId = 1, onSuccess, onFailure}) => {

    apiService.get({
      url: `${API_BASE_URL}/api/v1/cat/get-cat-details`,
      data: [{key: "catId", value: catId}],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure: ({message}) => onFailure?.(message),
    });

    
  },
  
  searchCatalysts: ({search, limit, onSuccess, onFailure ,offSet}) => {

    apiService.get({
      url: `${API_BASE_URL}/api/v2/cat/search`,
      data: [
        {key: "search", value: search},
        {key: "offSet", value: offSet},
        {key: "pageSize", value: limit},
   
      ],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        
        onSuccess?.(res);
      },
      onFailure: ({message}) => onFailure?.(message),
    });
    
  },
  getCatPrice: ({catId = 16314, onSuccess, onFailure}) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/cat/show-price`,
      data: [{key: "id", value: catId}],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        //console.log({res});
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        //console.log(message);
        onFailure?.(message);
      },
    });
  },
  addCatToFavorites: ({onSuccess, onFailure}) => {},

  getAllFavCats: ({onSuccess, onFailure}) => {
    apiService.get({
      url: `${API_BASE_URL}/api/v1/favorites/get-all-favorites`,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        const faveCats =  res?.body?.map?.((item)=>item?.catId)
       
        set(() => ({faveCats: {data: faveCats}}));
        //console.log(res?.body  ,faveCats );
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        //console.log(message);
        onFailure?.(message);
      },
    });
  },
  resetFavData:(()=>{
    set(() => ({faveCats: {data: []}}));
  }),
  deleteFavCats: ({onSuccess, onFailure ,catId}) => {
    const data={ catId }
    apiService.post({
      url: `${API_BASE_URL}/api/v1/favorites/delete`,
      data: JSON.stringify(data),
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        const newFaveData = get()?.faveCats?.data.filter((item)=>item !== catId)
        set(() => ({faveCats: {data: newFaveData}}));
        //console?.log({newFaveData})
        onSuccess?.(res);
      },
      onFailure: ({message}) => {
        onFailure?.(message);
      },
    });
  },
 
}));

export default useCatalysts;
