import { API_MAIN_URL } from "constants";
import { API_BASE_URL } from "constants";
import { apiService } from "services";
import { getAuthHeaders } from "utils";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useSystemSettings = create(
  devtools((set, get) => ({
    systemSettings: {
      state: "loading",
      data: {},
    },
    homeSystemSettings: {
      state: "loading",
      data: {},
    },
    privacyPolicy: {
      state: "loading",
      data: {},
    },
    termsAndConditions: {
      state: "loading",
      data: {},
    },
    getSystemSettings: ({ onSuccess, onFailure }) => {
      apiService.get({
        url: `${API_BASE_URL}/api/v1/settings/all`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          // console.log({res})
          set((state) => ({ systemSettings: { state: "loaded", data: res?.body } }));
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },
    getHomeSystemSettings: ({ onSuccess, onFailure }) => {

      apiService.get({
        url: `${API_BASE_URL}/api/v1/global-settings/home-screen-metal-price`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          // console.log({res})
          set((state) => ({ homeSystemSettings: { state: "loaded", data: res?.body } }));
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },
    getPrivacyPolicy: ({ onSuccess, onFailure }) => {
      const lang = localStorage.getItem('lang')
      const langCode = JSON?.parse(lang)?.lang_code

      set((state) => ({ privacyPolicy: { ...state?.privacyPolicy, state: "loading", } }));
      apiService.get({
        url: `${API_MAIN_URL}/system-management/api/v1/options/get/privacy_policy/${langCode}`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          // console.log({res})
          set((state) => ({ privacyPolicy: { state: "loaded", data: res?.body } }));
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },
    getTermsAndConditions: ({ onSuccess, onFailure }) => {
      set((state) => ({ termsAndConditions: { ...state?.termsAndConditions, state: "loading" } }));

      const lang = localStorage.getItem('lang')
      const langCode = JSON?.parse(lang)?.lang_code
      apiService.get({
        url: `${API_MAIN_URL}/system-management/api/v1/options/get/terms_and_condition/${langCode}`,
        headers: getAuthHeaders(),
        onSuccess: (res) => {
          // console.log({res})
          set((state) => ({ termsAndConditions: { state: "loaded", data: res?.body } }));
          onSuccess?.(res);
        },
        onFailure: ({ message }) => {
          onFailure?.(message);
        },
      });
    },


  }))
);

export default useSystemSettings;