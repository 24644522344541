import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';


export const CustomHelmet = ({ component,name }) => {
    const {t}  = useTranslation()
    return (
        <>
            <Helmet>
                <title>{t(`${name}Title`)}</title>
                <meta name="description" content={t(`${name}Desc`)} />
                <meta name="keywords" content={t(`${name}keywords`)} />
            </Helmet>
            {component}
        </>
    );
}