import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
  VStack,
} from "@chakra-ui/react";
import useApiEffect from "hooks/use-api-effect";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useFavorites} from "stores";
import {getToastData} from "utils";
import Card from "./card";
import Loader from "./loader";
import SassyAlert from "./sassy-alert";
import useAuth from './../stores/authStore';
import { useTranslation } from "react-i18next";

const AddToFavoriteModal = ({isOpen, onClose, catId , setIsFave}) => {
  const toast = useToast();
  const { user } = useAuth();
  const {t} = useTranslation()
  const {favoritesCollections, getAllFavoriteCollections, addItemToFavorites} =
    useFavorites();
  const [selectedCollection, setSelectedCollection] = useState(null);

  const handleAddToFavorites = () => {
    if (!selectedCollection) {
      toast(
        getToastData({
          title: "Select a collection to add catalyst to favorites",
        })
      );
      return;
    }

    toast(
      getToastData({
        status: "loading",
        title: "Adding catalyst to selected favorites collection",
      })
    );
    addItemToFavorites({
      collectionId: selectedCollection.collectionId,
      catId,
      onSuccess: (res) => {
        toast.closeAll();
        setIsFave(true)
        toast(
          getToastData({
            status: "success",
            title: "Added the catalyst to selected favorites collection",
          })
        );
        onClose();
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "error",
            title:
              errorMessage ||
              "There was an error while adding the catalyst to selected favorites collection",
          })
        );
      },
    });
  };

  useApiEffect(() => {
    if (favoritesCollections.state === "loading" || favoritesCollections.state === "erred") {
      getAllFavoriteCollections({});
    }
  }, [user]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      mx="10"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent py="10">
        <ModalHeader textAlign="center">
          <Heading as="h2" fontSize="26px" fontWeight="semibold">
            Choose a collection to add the item to favorites
          </Heading>
        </ModalHeader>
        <ModalBody>
          {favoritesCollections.state === "erred" ? (
            <SassyAlert
              status="error"
              title="There was an error while loading your favorites collections"
            />
          ) : favoritesCollections.state === "loading" ? (
            <Loader />
          ) : favoritesCollections.data.length === 0 ? (
            <>
              <SassyAlert
                status="info"
                title="No favorites collection found"
                description={`You have not created any favorites collection yet. `}
              />
              <Center mt="4">
                <Button  aria-label={t("Create favorites")} as={Link} to="/profile/favorites">
                  {t("Create One Now")}
                </Button>
              </Center>
            </>
          ) : (
            <>
              <Grid
                templateColumns={"repeat(auto-fit, minmax(200px, 1fr))"}
                gap="8"
                mt="8"
              >
                {favoritesCollections.data.map((c) => (
                  <GridItem key={c.collectionId}>
                    <Card
                      p="4"
                      height="100%"
                      onClick={() => setSelectedCollection(c)}
                      bgColor={
                        selectedCollection &&
                        selectedCollection?.collectionId === c.collectionId
                          ? "secondary"
                          : "initial"
                      }
                    >
                      <Center width="100%" height="100%">
                        <VStack spacing="8">
                          <div>
                            <Heading
                              as="h4"
                              fontSize="md"
                              color="gray"
                              textAlign="center"
                            >
                              {c.collectionName}
                            </Heading>
                          </div>
                        </VStack>
                      </Center>
                    </Card>
                  </GridItem>
                ))}
              </Grid>
              <Box mt="4">
                <Button aria-label="add to Favourites" onClick={handleAddToFavorites}>Continue</Button>
              </Box>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddToFavoriteModal;
