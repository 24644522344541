import { Flex, Spinner, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import { defaultCurrency } from "constants/currencies.ts";
import Cookies from "js-cookie";

export const isObjectValid = (object) =>
  object &&
  Object.keys(object).length > 0 &&
  Object.getPrototypeOf(object) === Object.prototype;

export const sluggify = (text) => text.toLowerCase().replaceAll(" ", "-");
export const deSluggify = (text) => text.replaceAll("-", " ").toUpperCase();
export const capitalize = (text) =>
  text
    .toLowerCase()
    ?.split(" ")
    ?.map((p) => p[0]?.toUpperCase() + p?.slice(1, p.length))
    ?.join(" ");

export const getAuthHeaders = () => {

  return{
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin" : "*",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    Authorization: getCookies("_Ut") ? "Bearer " + getCookies("_Ut") : "",
    Currency: localStorage.getItem("defCurrency") || localStorage.getItem("currency") ,
    lang: "en",
    // 'Cache-Control': 'no-cache'
  }
};

export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export const parsePrice = (price) => price.toFixed(2);

export const getToastData = ({
  title,
  description = "",
  status = "info",
  variant = "solid",
  duration = "2000",
  isClosable = true,
  position = "top-right",
}) => {
  if (status === "loading")
    return {
      position,
      variant,
      duration: 999999,
      render: () => (
        <Flex color="black" gap="4" p={3} bg="gray.400" borderRadius="lg">
          <Spinner />
          <VStack justify="flex-start" align="flex-start">
            <Text>{title}</Text>
            {description && <Text>{description}</Text>}
          </VStack>
        </Flex>
      ),
    };
  return {
    title,
    description,
    status,
    variant,
    duration,
    isClosable,
    position,
  };
};


export function randomCode(length) {
  let result = '';
  const characters = 'ABOPQRSTUVWXYZabcdefghijklmnopqrstuvcdefCDEFGHIJKLMNOPQRSTUVWXYZabDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}







export const getCookies = (name)=>{
 return Cookies.get(name)
}

export const removeCookies = (name)=>{
  return Cookies.remove(name)
 }

export const setCoockies = ({name , value})=>{
  Cookies.set(name , value ,{
    expires:100,
    secure:true,
    sameSite:"strict ",
    path:"/"
  })
}

export const getCountryDefaultData = async () => {
  const countryDefaultData = localStorage?.getItem("countryDefaultData")
  if (countryDefaultData) {
    // console.log("countryDefaultData")
  } else {
    try {
      localStorage.setItem("currency", defaultCurrency.currency[0])
      const res = await axios.get(`https://ipapi.co/json`)
      localStorage?.setItem("countryDefaultData", JSON.stringify(res?.data))

      localStorage.setItem("defaultCountry", res?.data?.country_code)
      // localStorage.setItem("currency", res?.data?.currency)
      
      localStorage.setItem("countryName", res?.data?.["country_name"])

    } catch (error) {
      localStorage.setItem("currency", "USD")
     // console.log({ error })
    }
  }
}


export const timestampTotime = (imestamp) => {
  const hours = Math.floor(imestamp / 3600)
  const minutes = Math.floor((imestamp % 3600) / 60)
  // const seconds = imestamp % 60
  //:${Math?.floor(seconds)}s
  return `${hours}h: ${minutes}m  `
}



export  const CalculatePfees = ({fees , price })=>{
     const fessValue =   typeof(fees) === "number" ?  (+price/100) * fees: (+price/100) * fees[0]
       const newPrice = +price - fessValue
       if(typeof(fees) === "number" ? fees === 0 : fees[0] ===0){
         return `${new Intl.NumberFormat().format(+price) } `
       }

       return `${new Intl.NumberFormat().format(+newPrice.toFixed(2)) } `
}