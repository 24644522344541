import { Box,  VStack } from "@chakra-ui/react";
import { HistoryItem } from "components";
// import { dummyCreditHistory } from "constants";
import useApiEffect from "hooks/use-api-effect";
import React from "react";
import { useProfile } from 'stores';
import moment from 'moment/moment';
import ListItem from './../components/list_item';
import { useTranslation } from 'react-i18next';
const ProfileCreditHistory = () => {
  const {t} = useTranslation()
  const { creditsHistory, getCreditsHistory, } = useProfile();

  useApiEffect(() => {
    if (creditsHistory.state === "loading") getCreditsHistory({
      onSuccess: () => { },
      onFailure: () => { },
    });
  }, []);
  //console.log({ creditsHistory })

  return (
    <Box>
      <VStack spacing="6" align="stretch">
        {creditsHistory?.data?.map((s, i) => (
          <HistoryItem
          accordion = {false}
            item={s}
            {...s}
            title={s?.planName || s?.planCode || s?.tranType}
            quantity={""}
            // price={`${s?.creditBalance} Credits`}
            status={s?.planStatus}
            date={moment(s.createdAt).format("DD MMM YYYY")}
            time={moment(s.createdAt).format("hh:mm a")}
            image={""}
            icon={""}

            content={<Box mt="4">
              <ListItem itemKey={t("Total Before Discount:")} value={s?.totalBeforeDiscount +" USD"} />
              <ListItem itemKey={t("Total After Discount:")} value={s?.totalAfterDiscount +" USD"} />
              <ListItem itemKey={t("Discount")+`:`}  value={s?.discount +" USD"} />
              <ListItem itemKey={t("Total Paid:")}  value={s?.totalPaid +" USD"} />
              <ListItem itemKey={t("Balance")+`:`} value={s?.creditBalance +"Credits"} />
              <ListItem itemKey={t("Expiration Date:")} value={moment(s.expirationDate).format("DD MMM YYYY")} />
            </Box>}
            key={i}
            variant="bordered center"
          />
        ))}
      </VStack>
    </Box>
  );
};

export default ProfileCreditHistory;
