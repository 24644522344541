import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import AuthLogin from './../views/auth/auth-login';


const AuthModal = ({ isOpen, onClose }) => {
    const [screen, setModalScreen] = useState("Login")



    return (
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                    setModalScreen("Login")
                }}
                size="md"
                mx="10"
                scrollBehavior="inside"
              
            >
                <ModalOverlay />
                <ModalContent py="5" mx={10}  >
                    <ModalCloseButton />
                    <ModalBody>

                        {screen === "Login" && <AuthLogin  onClose={onClose} setModalScreen={setModalScreen} />}
                        {/* {screen === "Signup" &&  <AuthSignup setModalScreen={setModalScreen}/>}
                   {screen === "VerifyOtp" && <AuthVerifyOtpForm setModalScreen={setModalScreen} />} */}
                    </ModalBody>
                </ModalContent>
            </Modal>
    );
};

;
export default AuthModal;
