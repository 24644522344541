import { useCallback, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IconArrowRight, IconEye, IconHeart } from "@tabler/icons";
import {
  AddToFavoriteModal,
  BuyItemButtons,
  CatSearchBox,
  Loader,
  MetalDetailsModal,
  SassyAlert,
} from "components";
import { useAuth, useCart, useCatalysts } from "stores";
import CatSpecifications from "./cat-specifications";
import { getCookies, getToastData } from "utils";
import useApiEffect from './../../hooks/use-api-effect';
import { Link as ReachLink } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import ImageViewer from 'react-simple-image-viewer';
import AuthModal from "components/auth-modal";


const notFoundImage = require('../../assets/images/notFound.webp')

const CatDetailsView = () => {
  const navigate = useNavigate();
  const toast = useToast();
  let { catId } = useParams();
  const { t } = useTranslation()
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  catId = parseInt(catId);



  const { getCatDetails, getCatPrice, faveCats, deleteFavCats } = useCatalysts();
  const { cart, addItemToCart, removeItemFromCart } = useCart();
  const { user } = useAuth();
  const [isFavs, setIsFave] = useState(faveCats?.data?.includes(catId))
  const [loading, setLoading] = useState(false)
  // state for metal details modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddFavoriteModalOpen,
    onOpen: onAddFavoriteModalOpen,
    onClose: onAddFavoriteModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddLoginModalOpen,
    onOpen: onAddLoginModalOpen,
    onClose: onAddLoginModalClose,
  } = useDisclosure();


  // state for managing images slider
  //console.log({user})
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = (index) => setCurrentSlide(index);

  const [catDetails, setCatDetails] = useState({
    state: "loading",
    data: {},
    errorMessage: "",
    catPrice: {
      data: null,
      state: "initial",
    },
  });


  //==================
  //show price
  //================
  const showCatPrice = () => {
    if (user.isLoggedIn) {
      getCatPrice({
        catId: catDetails.data.catId,
        onSuccess: (res) => {
          setCatDetails((prev) => ({
            ...prev,
            catPrice: { data: res.body, state: "loaded" },
          }));
        },
        onFailure: (errorMessage) => {
          setCatDetails((prev) => ({
            ...prev,
            catPrice: { data: null, state: "erred", errorMessage },
          }));
        },
      });
    } else {
      toast(getToastData({ title: "You'll have to login first.", duration: 3000 }));
      //navigate(`/auth/login`, { replace: true });
      onAddLoginModalOpen()
    }
  };

  useApiEffect(() => {
    getCatDetails({
      catId,
      onSuccess: (res) =>
        setCatDetails((prev) => ({
          state: "loaded",
          data: res.body,
          // ...prev,
          catPrice: {
            data: null,
            state: "initial",
          },
        })),
      onFailure: (errorMessage) =>
        setCatDetails((prev) => ({
          state: "erred",
          data: {},
          errorMessage,
          // ...prev,
          catPrice: {
            data: null,
            state: "initial",
          },
        })),
    });
  }, []);

  const showMetalDetails = () => {
    if (getCookies("_Ut")) {
      onOpen()
    } else {
      toast(getToastData({ title: "You'll have to login first.", duration: 3000 }));
      // navigate(`/auth/login`, { replace: true });
      // setIsLoginModalOpen(true)
      onAddLoginModalOpen()
    }
  }
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  //==========================
  //delet Fave
  //==========================
  const onDeleteFromFavourites = async () => {
    setLoading(true)
    await deleteFavCats({
      catId,
      onSuccess: () => {
        setIsFave(false)
        setLoading(false)
      },
      onFailure: (errorMessage) => {
        setLoading(false)
        toast(getToastData({ title: errorMessage, duration: 3000, status: "error" }));
      }
    })
  }
  const images = catDetails?.data?.images?.map((item) => item?.fullImageURL)


  return (
    <>
      {/* image viewer */}
      {isViewerOpen && catDetails?.data?.images && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: "#000000bf"
          }}
        />
      )}

      <main className="page">
        <Box as="section" height={{base:"30vh" , xl:"45vh"}} className={`hero ${!isDarkMode && "search-hero"}`} >
          <Container height="100%">
            <Flex
              width="100%"
              height="100%"
              justify="center"
              align="center"
              textAlign="center"
            >
              <Box width={{ base: "100%", md: "70%" }}>
                <CatSearchBox />
              </Box>
            </Flex>
          </Container>
        </Box>
        <Box as="section" py={{base:0 , md:24}}>
          <Container>
            {catDetails.state === "erred" ? (
              <SassyAlert
                status="error"
                title={catDetails.errorMessage || "Error"}
                description="An error occurred while getting the catalyst details."
              />
            ) : catDetails.state === "loading" ? (
              <Loader />
            ) : (
              catDetails.state === "loaded" && (
                <Grid
                  templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                  // alignItems="center"
                  justifyContent="space-between"
                  gap="10"
                >
                  <GridItem>
                  <Card>
                  <Box
                      boxShadow="0px 0px 11.4184px rgba(0, 0, 0, 0.15)"
                      p="4"
                      borderRadius="lg"
                    >
                      <HStack justify="space-between">
                        {loading ? <Loader size="sm" thickness="2px" /> :
                          <Icon
                            as={IconHeart}
                            color="primary"
                            fontSize="30"
                            fill={(isFavs || faveCats?.data?.includes(catId)) && "primary"}
                            onClick={!user?.isLoggedIn ? onAddLoginModalOpen : isFavs ? onDeleteFromFavourites : onAddFavoriteModalOpen}
                          />}

                        <Link aria-label={t('PGM Details')}as="button" color="primary" onClick={showMetalDetails}>
                          {t('PGM Details')} <Icon as={IconArrowRight} fontSize={20} />
                        </Link>
                      </HStack>
                      <MetalDetailsModal
                        isOpen={isOpen}
                        onClose={onClose}
                        pd={catDetails.data.pd}
                        pt={catDetails.data.pt}
                        rh={catDetails.data.rh}
                      />
                      <Carousel
                        className="cart-details-carousel"
                        showArrows={true}
                        selectedItem={currentSlide}
                        onChange={changeSlide}
                        showStatus={false}
                        showThumbs={false}
                        showIndicators={false}
                        swipeable
                        // width="500px"

                      >
                        {catDetails.data.images?.length > 0 ? (
                          catDetails.data.images?.map((i, index) => {
                            // const image = i.fullImageURL
                            //console?.log(image)
                            return (
                              <Center height="100%"
                                style={{
                                  height: "250px",
                                  // minWidth: "500px"
                                }}
                                width="100%" onClick={() => {
                                  openImageViewer(index)
                                }} >
                                <Image
                                  alt={"Catalytic Converter"}
                                  src={i.fullImageURL}
                                  maxWidth="80%"
                                  mx="auto"
                                  my="4"
                                  style={{ height: "250px" }}
                                  borderRadius="md"
                                />
                              </Center>
                            )
                          })
                        ) : (
                          <div>
                            <Image
                              alt="notFoundImage"
                              src={notFoundImage}
                              maxWidth="80%"
                              mx="auto"
                              my="4"
                              borderRadius="md"
                              style={{ height: "250px" }}
                            />
                          </div>
                        )}
                      </Carousel>

                      <Box mt="4">
                        <HStack
                          gap="4"
                          justify="center"
                          align="stretch"
                          wrap="wrap"
                        >
                          {catDetails.data.images?.length > 0 ? (
                            catDetails.data.images?.map((i, index) => (
                              <Box
                                bgColor="gray.100"
                                borderRadius="8"
                                outlineOffset={4}
                                p="2"
                                outline={
                                  currentSlide === index ? "3px solid green" : ""
                                }
                                display="flex"
                                alignItems="center"
                              >
                                <Image
                                  alt={"Catalytic Converter"}
                                  key={index}
                                  onClick={() => changeSlide(index)}
                                  src={i.fullImageURL}
                                  width="50px"
                                  height="50px"
                                  borderRadius="md"
                                />
                              </Box>
                            ))
                          ) : (
                            <Box
                              bgColor="gray.100"
                              borderRadius="8"
                              outlineOffset={4}
                              p="2"
                              outline="3px solid green"
                            >
                              <Image
                                alt="notFoundImage"
                                src={notFoundImage}
                                width="50px"
                                borderRadius="md"
                              />
                            </Box>
                          )}
                        </HStack>
                      </Box>

                      <Flex justify="center" mt="10">

                        {catDetails.catPrice.state === "erred" ? (
                          <SassyAlert
                            status="error"
                            title={
                              catDetails.catPrice.errorMessage ||
                              "There was an error while loading catalyst's price"
                            }
                          />
                        ) : catDetails.catPrice.state === "loading" ? (
                          <Loader />
                        ) : catDetails.catPrice.state === "loaded" ? (
                          <Heading as="h4" color="primary" size="lg">
                            {catDetails.catPrice.data.formattedPrice}
                            {" "} {localStorage.getItem("defCurrency")}
                          </Heading>
                        ) : (
                          <Button
                            variant="outline"
                            w="80%"
                            mx="auto"
                            onClick={showCatPrice}
                            aria-label={t('Show Price')} 
                          >
                            {t('Show Price')} <Icon as={IconEye} fontSize="24" ms="4" />
                          </Button>
                        )}

                      </Flex>
                      {(catDetails.catPrice.errorMessage === "EMAIL_ADDRESS_NOT_EXIST" || catDetails.catPrice.errorMessage === "EMAIL_ADDRESS_NOT_EXIST") && <Flex justify="end">
                        <Link aria-label={t('Activate Your Email')} as={ReachLink} to="/profile/email-activation" color="primary" >
                          {t('Activate Your Email')}
                          <Icon as={IconArrowRight} fontSize={20} />
                        </Link>
                      </Flex>}

                    </Box>
                  </Card>
                  </GridItem>
                  <GridItem>
                    <CatSpecifications catDetails={catDetails.data} />
                    <Box mt="5">
                      <BuyItemButtons
                        onPlus={() => {
                          if (catDetails.catPrice.state === "loaded")
                            addItemToCart(catDetails.data.catId, catDetails.data);
                          else
                            toast(
                              getToastData({
                                title:
                                  "You have to load the price for this catalyst before adding it to cart.",
                              })
                            );
                        }}
                        quantity={
                          cart.find((i) => i.catId === catDetails.data.catId)
                            ?.cartQty || 0
                        }
                        onMinus={() => {
                          removeItemFromCart(catDetails.data.catId);
                        }}
                        onBuy={() => {
                          if (catDetails.catPrice.state === "loaded")
                            addItemToCart(catDetails.data.catId, catDetails.data);
                          else
                            toast(
                              getToastData({
                                title:
                                  "You have to load the price for this catalyst before adding it to cart.",
                              })
                            );
                        }}
                      />
                    </Box>
                  </GridItem>
                </Grid>
              )
            )}
          </Container>
          <AddToFavoriteModal
            isOpen={isAddFavoriteModalOpen}
            onClose={onAddFavoriteModalClose}
            catId={catId}
            setIsFave={setIsFave}
          />
          <AuthModal isOpen={isAddLoginModalOpen} onClose={onAddLoginModalClose} />
        </Box>
      </main>
      {/* <AuthModal isOpen={isLoginModalOpen} onClose={() => { setIsLoginModalOpen(false) }} /> */}
    </>
  );
};

export default CatDetailsView;
