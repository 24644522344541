import {Box, Spinner} from "@chakra-ui/react";
import React from "react";

const Loader = (props) => {
  return (
    <Box display="grid" justifyContent="center" alignItems="center" {...props}>
      <Spinner
        thickness={props?.thickness ? props?.thickness :"4px"}
        speed="0.65s"
        emptyColor="secondary"
        color="primary"
        size= {props?.size ? props?.size :"xl"}
      />
    </Box>
  );
};

export default Loader;
