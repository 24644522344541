import {Button, Flex, Text} from "@chakra-ui/react";

const BuyItemButtons = ({onBuy, onPlus, onMinus, quantity}) => {
  return (
    <Flex gap="4" align="center">
      {onMinus && quantity > 0 && (
        <Button  aria-label="Buy" variant="outline" onClick={onMinus}>
          -
        </Button>
      )}
      {quantity > 0 && <Text fontWeight="semibold"> {quantity}</Text>}
      {onPlus && quantity > 0 && <Button aria-label="Buy" onClick={onPlus}>+</Button>}
      {onBuy && quantity === 0 && (
        <Button color='white' aria-label="buy" flexGrow={1} onClick={onBuy}>
          Add To Cart
        </Button>
      )}
    </Flex>
  );
};

export default BuyItemButtons;
