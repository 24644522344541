import {Box, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {
  ProfileCreditHistory,
  ProfileOrderHistory,
  ProfileSearchHistory,
} from "modules";
import React from "react";
import { useTranslation } from 'react-i18next';

const ProfileHistory = () => {
  const {t} = useTranslation()
  const tabsProps = {
    _hover: {bgColor: "secondarydark", color: "black"},
    _selected: {bgColor: "primary", color: "white"},
    border: "1.5px solid transparent",
    borderRadius: "lg",
  };

  return (
    <Box>
      <Tabs isFitted variant="unstyled">
        <TabList
          mb="6"
          overflow="hidden"
          borderRadius="lg"
          outline="1.5px solid green"
        >
          <Tab {...tabsProps}>{t("Search History")}</Tab>
          <Tab {...tabsProps}>{t("Transactions History")} </Tab>
          <Tab {...tabsProps}>{t("Sales History")} </Tab>
          
        </TabList>
        <TabPanels> 

          <TabPanel>
            <ProfileSearchHistory />
          </TabPanel>

          <TabPanel>
            <ProfileCreditHistory />
          </TabPanel>
          
          <TabPanel>
            <ProfileOrderHistory />
          </TabPanel>
          
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ProfileHistory;
