import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, useColorMode } from "@chakra-ui/react";
import Footer from "./footer";
import AppNavbar from "./navbar";

const Layout = ({ children, ...props }) => {
  const location = useLocation();
  const { colorMode } = useColorMode()

  const isNavbarDark = 
    ["/cart", "/buyers",].includes(location.pathname) ||  
    location.pathname.includes("/profile") || location.pathname.includes("/pricing/checkout") || location.pathname.includes("/auth");

  return (
    <Box className="relative" marginBottom={{ base: 14, md: 0, }} marginTop={{ base: 20 , md: 0 }} >
      <AppNavbar
        className="absolute top-0 right-0 left-0 h-24"
        variant={ colorMode === "dark" ? "light":isNavbarDark ? "dark" : "light"}

      />
       <Box pb={{base:20 , md:0}}>
       <Outlet />
       </Box>
      {!(location.pathname.includes("/profile") || location.pathname.includes("/auth")) && <Footer />}
    </Box>
  );
};

export default Layout;
