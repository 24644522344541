import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Highlight,
  Image,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import ForgotPasswordImage from "assets/images/man-forgot.webp";
import { useNavigate } from "react-router-dom";
import { SassyPhoneInput } from "components";
import { useTranslation } from 'react-i18next';
import { forgetPasswordValidationSchema } from './auth-login-form/utils';
import { Field, Form, Formik } from "formik";
import { useAuth } from "stores";

export const INITIAL_STATE = {
  phoneNumber: "",
  dialCode: localStorage?.getItem('defaultCountry') ? localStorage?.getItem('defaultCountry').toLowerCase() : "za",
};
const ForgotPasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const {setForgetPassPhone} = useAuth();


  const submitHandler = (values) => {
    //console?.log({values})
    setForgetPassPhone({mobileCode:values?.dialCode , mobileNo:values?.phoneNumber})
    navigate("/auth/verify-otp/forgetPass");
  };


  return (
    <VStack spacing="8">
      <Heading fontSize="30" mb="2" fontWeight="semibold">
        <Highlight
          query={t("Password")}
          styles={{ color: "primary", whiteSpace: "wrap" }}
        >
          {t("Forgot Your Password")}
        </Highlight>
      </Heading>
      <Image alt="ForgotPasswordImage" src={ForgotPasswordImage} width="360px" mx="auto" />
      <Text textAlign="center" maxWidth="400px">
        {t("Enter your registered Phone to get a Reset link and create new password.")}
      </Text>


      <Formik
        onSubmit={submitHandler}
        initialValues={INITIAL_STATE}
        enableReinitialize
        validationSchema={forgetPasswordValidationSchema}
      >

        <Box mb="8" width="100%">
          <Form>
            <Field name="phoneNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.phoneNumber && form.touched.phoneNumber
                  }
                >
                  <SassyPhoneInput
                    onChange={(phone, country) => {
                      form.setFieldValue("dialCode", country.dialCode);
                      form.setFieldValue(
                        "phoneNumber",
                        phone?.split(country.dialCode)[1]
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {form.errors.phoneNumber}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button color={"white"} aria-label={t("Send OTP to active user mobile")} mt="8" width="100%" type="submit">
              {t("Send OTP")}
            </Button>
          </Form>
        </Box>




      </Formik>
    </VStack>
  );
};

export default ForgotPasswordForm;
