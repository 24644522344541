import {Box, Center, Flex, Grid, GridItem, Image, Text, useColorMode} from "@chakra-ui/react";
import React from "react";
import LogoDark from "assets/images/logo-dark.webp";
import {Link, Outlet} from "react-router-dom";
import { useTranslation } from 'react-i18next';
const AuthView = () => {
  const {t} = useTranslation()
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  return (
    <Box
      as="main"
      className="page"
      width="100%"
      minHeight="100vh"
      height="100vh"
    >
      <Box as="section" width="100%" height="100%" backgroundColor={{base:!isDarkMode&& "white" , lg: !isDarkMode ?"primary" :"darkModeBg"}}>
        <Grid templateColumns={{base: "1fr", lg: "1.3fr 2.7fr"}} height="100%">
          <GridItem  display={{base: "none", lg: "block"}}>
            <Box
              className={!isDarkMode &&"colored-pattern-bg"}
              height="100%"
              px="10"
              py={{base:'0' ,md: "28", lg: "40"}}
              display={{base: "none", lg: "block"}}
              bgColor={ isDarkMode && "darkModeBg"}
            >
              <Flex
                align="center"
                justify="space-between"
                flexDirection="column"
                height="100%"
                gap={{base:'0' ,md: "24", lg: "auto"}}
              >
                <Link  aria-label={t("home")} to="/home">
                  <Box
                    bgColor="white"
                    px="10"
                    py="4"
                    borderRadius="md"
                    boxShadow="md"
                    w="max-content"
                    mx="auto"
                  >
                    <Image
                      alt="logo"
                      src={LogoDark}
                      width={{base: "130px", sm: "170px", lg: "190px"}}
                    />
                  </Box>
                </Link>
                <Text
                  color="white"
                  textAlign="center"
                  maxWidth="500px"
                  mx="auto"
                >
                    {t("We update the prices every 2 hours acoording to the market price so you will always be up to date and get the acurate price for your catalytic converters")}
                </Text>
              </Flex>
            </Box>
          </GridItem>
          <GridItem>
            <Box
              py={{ base:0,md:"16"}}
              px={{base:"6" , lg:"16"}}
              height="100%"
              marginLeft={{bade:"0" , lg:"-2"}}
              backgroundColor={!isDarkMode ?"white" :"#1a202c"}
              borderTopLeftRadius="16"
              borderBottomLeftRadius="16"
            >
              <Center  width="100%" height="100%" mt={{ base:0,md:7 }}>
                <Outlet />
              </Center>
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default AuthView;
