import {Box, Container, Flex, Heading, useColorMode} from "@chakra-ui/react";
import {FooterCTA} from "components";
import React from "react";
import {Outlet} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import responsiveStyle from "constants/responsiveStyle";

const CarBrands = () => {
  const {t} = useTranslation()
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  // return <h1> ===========----=====</h1>
  return (
    <main className="page">
      <Box display={{base:"none" , md:"block"}} as="section" height={responsiveStyle?.page_header_section_height} className={`hero ${!isDarkMode && 'car-brands-hero'}`} >
        <Container height="100%" >
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              color="white"
             // className="-mt-24"
              fontWeight="extrabold"
              fontSize={{base: "30", md: "50px", xl: "60px"}}
             // top={{base:0 , xl :0}}
            >
              {t("Car Brands")}
            </Heading>
          </Flex>
        </Container>
      </Box>
      <Box as="section" py={responsiveStyle?.section_py}>
        <Container>
          <Outlet />
        </Container>
      </Box>
      <FooterCTA />
    </main>
  );
};

export default CarBrands;
