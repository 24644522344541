import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {IconEye, IconEyeOff} from "@tabler/icons";
import {Field, Form, Formik} from "formik";
import React, {useState} from "react";
import {useProfile} from "stores";
import {getToastData} from "utils";
import * as yup from "yup";
import { useTranslation } from 'react-i18next';

const ProfileSecurityForm = () => {
  const {t} = useTranslation()
  const {changePassword} = useProfile();
  const toast = useToast();
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  const [
    {state, showOldPassword, showNewPassword, showConfirmPassword},
    setViewState,
  ] = useState({
    state: "",
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const INITIAL_VALUES = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required(t("Old password is required")),
    newPassword: yup.string().required(t("New password is required")),
    confirmPassword: yup
      .string()
      .required(t("You have to confirm your new password"))
      .oneOf([yup.ref("newPassword")], t("Your passwords do not match.")),
  });

  const onSubmit = (values) => {
    toast(getToastData({status: "loading", title: t("Updating password ...")}));
    changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      onSuccess: (res) => {
        toast.closeAll();
        toast(
          getToastData({
            status: "success",
            title: t("Password updated successfully"),
          })
        );
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        // setViewState((prev) => ({
        //   ...prev,
        //   state: "erred",
        // }));
        toast(
          getToastData({
            status: "error",
            title:
              errorMessage || t("An error has occurred while updating password"),
          })
        );
      },
    });
  };

  return (
    <Box pt="12">
      <Formik
        initialValues={INITIAL_VALUES}
        enableReinitialize
        {...{validationSchema, onSubmit}}
      >
        {/* {(props) => ( */}
        <Form>
          <VStack spacing="6" align="stretch">
            <Field name="oldPassword">
              {({field, form}) => (
                <FormControl
                  isInvalid={
                    form.errors.oldPassword && form.touched.oldPassword
                  }
                >
                  {field.value && (
                    <FormLabel fontSize="sm" color="dark">
                      {t("Old Password")}
                    </FormLabel>
                  )}
                  <InputGroup>
                    <Input
                    bgColor={isDarkMode ? "darkModeBg" : "white"}
                    color={isDarkMode ? "white" : "dark"}
                      {...field}
                      type={showOldPassword ? "text" : "password"}
                      placeholder={t("Old Password")}
                    />
                    <InputRightElement
                      color="dark"
                      onClick={() =>
                        setViewState((prev) => ({
                          ...prev,
                          showOldPassword: !prev.showOldPassword,
                        }))
                      }
                      children={
                        showOldPassword ? (
                          <IconEyeOff size="18"color={isDarkMode ? "white" : "dark"} />
                        ) : (
                          <IconEye size="18" color={isDarkMode ? "white" : "dark"}/>
                        )
                      }
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.oldPassword}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Grid
              templateColumns={{base: "1fr", md: "1fr 1fr"}}
              gap="6"
              alignItems="end"
            >
              <GridItem>
                <Field name="newPassword">
                  {({field, form}) => (
                    <FormControl
                      isInvalid={
                        form.errors.newPassword && form.touched.newPassword
                      }
                    >
                      {field.value && (
                        <FormLabel fontSize="sm" color="dark">
                          {t("New Password")}
                        </FormLabel>
                      )}
                      <InputGroup>
                        <Input
                        bgColor={isDarkMode ? "darkModeBg" : "white"}
                        color={isDarkMode ? "white" : "dark"}
                          {...field}
                          type={showNewPassword ? "text" : "password"}
                          placeholder={t("New Password")}
                        />
                        <InputRightElement
                          color="dark"
                          onClick={() =>
                            setViewState((prev) => ({
                              ...prev,
                              showNewPassword: !prev.showNewPassword,
                            }))
                          }
                          children={
                            showNewPassword ? (
                              <IconEyeOff size="18" color={isDarkMode ? "white" : "dark"}/>
                            ) : (
                              <IconEye size="18" color={isDarkMode ? "white" : "dark"}/>
                            )
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.newPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field name="confirmPassword">
                  {({field, form}) => (
                    <FormControl
                      isInvalid={
                        form.errors.confirmPassword &&
                        form.touched.confirmPassword
                      }
                    >
                      {field.value && (
                        <FormLabel fontSize="sm" color="dark">
                          {t("Confirm Password")}
                        </FormLabel>
                      )}
                      <InputGroup>
                        <Input
                        bgColor={isDarkMode ? "darkModeBg" : "white"}
                        color={isDarkMode ? "white" : "dark"}
                          {...field}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder={t("Confirm Password")}
                        />
                        <InputRightElement
                          color="dark"
                          onClick={() =>
                            setViewState((prev) => ({
                              ...prev,
                              showConfirmPassword: !prev.showConfirmPassword,
                            }))
                          }
                          children={
                            showConfirmPassword ? (
                              <IconEyeOff size="18" color={isDarkMode ? "white" : "dark"} />
                            ) : (
                              <IconEye size="18" color={isDarkMode ? "white" : "dark"} />
                            )
                          }
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {form.errors.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
            </Grid>

            <Flex justify="end" mt="6">
              <HStack spacing="5">
                <Button  color={isDarkMode ? "white" : "primary"} aria-label= {t("Cancel")} type="reset" variant="outline">
                  {t("Cancel")}
                </Button>
                <Button color={'white'}  aria-label={t("Save")} type="submit" isLoading={state === "loading"}>
                  {t("Save")}
                </Button>
              </HStack>
            </Flex>
          </VStack>
        </Form>
        {/* )} */}
      </Formik>
    </Box>
  );
};

export default ProfileSecurityForm;
