import {
  Box,
  Container,
  Flex,
  Heading,
  Highlight,
} from "@chakra-ui/react";
import {FooterCTA} from "components";
import responsiveStyle from "constants/responsiveStyle";
import React, {useState, } from "react";
import { useTranslation } from "react-i18next";

const BeOurPartner = () => {
  // const [formStep, setFormStep] = useState(1);
  const {t} = useTranslation()
  // const [formsValues, setFormsValues] = useState({
  //   aboutForm: {},
  //   addressForm: {},
  //   attachmentsForm: {},
  // });

  return (
    <main className="page">
      <Box as="section" display={{base:"none" , md:"block"}} height={responsiveStyle?.page_header_section_height} className="hero partner-hero">
        <Container height="100%">
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              color="white"
              // className="-mt-24"
              fontWeight="extrabold"
              fontSize={{base: "40px", md: "50px", xl: "60px"}}
            >
              {t('Be Our Partner')}
            </Heading>
          </Flex>
        </Container>
      </Box>

      <Box as="section" py={responsiveStyle?.section_py} className="gradient-bg">
        <Container>
          <Heading variant="section" mb="20" textAlign="center">
            <Highlight
              query="Our Partner"
              styles={{color: "primary", whiteSpace: "wrap"}}
            >
             {t(" Become Our Partner")}
            </Highlight>
          </Heading>
          
          <Heading variant="section" fontSize="20px" mb="3" textAlign="center">
            <Highlight
              query="sales@catalyticprice.com"
              styles={{color: "primary", whiteSpace: "wrap"}}
            >
                {t('We are not receiving a partner at this time please try again or contact us by this email : sales@catalyticprice.com')}
            </Highlight>
          </Heading>
          
          {/* <Box maxWidth={{md: "80%", lg: "70%"}} mx="auto">
            <HStack>
              <Circle size="60px" bgColor="primary" color="white">
                <IconBuildingSkyscraper />
              </Circle>
              <Divider
                orientation="horizontal"
                bgColor={formStep >= 2 ? "primary" : "#D0D0D0"}
              />
              <Circle
                size="60px"
                bgColor={formStep >= 2 ? "primary" : "#D0D0D0"}
                color="white"
              >
                <IconMapPin />
              </Circle>
              <Divider
                orientation="horizontal"
                bgColor={formStep >= 3 ? "primary" : "#D0D0D0"}
              />
              <Circle
                size="60px"
                bgColor={formStep >= 3 ? "primary" : "#D0D0D0"}
                color="white"
              >
                <IconPaperclip />
              </Circle>
             
            </HStack>

            <Box mt="16">
              {formStep === 1 ? (
                <PartnerAboutForm
                  initialValues={{...formsValues.aboutForm}}
                  onSubmit={(values) => {
                    setFormsValues((prev) => ({
                      ...prev,
                      aboutForm: {...values},
                    }));
                    setFormStep((prev) => prev + 1);
                  }}
                />
              ) : formStep === 2 ? (
                <PartnerAddressForm
                  initialValues={{...formsValues.addressForm}}
                  onSubmit={(values) => {
                    setFormsValues((prev) => ({
                      ...prev,
                      addressForm: {...values},
                    }));
                    setFormStep((prev) => prev + 1);
                  }}
                  onBack={() => {
                    setFormStep((prev) => prev - 1);
                  }}
                />
              ) : (
                formStep === 3 && (
                  <PartnerAttachmentsForm
                    onSubmit={(values) => {
                      setFormsValues((prev) => ({
                        ...prev,
                        attachmentsForm: {...values},
                      }));
                    }}
                    onBack={() => {
                      setFormStep((prev) => prev - 1);
                    }}
                  />
                )
              )}
            </Box>
          </Box> */}
        </Container>
      </Box>

      <FooterCTA />
    </main>
  );
};

export default BeOurPartner;
