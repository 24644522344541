import {Circle, Image} from "@chakra-ui/react";
import React from "react";

const BrandLogo = ({logo, p = "3", size = "60px"}) => {
  return (
    <Circle
      p={p}
      boxShadow="0px 0px 6.79348px rgba(0, 0, 0, 0.15)"
      width={size}
      height={size}
    >
      {/* <AvatarGroup size="sm" spacing="-10" max="2">
        {logos?.map((l) => (
          <Avatar src={l} />
        ))}
        <Avatar />
      </AvatarGroup> */}
      <Image src={logo} alt="Catalytic Price logo"  />
    </Circle>
  );
};

export default BrandLogo;
