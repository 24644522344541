import axios from "axios";
import i18n from "lang/i18n/index.ts";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { arTranslation } from './../lang/i18n/index.ts';
import { API_BASE_URL } from "constants/env.js";

const useLangSettings = create(
    devtools((set, get) => ({
        languages: {
            state: "loading",
            data: [],
        },
        getActiveLanguages: async ({ onSuccess, onFailure }) => {
            try {
                const res = await axios.post(
                    `${API_BASE_URL}/api/v1/languages/get-languages`
                );
                // console.log(res)
                const languages = res?.data?.body;
                // console?.log({ state: "loaded", data: languages });
                if (languages) {
                    set((state) => ({
                        languages: { state: "loaded", data: languages },
                    }));
                    const currentLang = localStorage.getItem("lang")
                    if(!currentLang){
                        const defaultLang = languages?.filter((item) => item?.isDefault)[0]
                        localStorage.setItem('lang', JSON.stringify(defaultLang))
                        localStorage.setItem('dir', defaultLang?.langDir)

                        const res = await axios.post(
                            `${API_BASE_URL}/api/v1/languages/get-languages-translate?code=${defaultLang?.langCode}`
                        );
                        // console.log({ res }, "res?.body?.data?.translation", res?.data?.body?.translate)
                        const dir = defaultLang?.langDir
                        localStorage.setItem("dir", dir)
                        i18n.addResources(defaultLang?.langCode, 'general', res?.data?.body?.translate);
                        i18n.changeLanguage(defaultLang?.langCode)
                        document.body.setAttribute("dir", defaultLang?.langDir);
                        console.log({defaultLang , languages})
                    }
                    
                }
            } catch (error) {
                console.log(error)
            }

        },
        getLanguagesTranslate: async ({ lang }) => {
            if (lang?.lang_id) {
                const res = await axios.post(
                    `${API_BASE_URL}/api/v1/languages/get-languages-translate?code=${lang?.lang_code}`
                );
                // console.log({ res }, "res?.body?.data?.translation", res?.data?.body?.translate)
                const dir = lang?.lang_dir
                localStorage.setItem("dir", dir)
                i18n.addResources(lang?.lang_code, 'general', res?.data?.body?.translate);
                i18n.changeLanguage(lang?.lang_code)
                document.body.setAttribute("dir", lang?.lang_dir);
            } else {
                const res = await axios.post(
                    `${API_BASE_URL}/api/v1/languages/get-languages-translate?code=${lang?.langCode}`
                );
                // console.log({ res }, "res?.body?.data?.translation", res?.data?.body?.translate)
                const dir = lang?.langDir
                localStorage.setItem("dir", dir)
                i18n.addResources(lang?.langCode, 'general', res?.data?.body?.translate);
                i18n.changeLanguage(lang?.langCode)
                document.body.setAttribute("dir", lang?.langDir);

            }
        }


    }))
);

export default useLangSettings;
