import {
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Center,
  Circle,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { IconCalendar, IconCheck, IconClock, IconX } from "@tabler/icons";
import Card from "./card";
import React from "react";
const notFoundImage = require('./../assets/images/notFound.webp')

const HistoryItem = ({
  title,
  quantity,
  price,
  date,
  status,
  time,
  variant,
  image,
  icon,
  subtitle,
  content,
  accordion
}) => {

  return (
    <Card position="relative">
      <Flex justify="space-between" align="center">
        <Box display="flex" flexDirection="column" flex={1}>
          {
            accordion ?
              <AccordionButton flex={1} flexDirection="column"  >

                <Flex justifyContent="space-between" width="100%">
                  <Box textAlign="left">
                    <Heading as="h5" color="dark" fontWeight="semibold" fontSize="18px">
                      {title}
                    </Heading>

                    <Text color="dark" fontSize="14px" mt="1">
                      {quantity && `x${quantity}`} {price && price} {subtitle && subtitle}
                    </Text>
                  </Box>
                  <div><AccordionIcon alignSelf="flex-end" /></div>
                </Flex>

              </AccordionButton>
              : <>
                <Heading as="h5" color="dark" fontWeight="semibold" fontSize="18px">
                  {title}
                </Heading>
                <Text color="dark" fontSize="14px" mt="1">
                  {quantity && `x${quantity}`} {price && price} {subtitle && subtitle}
                </Text>
              </>
          }


          {content ? accordion ? <AccordionPanel pb={4}>{content}</AccordionPanel> : content : content}


        </Box>

        {(image || icon) && (
          <Center>
            {image && (
              <Image
                alt={subtitle && subtitle}
                boxSize="50px"
                p="1"
                bgColor="#efefef"
                borderRadius="full"
                objectFit="cover"
                src={image}
                fallbackSrc={notFoundImage}
              />
            )}
            {icon && <Icon as={icon} color="primary" fontSize="24px" />}
          </Center>
        )}
      </Flex>
      {variant?.includes("bordered") && (
        <Divider mt="3" p="1px" borderRadius="xl" bgColor="#e1e1e1" />
      )}
      <Stack
        direction={{ base: "column", sm: "row" }}
        color="gray"
        spacing="4"
        fontSize="14px"
        mt="3"
        justify={{ base: "start", sm: variant?.split(" ")[1] }}
      >
        {date && (
          <Flex align="center" gap="2">
            <Icon as={IconCalendar} fontSize="18px" />
            {date}
          </Flex>
        )}


        {time && (
          <Flex align="center" gap="2">
            <Icon as={IconClock} fontSize="18px" />
            {time}
          </Flex>
        )}

        {status && (
          <Flex align="center" gap="2">
            <Circle
              boxSize="4"
              bgColor={(status !== "CANCELLED") ? (status === "EXPIRED") ? "red" : "primary" : "red"}
            >
              <Icon
                as={(status !== "CANCELLED") ? (status === "EXPIRED") ? IconX : IconCheck : IconX}
                fontSize="13px"
                color="white"
              />
            </Circle>
            <Text color={(status !== "CANCELLED") ? (status === "EXPIRED") ? "red" : "primary" : "red"}>
              {status}
            </Text>
          </Flex>
        )}
      </Stack>
    </Card>
  );
};

export default HistoryItem;
