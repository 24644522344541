/* eslint-disable eqeqeq */
import axios from "axios";
import strings from "constants/strings";
import { isObjectValid } from "../utils";
import { removeCookies } from './../utils/utils-functions';
import { getCookies } from 'utils';

async function service({ proposal, boolBacks }) {
  const { onSuccess, onFailure } = boolBacks;

  try {
    //console.log({ proposal })
    const response = await axios(proposal);
    //console.log({response})
    const { data: apiResponse, status } = response;
    // Well, an extra layer of protection never hurts 😉
    // const data2 = await JSON?.parse(apiResponse)
    // console.log({ response }, apiResponse)
    if (
      apiResponse &&
      status == 200 &&
      ((apiResponse.header?.httpStatusCode === 200) || (apiResponse.header?.headerCode === 200))
    ) {
      //console.log({response})
      onSuccess?.(apiResponse);
      return;
    }

    // Why do we fall Bruce? Only to rise back up.
    // console.error(JSON.stringify(response));
    const errorMSG = apiResponse?.header?.userMessage ||
      apiResponse?.header?.headerMessage ||
      strings.DEFAULT_ERROR_MESSAGE
    // console.log(errorMSG)
    if ((apiResponse?.header?.headerMessage === "USER_NOT_FOUND" ||
     apiResponse?.header?.headerMessage === "TOKEN_NO_VALID"
     )
      && getCookies("_Ud")
    ) {
      removeCookies("_Ut")
      removeCookies("_Ud")
      window?.history?.replaceState("", "", "https://catalyticprice.com/auth/login")
      window?.location.reload()
    }
    onFailure?.({
      message: errorMSG,//?.replace(/(_)/gm, " ")?.toLowerCase(),

      error: apiResponse,
    });
  } catch (error) {
    ////console.log({proposal , error})
    // This is the recommended error handling approach by axios.
    if (error.response) {
      // Status code is not 200.
      ////console.log(error.response)
      //  //console.error(strings.DEFAULT_ERROR_MESSAGE, error);
      onFailure?.({
        message:
          error.response.data?.message ||
          error.response.data.header?.headerMessage ||
          strings.DEFAULT_ERROR_MESSAGE,
        error: error.response,
      });
      return;
    }

    if (error.request) {
      // No response was received.
      // //console.error("Server timed out!", error);
      onFailure?.({ message: "Server timed out!" });
      return;
    }

    if (error.message) {
      // Error setting up the request.
      ////console.error("Error setting up the service!", error);
      onFailure?.({ message: strings.DEFAULT_ERROR_MESSAGE });
      return;
    }

    //console.error("There was an unidentified error", error);
    onFailure?.({ message: "There was an unidentified error" });
  }
}

function getProposal({ url, headers, method }) {
  if (!url) throw new Error("ApiService requires a valid url to proceed!");

  const proposal = {};

  if (isObjectValid(headers)) proposal["headers"] = headers;

  proposal["url"] = url;
  proposal["method"] = method;

  return proposal;
}

/**
 * Perform a GET request.
 *
 * @param { Omit<ServiceInitiator, 'data'> & Boolbacks } object The parameters passed.
 * @param {{ key: string, value: string | Array }[]} object.data
 * The body of the request, must be an
 * array of the objects of the form {key, value} where;
 * - key: the string-based URL query parameter,
 * - value: the value for the URL query parameter
 */
function get({ url, data, headers, ...boolBacks }) {
  const params = {};

  data?.[0]?.key &&
    data?.[0]?.value &&
    data.forEach(({ key, value }) => {
      if (value)
        params[key] = Array.isArray(value) ? JSON.stringify(value) : value;
    });

  service({
    boolBacks,
    proposal: getProposal({
      headers,
      method: "get",
      url: isObjectValid(params)
        ? `${url}?${new URLSearchParams(params)}`
        : url,
    }),
  });
}

/**
 * Perform a POST request.
 *
 * @param { ServiceInitiator & Boolbacks } object The parameters passed.
 */
function post({ url, data, headers, method = "post", ...boolBacks }) {
  // console.log("url, data," , {url, data,})
  service({
    boolBacks,
    proposal: { ...getProposal({ url, method, headers }), data },
  });
}

/**
 * Perform a POST request but also send some query parameters.
 *
 * @param { ServiceInitiator & Boolbacks } object The parameters passed.
 * @param {{ key: value }[]} object.params The query parameters.
 */
function postAndGet({ url, params, ...props }) {
  // URLSearchParams accepts a Record<string, string>
  const queryParams = {};

  // We have to make the record directly from the array of objects passed as the params.
  params.forEach((param) =>
    Object.entries(param).forEach(
      ([key, value]) =>
      (queryParams[key] = Array.isArray(value)
        ? // An array of keys needs to be passed as it is, such as [1,2,3,4,5]
        JSON.stringify(value)
        : value)
    )
  );

  post({
    ...props,
    url: `${url}?${new URLSearchParams(queryParams)}`,
  });
}

/**
 * Perform a DELETE request.
 *
 * @param { Omit<ServiceInitiator, 'data'> & Boolbacks } object The parameters passed.
 *
 * @param {number} object.recordId The record to delete.
 */
function deleteRecord({ url, recordId, headers, ...boolBacks }) {
  service({
    boolBacks,
    proposal: getProposal({
      headers,
      method: "delete",
      url: `${url}/${recordId}`,
    }),
  });
}

const apiService = {
  get,
  post,
  postAndGet,
  delete: deleteRecord,
};

export default apiService;
