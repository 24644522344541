import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const arTranslation = {
  "Home": "ألرئيسيه",
  "Car Brands": "ماركات السيارات",
  "Be Our Partner": "كن شريكنا",
  "FAQ's": "التعليمات",
  "Pricing": "التسعير",
  "Comparator": "المقارن",
  "About Us": "عنا",
  "Profile": "الملف الشخصى",
  "Logout": "تسجيل خروج",
  "Login/SignUp": "تسجيل خروج/تسجيل دخول",
  "Navigation": "Navigation",
  "Search Catalytic Converters for Authentic prices.": "ابحث في المحولات الحفازة عن أسعار أصلية.",
  "Browse among more than 40,000 catalytic converters prices in our records. Our prices are based on a real ICP results according to PT, PD, RH.": " تصفح بين أكثر من 40.000 من أسعار المحولات الحفازة في سجلاتنا. تعتمد أسعارنا على نتائج حقيقية لبرنامج المقارنات الدولية وفقًا لـ PT ، PD ، RH.",
  "We update the prices every 2 hours acording to the market price so you will always be up to date and get the acurate price for your catalytic converters.": "نقوم بتحديث الأسعار كل ساعتين وفقًا لسعر السوق ، لذلك ستكون دائمًا على اطلاع دائم وتحصل على السعر الدقيق للمحولات الحفازة.",
  "Search by Cat Id...": "البحث بالارقام التسلسلبه ...",
  "Search": "بحث",
  "Example: 123421, TR PSA K494 etc.": "مثال: 123421 ، TR PSA K494 إلخ.",
  "Explore More": "استكشاف المزيد",
  "Why Choose Catalytic Prices?": "لماذا تختار الأسعار التحفيزية؟",
  "We provide more details about your catalytic converters & the amount of metals inside of each catalytic with full description": "نقدم مزيدًا من التفاصيل حول المحولات الحفازة وكمية المعادن داخل كل محفز مع وصف كامل",
  "Browse Cats by Car Brands & Models": "تصفح Cats حسب ماركات ونماذج السيارات",
  "Become Our Partner & Enjoy Benefits": "كن شريكنا واستمتع بالمزايا",
  "Managed Dashboad": "لوحه التحكم",
  "Responsiveness & Compliance": "الاستجابة والامتثال",
  "Become a Partner": "كن شريكا",
  "We Are The Best for Some Reasons": "نحن الأفضل لبعض الأسباب!",
  "In-Depth Cat Details": "تفاصل المحولات بالتفصيل",
  "Quick, Easy and Reliable": "سريع وسهل وموثوق",
  "Get more details about your catalytic converters & the amount of metals inside of each cat.": "احصل على مزيد من التفاصيل حول المحولات الحفازة وكمية المعادن داخل كل قط",
  "Never Ending Catalogue": "كتالوج لا ينتهي",
  "We have more that 40,000 cat records in our database and we are still growing with unmatchable pace.": "لدينا أكثر من 40.000 سجل قطط في قاعدة بياناتنا وما زلنا ننمو بوتيرة لا مثيل لها.",
  "Download our App to Enjoy a Seamless Experience": "قم بتنزيل تطبيقنا للاستمتاع بتجربة سلسة!",
  "Download our App from Google Play and Apple App Store to enjoy using an uninterrupted experience in the palm of your hands.": "قم بتنزيل تطبيقنا من Google Play و Apple App Store للاستمتاع بتجربة غير منقطعة في راحة يديك.",
  "Feel free to Ping us anytime in case you need any help regarding any topic or have query about us.": "لا تتردد في الاتصال بنا في أي وقت إذا كنت بحاجة إلى أي مساعدة بخصوص أي موضوع أو لديك استفسار عنا.",
  "Need Any Help?": "هل تحتاج الى مساعدة؟",
  "Cat Price ensures the usability to be easy and secure to provide you the best Cat market experience.": "تضمن Cat Price سهولة الاستخدام لتكون سهلة وآمنة لتزويدك بأفضل تجربة سوق من Cat.",
  "Contact Us": "اتصل بنا",
  "Quick Links": "روابط سريعة",
  "Privacy Policy": "سياسة الخصوصية",
  "Enter You Email": "أدخل بريدك الإلكتروني",
  "T & C": "T & C",
  "Contacts": "جهات الاتصال",
  "Subscribe Newsletter": "اشترك في النشرة الإخبارية",
  " Follow us": "أبق على اتصال",
  "Transformation by the Numbers": "التحول بالأرقام",
  "Who are We & What do we Do!": "من نحن وماذا نفعل!",
  "What is Our Mission?": "ما هي مهمتنا؟",
  "Showing all Catalytic Converters From": "يتم عرض جميع المحولات الحفازة من",
  // ==================Toll Refining Comparator=====================
  "Toll Refining Comparator": "حاسبه المعادن",
  "Price Settings": "إعدادات الأسعار",
  "Result": "النتيجه",
  "Weight In KGs": "الوزن بالكيلو جرام",
  "Resultant": "المحصلة",
  "Resultant Price": "Resultant Price",
  "Proceed": "يتابع",

  // ==================FAQs====================
  "FAQs": "أسئلة وأجوبة",
  "Frequently Asked Questions": "أسئلة مكررة",

  //===========Priceing============
  "We Offer Best Services in Best Prices Ever!": "نحن نقدم أفضل الخدمات بأفضل الأسعار على الإطلاق!",

  "All": "كل",
  "Brands": "الانواع",

  //===========profile============
  "First Name": "الاسم الأول",
  "Last Name": "اسم العائلة",
  "Country": "البلد",
  "Currency": "عملة",
  "Choose your currency": "اختر عملتك",
  "Cancel": "الغاء",
  "Save": "حفظ",
  "Edit": "تحرير",
  "Phone Number": "رقم التليفون",
  "Profile Settings": "إعدادات الملف الشخصي",
  "Current Plan": "الخطة الحالية",
  "History": "تاريخ",
  "Favorites": "المفضلة",
  "Support": "الدعم",
  "Security Settings": "اعدادات الامان",
  "Email Activation": "تفعيل البريد الإلكتروني",
  "Your current plan is": "خطتك الحالية هي",
  "Cridits": "رصيد",
  "Unlimited": "غير محدود",
  "No Cridits available": "لايوجد رصيد متاح",
  "Search History": "سجل البحث",
  "Transactions History": "تاريخ المعاملات",
  "Sales History": "تاريخ المبيعات",
  "Total Before Discount:": "الإجمالي قبل الخصم:",
  "Total After Discount:": "الإجمالي بعد الخصم:",
  "Total Paid:": "مجموع المبالغ المدفوعة:",
  "Balance": "الرصيد",
  "Expiration Date:": "تاريخ الأنتهاء :",
  "Discount": "الخصم",
  "Collections": "المجموعات",
  "Create New Collection": "إنشاء مجموعة جديدة",
  "Email": "بريد إلكتروني",
  "Query": "استفسار",
  "Send": "ارسال",
  "Write anything you want to ask?": "اكتب أي شيء تريد أن تسأل؟",
  "Favorites collection created successfully.": "تم إنشاء مجموعة المفضلة بنجاح.",
  "Create a New Favorites Collection": "إنشاء مجموعة مفضلة جديدة",
  "Collection Name": "اسم المجموعة",
  "Create": "انشاء",
  "New Collection name goes here": "اسم المجموعة الجديد يظهر هنا",
  "Old password is required": "كلمة المرور القديمة مطلوبة",
  "New password is required": "كلمة المرور الجديدة مطلوبة",
  "You have to confirm your new password": "عليك تأكيد كلمة مرورك الجديدة",
  "Your passwords do not match.": "كلمات السر الخاصة بك لا تتطابق.",
  "Updating password ...": "جارٍ تحديث كلمة المرور ...",
  "Password updated successfully": "تم تحديث كلمة السر بنجاح",
  "An error has occurred while updating password": "حدث خطأ أثناء تحديث كلمة المرور",
  "Old Password": "كلمة المرور القديمة",
  "New Password": "كلمة المرور الجديدة",
  "Confirm Password": "تأكيد كلمة المرور",
  "Email not valid": "البريد الإلكتروني غير صالح",
  "Email is required": "البريد الالكتروني مطلوب",
  "OTP was sent successfully": "تم إرسال OTP بنجاح",
  "An error has occurred while Send OTP": "حدث خطأ أثناء إرسال OTP",
  "Email Already Activated": "تم تنشيط البريد الإلكتروني بالفعل",
  "Send OTP": "إرسال OTP",
  "Welcome to Catalytic Price!": "مرحبا بكم في Catalytic Price!",
  "Login": "تسجيل الدخول",
  "Login to Your account": "تسجيل الدخول إلى حسابك",
  "Don't have an account?": "ليس لديك حساب؟",
  "Register here": "انشاء حساب",
  "We update the prices every 2 hours acoording to the market price so you will always be up to date and get the acurate price for your catalytic converters": "نقوم بتحديث الأسعار كل ساعتين وفقًا لسعر السوق ، لذلك ستكون دائمًا على اطلاع وتحصل على السعر الدقيق للمحولات الحفازة الخاصة بك",
  "Logging you in": "تسجيل دخولك",
  "You have successfully logged in.": "لقد قمت بتسجيل الدخول بنجاح.",
  "Your phone number is not verified.": "لم يتم التحقق من رقم هاتفك.",
  "Password": "كلمة المرور",
  "I forgot my password": "لقد نسيت كلمة المرور",
  "You have successfully signed up.": "لقد سجلت بنجاح.",
  "Your account already exists but not active. Verify OTP to activate it.": "حسابك موجود بالفعل ولكنه غير نشط. تحقق من OTP لتنشيطه.",
  "Full Name": "الاسم الكامل",
  "Phone number does not seem to be valid.": "يبدو أن رقم الهاتف غير صالح.",
  "I agree to the": "أنا أوافق على",
  "Terms of Services": "شروط الخدمة",
  "of the Catalytic Price website.": "من موقع Catalytic Price",
  "Register": "انشاء حساب",
  "Register Your account": "سجل حسابك",
  "Login here": "تسجيل الدخول من هنا",
  "Already have an account?": " لديك حساب؟",
  "Your Email has been verified.": "تم التحقق من بريدك الالكتروني.",
  "Email Verification": "تأكيد بواسطة البريد الالكتروني",
  "We have sent you an OTP on your": "لقد أرسلنا لك OTP على",
  "Enter in fields to get verified.": "أدخل في الحقول ليتم التحقق منها.",
  "Verify": "تاكيد",
  "Forgot Your Password": "نسيت كلمة السر",
  "Enter your registered Phone to get a Reset link and create new password.": "أدخل هاتفك المسجل للحصول على رابط إعادة تعيين وإنشاء كلمة مرور جديدة.",
  "Your phone number has been verified.": "تم التحقق من رقم هاتفك.",
  "OTP sent successfully": "تم إرسال OTP بنجاح",
  "Account Verification": "تأكيد الحساب",
  "Phone": "هاتف",
  "Didn’t recieve the OTP?": "لم تستلم OTP؟",
  "Send Again": "أعد الإرسال",


  // "HomeDesc": "",
  // "AboutUsDesc": "",
  // "FAQsPageDesc": "",
  // "RefiningComparatorDesc": "",
  // "CatSearchResultsDesc": "",
  // "CatDetailsDesc": "",
  // "CarBrandsDesc": "",
  // "CarBrandsDesc": "",
  // "CarBrandCatalystsDesc": "",
  // "BeOurPartnerDesc": "",
  // "PricingDesc": "",
  // "PricingPlansDesc": "",
  // "PlanPurchasingDesc": "",
  // "PlanCheckoutDesc": "",
  // "CartDesc": "",
  // "BuyersDesc": "",
  // "ProfileDesc": "",
  // "ProfileSettingsDesc": "",
  // "ProfileHistoryDesc": "",
  // "ProfileEmailConfairmationDesc": "",
  // "EmailVerifyOtpDesc": "",
  // "FavoritesCollectionsDesc": "",
  // "FavoritesCollectionItemsDesc": "",
  // "CurrentPlanDesc": "",
  // "ProfileSecurityDesc": "",
  // "ProfileSupportDesc": "",
  // "loginDesc": "",
  // "signupDesc": "",
  // "ForgotPasswordDesc": "",
  // "AuthVerifyOtpDesc": "",

}

const resources = {
  ar: {
    translation: arTranslation
  },
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'en',
    // fallbackLng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    ns: ['general'],
    defaultNS: 'general',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;