import {
  Box,
  Button,
  Heading,
  Highlight,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";

import AuthenticationScreen from "assets/images/authentication-screen.webp";
import { useNavigate} from "react-router-dom";
import useApiEffect from "hooks/use-api-effect";
import { useProfile} from "stores";
import {getToastData} from "utils";
import { useTranslation } from 'react-i18next';

const EmailVerifyOtpForm = () => {
  const {t} = useTranslation()
  const { verifyEmailOtp , emailActivation} = useProfile();
  const toast = useToast();
  const navigate = useNavigate();

  const [enteredOtp, setEnteredOtp] = useState();
  const otpSentRef = useRef(false);

  const handleOtpVerify = () => {
    //console.log({enteredOtp,  } , emailActivation?.data?.activationToken)
    
    verifyEmailOtp({
      otp: enteredOtp,
      emailActivation: emailActivation?.data?.activationToken ,
      onSuccess: (res) => {
        toast(
          getToastData({
            title: t("Your Email has been verified."),
            status: "success",
          })
        );
        setTimeout(() => {
          navigate("/home");
        }, 400);
      },
      onFailure: (errorMessage) => {
        toast(
          getToastData({
            title: errorMessage,
            status: "error",
          })
        );
      },
    });
  };

  useApiEffect(() => {}, []);


  useEffect(()=>{
    if(enteredOtp?.length === 4){
      handleOtpVerify()
    }
  },[enteredOtp])

  return (
    <VStack spacing="8">
      <Heading fontSize="30" mb="2" fontWeight="semibold">
        <Highlight
          query="Verification"
          styles={{color: "primary", whiteSpace: "wrap"}}
        >
          {t("Email Verification")}
        </Highlight>
      </Heading>
      <Image alt="AuthenticationScreen" src={AuthenticationScreen} width="360px" mx="auto" />
      <Text textAlign="center" maxWidth="400px">
        {t("We have sent you an OTP on your")}{" "}
        <Box as="span" color="primary">
          {t("Email")}
        </Box>
        , {t("Enter in fields to get verified.")}
      </Text>
      <HStack>
        <PinInput otp onComplete={(val) => setEnteredOtp(val)}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>

      <Button  color={"white"} aria-label="Verify OTP" width="100%" onClick={handleOtpVerify}>
        {t("Verify")}
      </Button>

      
    </VStack>
  );
};

export default EmailVerifyOtpForm;
