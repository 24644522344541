import {
 
  Box,
  Button,
  Card,
  Circle,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IconHeart, IconQuestionMark } from "@tabler/icons";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useCatalysts } from "stores";
import { getToastData } from "utils";
import BrandLogo from "./brand-logo";
import Loader from "./loader";
import { useTranslation } from "react-i18next";
import AddToFavoriteModal from "./add-to-favorite-modal";
import AuthModal from "./auth-modal";

const notFoundImage = require('../assets/images/notFound.webp')
const Catalyst = ({ cat }) => {
  const { t } = useTranslation();
  const currancy = localStorage.getItem("defCurrency");

  // const [isViewerOpen, setIsViewerOpen] = useState(false)
  const { getCatPrice, faveCats, deleteFavCats } = useCatalysts();
  const [isFavs, setIsFave] = useState(faveCats?.data?.includes(cat?.catId))
  const [loadingFav, setLoadingFav] = useState(false)
  const {
    isOpen: isAddFavoriteModalOpen,
    onOpen: onAddFavoriteModalOpen,
    onClose: onAddFavoriteModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddLoginModalOpen,
    onOpen: onAddLoginModalOpen,
    onClose: onAddLoginModalClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const [{ price, loading }, setPrice] = useState({
    price: cat?.price?.formattedPrice,
    loading: false,
  });
  const toast = useToast();
  const { user } = useAuth();

  //====================
  //Show CatPrice
  //====================
  const showCatPrice = async () => {
    if (user.isLoggedIn) {
      setPrice({ price: price, loading: true });
      getCatPrice({
        catId: cat.catId,
        onSuccess: (res) => {
          //console?.log({ res });
          setPrice({ price: res?.body?.formattedPrice, loading: false });
        },
        onFailure: (errorMessage) => {
          //console?.log(errorMessage);

          if (errorMessage === "EMAIL_ADDRESS_NOT_EXIST") {
            toast(
              getToastData({
                title: "please Activate your email",
                duration: 3000,
              })
            );

            navigate(`/profile/email-activation`, { replace: true });
          } else {
            toast(
              getToastData({
                title: errorMessage,
                duration: 3000,
                status: "error",
              })
            );
          }
          setPrice({ price: null, loading: false });
        },
      });
    } else {
      setPrice({ price: null, loading: false });
      toast(
        getToastData({ title: "You'll have to login first.", duration: 3000 })
      );
      onAddLoginModalOpen()
      //navigate(`/auth/login`, { replace: true });
    }
  };

  //==========================
  //delet Fave
  //==========================
  const onDeleteFromFavourites = async () => {
    setLoadingFav(true)
    await deleteFavCats({
      catId: cat?.catId,
      onSuccess: () => {
        setIsFave(false)
        setLoadingFav(false)
      },
      onFailure: (errorMessage) => {
        setLoadingFav(false)
        toast(getToastData({ title: errorMessage, duration: 3000, status: "error" }));
      }
    })
  }

  const { colorMode } = useColorMode()
  const isDarkMode= colorMode === "dark"

  return (
    <>
     <Card>
     <Box
        minHeight="200px"
        boxShadow={isDarkMode ?"0px 0px 6.79348px rgba(0, 0, 0, 0.95)":"0px 0px 6.79348px rgba(0, 0, 0, 0.15)"}
        bgColor={!isDarkMode &&"white"}
        borderRadius="lg"
        p="4"
        height="100%"
        width="100%"
      >
        <Flex justify="space-between" flexDirection="column" height="100%">
          <Link aria-label={cat.catSn || cat.catNo} to={`/catalyst/${cat.catId}`} >
            <HStack align="center" justify="space-between">
              <HStack gap="2">

                <BrandLogo logo={cat?.brands[0]?.makerImage} />
                <Heading as="h5" fontSize="20">
                  {cat.catSn || cat.catNo}
                </Heading>

              </HStack>
              <Circle boxShadow="0px 0px 6.79348px rgba(0, 0, 0, 0.15)" p="2">
                <Icon as={IconQuestionMark} color="primary" fontSize="16" />
              </Circle>
            </HStack>
            {<Image
              alt={cat.catSn || cat.catNo}
              src={cat.images[0]?.fullImageURL}
              maxWidth="80%"
              mx="auto"
              my="4"
              style={{ height: "150px" }}
              fallbackSrc={notFoundImage}
            />}
          </Link>
          <HStack>
            <Button
              onClick={price ? () => { } : showCatPrice}
              variant="outline"
              color="primary"
              flexGrow="1"
              aria-label={t("price")}
            >
              {loading && <Loader thickness="2px" size="sm" />}
              {price ? `${price} ${currancy}` : ` ${t("Show Price")} `}
            </Button>

            {
              <IconButton
                variant="outline"
                colorScheme="green"
                _hover={{
                  bgColor: "primary",
                  color: "white",
                }}
                bgColor={(isFavs || faveCats?.data?.includes(cat?.catId)) && `primary`}
                color={(isFavs || faveCats?.data?.includes(cat?.catId)) ? `white` : "primary"}
                aria-label="Favorite It"
                fontSize="20px"
                icon={loadingFav ? <Loader size="md" thickness="2px" /> : <IconHeart />}
                onClick={!user?.isLoggedIn ? onAddLoginModalOpen : isFavs ? onDeleteFromFavourites : onAddFavoriteModalOpen}

              />}
          </HStack>
        </Flex>
      </Box>
     </Card>
      <AddToFavoriteModal
        isOpen={isAddFavoriteModalOpen}
        onClose={onAddFavoriteModalClose}
        catId={cat?.catId}
        setIsFave={setIsFave}
      />
      <AuthModal  isOpen={isAddLoginModalOpen} onClose={onAddLoginModalClose}  />
    </>
  );
};

export default Catalyst;
