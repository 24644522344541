import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Highlight,
  Image,
  Stack,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { FooterCTA } from "components";
import React from "react";
import BuildingsImage from "assets/images/buildings.webp";
import CatImage1 from "assets/images/cat-1.webp";
import { useTranslation } from "react-i18next";
import responsiveStyle from "constants/responsiveStyle";

const AboutUs = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode()
  const isDarkMode= colorMode === "dark"
  return (
    <main className="page">
      <Box as="section" style={{backgroundImage: isDarkMode && "none" , backgroundColor:isDarkMode && "transparent"}}  display={{base:"none" , md:"block"}} height={responsiveStyle?.page_header_section_height} className="hero about-us-hero">
        <Container height="100%">
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              color="white"
              // className="-mt-24"
              fontWeight="extrabold"
              fontSize={{ base: "40px", md: "50px", xl: "60px" }}
            >
              {t("About Us")}
            </Heading>
          </Flex>
        </Container>
      </Box>

      {/*  */}
      <Box as="section" bg={!isDarkMode && "secondary"} py={responsiveStyle?.section_py}>
        <Container>
          <Heading variant="section" textAlign="center">
            <Highlight
              query="Numbers"
              styles={{ color: "primary", whiteSpace: "wrap" }}
            >
              {t("Transformation by the Numbers")}
            </Highlight>
          </Heading>
          <Stack
            mt="16"
            gap="12"
            direction={{ base: "column", md: "row" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              bgColor="secondarydark"
              p={{ base: "12", md: "6", lg: "12" }}
              width="100%"
              maxWidth="400px"
              borderRadius="lg"
              textAlign="center"
            >
              <VStack>
                <Text color={ "dark"}>{t('Our app uses the latest technology and XRF as well as ICP to collect all information about catalytic converter prices .')}</Text>
              </VStack>
            </Box>
            <Box
              bgColor="secondarydark"
              p={{ base: "12", md: "6", lg: "12" }}
              width="100%"
              maxWidth="400px"
              borderRadius="lg"
              textAlign="center"
            >
              <VStack>  
                <Text color={ "dark"}>{t('So we have a large database and we collecting our information from many sources all around the world as big catalytic converters, collectors and buyers . ')}</Text>
              </VStack>
            </Box>
            <Box
              bgColor="secondarydark"
              p={{ base: "12", md: "6", lg: "12" }}
              width="100%"
              maxWidth="400px"
              borderRadius="lg"
              textAlign="center"
            >
              <VStack>
                <Text color={ "dark"}>{t('We started our business in 2014 and we have around 40.000 item in our database and we adding more everyday with 99% accurate information')}</Text>
              </VStack>
            </Box>
          </Stack>
        </Container>
      </Box>
      {/*  */}

      {/*  */}
      <Box as="section" py="24" bgColor={!isDarkMode &&"white"}>
        <Container>
          <Heading variant="section" mb="8" textAlign="center">
            <Highlight
              query={["We", "Do"]}
              styles={{ color: "primary", whiteSpace: "wrap" }}
            >
              {t("Who are We & What do we Do!")}
            </Highlight>
          </Heading>
          <Box mt="16">
            <Grid
              gap="12"
              justifyItems="center"
              alignItems="center"
              templateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
            >
              <GridItem>
                <Box>
                  <Image
                    alt="Buildings"
                    src={BuildingsImage}
                    width="100%"
                    maxWidth={{ base: "400px", lg: "600px" }}
                  />
                </Box>
              </GridItem>
              <GridItem>
                <VStack spacing="6">
                  <Text fontWeight="semibold" color={isDarkMode? "white": "dark"}>
                    <Highlight
                      query={["“", "”", "Catalytic Price"]}
                      styles={{ color: "primary" }}
                    >
                      {t(
                        "Catalytic Price app it’s about providing accurate prices for the most catalytic converter prices and the converters are a critical component of modern automobile engines, designed to reduce harmful emissions of pollutants such as carbon monoxide, nitrogen oxides, and hydrocarbons. These devices contain a catalyst that reacts with the exhaust gases to convert them into less harmful substances."
                      )}
                    </Highlight>
                  </Text>

                  <Text color={isDarkMode? "white": "dark"}>
                    <Highlight
                      query="Catalytic Price"
                      styles={{ color: "primary" }}
                    >
                      {t(
                        "harmful substances. The working of catalytic converters involves several steps. As exhaust gases pass through the converter, they come into contact with a catalyst made of platinum, palladium, and rhodium. These metals act as a catalyst and promote chemical reactions that convert harmful pollutants into less harmful ones. For example, carbon monoxide is converted into carbon dioxide, nitrogen oxides are reduced to nitrogen and oxygen, and unburned hydrocarbons are converted into carbon dioxide and water."
                      )}
                    </Highlight>
                  </Text>
                  <Text color={isDarkMode? "white": "dark"}>
                    <Highlight
                      query="Catalytic Price"
                      styles={{ color: "primary" }}
                    >
                      {t(
                        "The effectiveness of a catalytic converter depends on several factors, including the quality of the catalyst, the design of the converter, and the operating conditions of the engine. Regular maintenance of the engine and the converter is also essential to ensure optimal performance."
                      )}
                    </Highlight>
                  </Text>
                </VStack>
              </GridItem>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/*  */}

      <Box as="section" py="16">
        <Container>
          <Grid
            gap="12"
            justifyItems="center"
            alignItems="center"
            templateColumns={{ base: "1fr", md: "repeat(2,1fr)" }}
          >
            <GridItem>
              {/* <Heading variant="section" mb="10">
                <Highlight
                  query="Our Mission"
                  styles={{color: "primary", whiteSpace: "wrap"}}
                >
                   
                  {t("What is Our Mission?")}
                </Highlight>
              </Heading> */}
              <Text color={isDarkMode? "white": "dark"} lineHeight="tall">
                {t(
                  "Several technologies are used to manufacture catalytic converters, including ceramic and metallic substrates. The ceramic substrates are made of a high-temperature-resistant material that can withstand the harsh conditions in the converter, while metallic substrates are made of a stainless steel material that is more durable and less prone to corrosion."
                )}
              </Text>
              <Text color={isDarkMode? "white": "dark"} lineHeight="tall" mt="2">
                {t(
                  "Recent advances in catalytic converter technology include the use of advanced catalyst materials, such as zeolites, and the development of new manufacturing processes that reduce the amount of precious metals needed in the catalyst. These innovations aim to improve the efficiency of catalytic converters, reduce their cost, and make them more environmentally friendly."
                )}
              </Text>
              <Text color={isDarkMode? "white": "dark"} lineHeight="tall" mt="2">
                {t(
                  "Overall, catalytic converters play a critical role in reducing harmful emissions from automobiles and other engines. As environmental regulations continue to become more stringent, the development of new and more efficient catalytic converter technologies will remain a vital area of research and innovation."
                )}
              </Text>
            </GridItem>
            <GridItem gridRow={{ base: "1/2", md: "auto" }}>
              <Box>
                <Image
                  alt={"about"}
                  src={CatImage1}
                  width="100%"
                  maxWidth={{ base: "400px", lg: "500px" }}
                />
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </Box>

      <FooterCTA />
    </main>
  );
};

export default AboutUs;
