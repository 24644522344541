import {Box , Card as CardChakra, CardBody} from "@chakra-ui/react";
import React from "react";

const Card = ({children, p = "4", ...props}) => {
  return (
    <CardChakra
      boxShadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
      p={p}
      borderRadius="lg"
      h="100%"
      {...props}
    >
      <CardBody>
      {children}
      </CardBody>
    </CardChakra>
  );
};

export default Card;
