import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { IconLock} from "@tabler/icons";
import {SassyPhoneInput} from "components";
import {Field, Formik, Form} from "formik";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {useAuth, useCatalysts} from "stores";
import {getToastData} from "utils";
import {INITIAL_STATE, loginValidationSchema} from "./utils";
import { useTranslation } from 'react-i18next';


const AuthLoginForm = ({setModalScreen , onClose}) => {
  const {t} = useTranslation()
  const {loginUser} = useAuth();
  const {getAllFavCats} = useCatalysts()
  const toast = useToast();
  const navigate = useNavigate();
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"

  const submitHandler = (values) => {
    toast(
      getToastData({
        status: "loading",
        title: t("Logging you in"),
      })
    );
    
    loginUser({
      ...values,
      onSuccess: (res) => {
        toast.closeAll();
        toast(
          getToastData({
            title: t("You have successfully logged in."),
            status: "success",
          })
        );
        getAllFavCats({onSuccess:()=>{}, onFailure:()=>{} })
        setTimeout(() => {
          if(setModalScreen){
            onClose()
           }else{
            navigate("/home");
          }
          
        }, 1500);

      // console.log({res});
      },
      onFailure: (errorMessage) => {
        toast.closeAll();
        if (errorMessage === "User No Active Mobile") {
          toast(
            getToastData({
              status: "info",
              title: t("Your phone number is not verified."),
            })
          );
          setTimeout(() => {
            navigate("/auth/verify-otp");
          }, 1500);
          return;
        }

        toast(
          getToastData({
            title: errorMessage,
            status: "error",
            duration:10000
          })
        );
      },
    });
  };

  return (
    <Formik
      onSubmit={submitHandler}
      initialValues={INITIAL_STATE}
      enableReinitialize
      validationSchema={loginValidationSchema}
    >
      <Form>
        <VStack justify="flex-start" align="stretch" spacing="4">
          <Field name="phoneNumber">
            {({field, form}) => (
              <FormControl
                isInvalid={
                  form.errors.phoneNumber && form.touched.phoneNumber
                  // || !isPhoneNumberValid
                }
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Phone Number")}
                  </FormLabel>
                )}
                <SassyPhoneInput
                  onChange={(phone, country) => {
                    form.setFieldValue("dialCode", country.dialCode);
                    form.setFieldValue(
                      "phoneNumber",
                      phone.replace(country.dialCode , '')
                    );
                  }}
                />
                <FormErrorMessage>
                  {
                    form.errors.phoneNumber
                    // || (!isPhoneNumberValid
                    //     ? "Phone number does not seem to be valid."
                    //     : "")
                  }
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="password">
            {({field, form}) => (
              <FormControl
                isInvalid={form.errors.password && form.touched.password}
              >
                {field.value && (
                  <FormLabel fontSize="sm" color="dark">
                    {t("Password")}
                  </FormLabel>
                )}
                <InputGroup>
                  <Input
                  color={isDarkMode ? "white":"dark"}
                  bgColor={isDarkMode?"darkModeBg": "white"}
                    {...field}
                    placeholder={t("Password")}
                    size="lg"
                    type="password"
                    fontSize="16"
                  />
                  <InputRightElement
                    color="gray"
                    children={<IconLock size="18"   />}
                  />
                </InputGroup>
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </VStack>
        <Text textAlign="right" color="primary" mt="4" fontWeight="medium">
          <Link aria-label={t("I forgot my password")} to="/auth/forgot-password">{t("I forgot my password")}</Link>
        </Text>
        
        <Button color={"white"} aria-label={t("Login")} type="submit" width="100%" mt="6">
          {t("Login")}
        </Button>
      </Form>
    </Formik>
  );
};

export default AuthLoginForm;
