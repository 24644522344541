import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  Highlight,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import {FooterCTA} from "components";
import responsiveStyle from "constants/responsiveStyle";
import React from "react";
import { useTranslation } from 'react-i18next';

const FAQsPage = () => {
  const {t} = useTranslation()
  const { colorMode } = useColorMode()
  const isDarkMode= colorMode === "dark"
  return (
    <main className="page">
      <Box as="section" style={{backgroundImage: isDarkMode && "none" , backgroundColor:isDarkMode && "transparent"}}  display={{base:"none" , md:"block"}} height={responsiveStyle?.page_header_section_height} className="hero faqs-hero">
        <Container height="100%">
          <Flex
            width="100%"
            height="100%"
            justify="center"
            align="center"
            textAlign="center"
          >
            <Heading
              as="h1"
              color={'white'}
              // className="-mt-24"
              fontWeight="extrabold"
              fontSize={{base: "40px", md: "50px", xl: "60px"}}
            >
              {t("FAQs")}
            </Heading>
          </Flex>
        </Container>
      </Box>
      <Box as="section" py={responsiveStyle?.section_py} className="gradient-bg">
        <Container>
          <Heading variant="section" mb="16" textAlign="center">
            <Highlight
              query="Questions"
              styles={{color: "primary", whiteSpace: "wrap"}}
            >
              {t("Frequently Asked Questions")}
            </Highlight>
          </Heading>
          <Box maxWidth={{md: "80%", lg: "60%"}} mx="auto">
            <Accordion>
              <AccordionItem>
                <h2>
                  <AccordionButton py="4">
                    <Box flex="1" textAlign="left">
                      {t('question_1')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py="4">
                  <VStack alignItems="start" spacing="8" color="#949494">
                    <Text>
                     {t('question_1_answer')}
                    </Text>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton py="4">
                    <Box flex="1" textAlign="left">
                      {t('question_2')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py="4">
                  <VStack alignItems="start" spacing="8" color="#949494">
                    <Text>
                    {t('question_2_answer')}
                    </Text>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton py="4">
                    <Box flex="1" textAlign="left">
                      {t('question_3')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py="4">
                  <VStack alignItems="start" spacing="8" color="#949494">
                    <Text>
                    {t('question_3_answer')}
                    </Text>
                    
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton py="4">
                    <Box flex="1" textAlign="left">
                      {t('question_4')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py="4">
                  <VStack alignItems="start" spacing="8" color="#949494">
                    <Text>
                    {t('question_4_answer')}
                    </Text>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton py="4">
                    <Box flex="1" textAlign="left">
                    {t('question_5')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py="4">
                  <VStack alignItems="start" spacing="8" color="#949494">
                    <Text>
                    {t('question_5_answer')}
                    </Text>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton py="4">
                    <Box flex="1" textAlign="left">
                    {t('question_6')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py="4">
                  <VStack alignItems="start" spacing="8" color="#949494">
                    <Text>
                    {t('question_6_answer')}
                    </Text>
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Container>
      </Box>

      <FooterCTA />
    </main>
  );
};

export default FAQsPage;
