import {
  Box,
  Center,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  useColorMode,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
  IconHeart,
  IconHeartHandshake,
  IconHistory,
  IconLock,
  IconLogout,
  IconUser,
  IconMailForward,
  IconBusinessplan,
  IconMenu2
} from "@tabler/icons";
import { capitalize, deSluggify } from "utils";
import { useAuth } from "stores";
import { ProfileSidebarImage } from "modules";
import { useTranslation } from 'react-i18next';

const ProfileLayout = () => {
  const location = useLocation();
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === "dark"
  const { user , logoutUser} = useAuth();
  const { t } = useTranslation()
  const ViewTitle = capitalize(
    deSluggify(location?.pathname?.split("/")[2] || "")
  );

  const getNavLinkClassName = ({ isActive }) => `${isActive ? "text-primary" : "text-inherit"
    }  w-full block hover:text-primary hover:duration-100 hover:ease-in tracking-wide hover:scale-105 border-b-2 border-black-300 font-semibold px-8 pb-2 flex align-items-center gap-2`;

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();
  const btnRef = React.useRef()

  return (
    <main className="page">

     {/* mobile menue */}
      <Drawer
        isOpen={isDrawerOpen}
        placement='left'
        onClose={onDrawerClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Grid templateColumns={{ md: ".5fr 1.5fr" }} gap="4" minHeight="81vh">
              <GridItem>
                <Box
                  height="100%"
                  borderRadius="sm"
                
                >
                  <Box py="8" px="5">
                    <Center>
                      <VStack gap="3">
                        <ProfileSidebarImage
                          user={user.data}
                          src={user.data.profilePicture}
                          name={user.data.fullNameEn}
                        />

                        <Heading
                          as="h5"
                          color={isDarkMode ? "white": "dark"}
                          fontSize="16px"
                          fontWeight="semibold"
                        >
                          {user.data.fullNameEn}
                        </Heading>
                      </VStack>
                    </Center>
                  </Box>

                  <VStack
                     color={isDarkMode ? "white": "dark"}
                    spacing="6"
                    justify="flex-start"
                    align="flex-start"
                  >
                    <NavLink
                      to="/profile/profile-settings"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                      color={isDarkMode ? "white": "dark"}
                    >
                      <IconUser size="22" />
                      {t("Profile")}
                    </NavLink>

                    <NavLink
                      to="/profile/current-plan"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                    >
                      <IconBusinessplan size="22" />
                      {t("Current Plan")}
                    </NavLink>

                    <NavLink
                      to="/profile/history"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                    >
                      <IconHistory size="22" />
                      {t("History")}
                    </NavLink>

                    <NavLink
                      to="/profile/favorites"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                    >
                      <IconHeart size="22" />
                      {t("Favorites")}
                    </NavLink>

                    <NavLink
                      to="/profile/support"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                    >
                      <IconHeartHandshake size="22" />
                      {t("Support")}
                    </NavLink>

                    <NavLink
                      to="/profile/security-settings"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                    >
                      <IconLock size="22" />
                      {t("Security Settings")}
                    </NavLink>

                    <NavLink
                      to="/profile/email-activation"
                      end
                      className={getNavLinkClassName}
                      onClick={onDrawerClose}
                    >
                      <IconMailForward size="22" />
                      {t("Email Activation")}
                    </NavLink>

                    <NavLink  as="button"
                      onClick={() => {
                        onDrawerClose()
                        logoutUser();
                        // navigate("/home");
                        // resetFavData()
                      }} end className={getNavLinkClassName}>
                      <IconLogout size="22" />
                      {t("Logout")}
                    </NavLink>
                  </VStack>
                </Box>
              </GridItem>

            </Grid>
          </DrawerBody>
        </DrawerContent>
      </Drawer>


      {/* desktop menur */}
      <Box as="section" className="hero profile-hero" py="14" />
      <Grid templateColumns={{ md: ".5fr 1.5fr" }} gap="4" minHeight="81vh">
        {/* <GridItem> */}
          <Box
            boxShadow="2px 0px 4px rgba(0, 0, 0, 0.1)"
            height="100%"
            borderRadius="sm"
            // className="sm:hidden md:hidden xl:hidden "
            display={{
              base: "none",
              md: 'block',
            }}
          >
            <Box py="8" px="5">
              <Center>
                <VStack gap="3">
                  <ProfileSidebarImage
                    user={user.data}
                    src={user.data.profilePicture}
                    name={user.data.fullNameEn}
                  />

                  <Heading
                    as="h5"
                    // color="dark"
                    fontSize="16px"
                    fontWeight="semibold"
                  >
                    {user.data.fullNameEn}
                  </Heading>
                </VStack>
              </Center>
            </Box>

            <VStack
              // color="black"
              spacing="6"
              justify="flex-start"
              align="flex-start"
            >
              <NavLink
                to="/profile/profile-settings"
                end
                className={getNavLinkClassName}
              >
                <IconUser size="22" />
                {t("Profile")}
              </NavLink>

              <NavLink
                to="/profile/current-plan"
                end
                className={getNavLinkClassName}
              >
                <IconBusinessplan size="22" />
                {t("Current Plan")}
              </NavLink>

              <NavLink
                to="/profile/history"
                end
                className={getNavLinkClassName}
              >
                <IconHistory size="22" />
                {t("History")}
              </NavLink>

              <NavLink
                to="/profile/favorites"
                end
                className={getNavLinkClassName}
              >
                <IconHeart size="22" />
                {t("Favorites")}
              </NavLink>

              <NavLink
                to="/profile/support"
                end
                className={getNavLinkClassName}
              >
                <IconHeartHandshake size="22" />
                {t("Support")}
              </NavLink>

              <NavLink
                to="/profile/security-settings"
                end
                className={getNavLinkClassName}
              >
                <IconLock size="22" />
                {t("Security Settings")}
              </NavLink>

              <NavLink
                to="/profile/email-activation"
                end
                className={getNavLinkClassName}
              >
                <IconMailForward size="22" />
                {t("Email Activation")}
              </NavLink>

              <NavLink  as="button"  onClick={() => {
                        logoutUser();
                        // navigate("/home");
                        // resetFavData()
                      }} end className={getNavLinkClassName}>
                
                <IconLogout size="22" />
                {t("Logout")}
              </NavLink>
            </VStack>
          </Box>
        {/* </GridItem> */}

        <GridItem>
          <Container height="100%">
            <Flex>
         
             <Flex
              py="4"
              bgColor="secondary"
              color="primary"
              borderRadius="lg"
              w="100%"
              textAlign="center"
              mt={{ base: "0", md: "0" }}
              mb={{ base: "0", md: "12" }}
              
            >
                 <button

              className={`xl:hidden cursor-pointer ${"text-primary"} px-3 active:outline-none rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary`}
              onClick={onDrawerOpen}
              ref={btnRef}
            > <IconMenu2  size={33} /></button>
              <Heading fontSize="20" textAlign='center' flex={1}>{t(ViewTitle)}</Heading>

            </Flex>

            </Flex>
           
           

            <Box py="4">
              <Outlet />
            </Box>
          </Container>
        </GridItem>
      </Grid>
    </main>
  );
};

export default ProfileLayout;
