import { Box, Button, Flex, Input, InputGroup, InputLeftElement, InputRightElement, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sluggify } from "utils";
import { useTranslation } from 'react-i18next';
import { IconMicrophone, IconMicrophoneOff, IconPhotoUp } from "@tabler/icons";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { createWorker } from "tesseract.js";
import Tesseract, { createWorker } from 'tesseract.js';

const microphone = require("../assets/images/wired-outline-188-microphone-recording.gif")
const CatSearchBox = ({ value }) => {
  const [input, setInput] = useState(value || "");
  const { t } = useTranslation()
  const navigate = useNavigate();
  const inputRef = useRef()
  const {colorMode} = useColorMode()
  const isDarkMode = colorMode === "dark"
  const dir = localStorage?.getItem("dir") || "ltr"
  const dirRTL = dir === "rtl";

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const worker = createWorker();


  useEffect(() => {
    transcript !== true && setInput(transcript);
    transcript !== true && transcript.length && navigate(`/search/${sluggify(transcript)}`);
    // Tesseract.recognize(
    //   'https://tesseract.projectnaptha.com/img/eng_bw.png',
    //   'eng',
    //   { logger: m => console.log({m}) }
    // ).then((e) => {
    //   console.log({e});
    // }).catch(error=>console.log("kkkk",{error}))
    // console.log("9999")
  }, [transcript])


  return (
    <Box width="100%">
      <Flex width="100%" >
        <InputGroup size='md'>
          <Input
            bgColor={isDarkMode && "darkModeBg"}
            color={isDarkMode ?"white" :"black"}
            focusBorderColor="green.100"
            placeholder={t("Search by Cat Id...")}
            borderRight={0}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "enter") {
                navigate(`/search/${sluggify(input)}`, { replace: true });
              }
            }}

          />

          {!dirRTL &&  <InputRightElement width='7.5rem'>
            <Input
              type="file"
              accept="image/*"
              hidden
              ref={inputRef}
              onChange={async (e) => {               
              }}
            />

            {!listening && <Button type="file"
              onClick={SpeechRecognition.startListening}
              style={{ boxShadow: "none", padding: 0 }}
              rightIcon={<IconMicrophoneOff style={{ color: "#aaa" }} />}
              colorScheme='teal'
              variant='ghost'
            />}
            {listening && <Button type="file"
              onClick={SpeechRecognition.stopListening}
              style={{ boxShadow: "none", padding: 0 }}
              rightIcon={<img style={{ width: 35, height: 35 }} src={microphone} />}
              colorScheme='teal'
              variant='ghost'
            />}

            <Button onClick={() => inputRef.current.click()} style={{ boxShadow: "none", padding: 0 }} rightIcon={<IconPhotoUp style={{ color: "#aaa" }} />} colorScheme='teal' variant='ghost'></Button>
          </InputRightElement>}

          {dirRTL &&<InputLeftElement width='7.5rem'>
            <Input
              type="file"
              accept="image/*"
              hidden
              ref={inputRef}
              onChange={async (e) => {               
              }}
            />

            {!listening && <Button type="file"
              onClick={SpeechRecognition.startListening}
              style={{ boxShadow: "none", padding: 0 }}
              rightIcon={<IconMicrophoneOff style={{ color: "#aaa" }} />}
              colorScheme='teal'
              variant='ghost'
            />}
            {listening && <Button type="file"
              onClick={SpeechRecognition.stopListening}
              style={{ boxShadow: "none", padding: 0 }}
              rightIcon={<img style={{ width: 35, height: 35 }} src={microphone} />}
              colorScheme='teal'
              variant='ghost'
            />}

            <Button onClick={() => inputRef.current.click()} style={{ boxShadow: "none", padding: 0 }} rightIcon={<IconPhotoUp style={{ color: "#aaa" }} />} colorScheme='teal' variant='ghost'></Button>
          </InputLeftElement>}
        </InputGroup>





        <Button
          as={Link}
          to={`/search/${sluggify(input)}`}
          className="-translate-x-2  z-5"
          aria-label={t("Search")}
          color="#fff"
        >
          {t("Search")}
        </Button>
      </Flex>
      <Text mt="4" fontSize="sm" fontWeight="light" color={{ base: "black", md: "white" }}>
        {t('Example: 123421, TR PSA K494 etc.')}
      </Text>
    </Box>
  );
};

export default CatSearchBox;
